import React, { useState, useEffect, useCallback, useRef } from "react";
import { useQuery } from "react-query";
import {
  View,
  SafeAreaView,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  Image,
  ImageBackground,
  Text,
  StatusBar,
  useWindowDimensions,
  Dimensions, Platform
} from "react-native";

import Header from "../components/Header";
import { loginService } from "../services/UserService";
import { Fontisto, Ionicons, Entypo, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import {  allPosts } from "../services/PostServices";
import axios from "axios";
import moment from 'moment';
import colors from "../Colors";
import Configuration from "../config/Configuration";
import useHomeFeedPosts from "../hooks/useHomeFeedPosts";
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
import PhotoGallery from "../components/PhotoGallery";
import { GestureHandlerRootView } from 'react-native-gesture-handler';


const SERVER = Configuration.BASE_SERVER_URL;

const theme = colors.defaultTheme();
const jointly = require("../assets/draw.png")
const MINIMUM_ITEM_HEIGHT = 300
const MAX_WIDTH = 780
const BREAKPOINT = 500


export default function HomeScreen({ navigation, loggedIn, token, route }) {

  const [fontsLoaded]= useFonts(fontsList);  
  const isLoggedIn = loginService((state) => state.isLoggedIn);
  const authToken = loginService((state) => state.authToken);
  const {height, width} = useWindowDimensions();

//   const { isLoading, data, hasNextPage, fetchNextPage } = useHomeFeedVideos({token: authToken});

  if (isLoggedIn) {
    return (
      <SafeAreaView style={styles.container}>
        <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
        <Header />
        <GestureHandlerRootView style={{flex:1, width:"100%", alignSelf:"center", paddingHorizontal:5}}>
          <UserFeed token={authToken} navigation={navigation} route={route} />
        </GestureHandlerRootView>
      </SafeAreaView>
    );
  } else {
    //console.log("you are not logged in");
    return (
      <SafeAreaView style={styles.container}>
        <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
        <Header />
        <View style={[styles.wrapper,{width:width, maxWidth:MAX_WIDTH - 200,alignSelf:"center"}]}>
          <Text style={[styles.profileTitle, { fontSize: 26 }]}>Hello! Olá! Hola!</Text>
          <Text style={[styles.text, { padding: 10, textAlign: "center" }]}>Welcome to Joyntly, to proceed you need to login or register.</Text>
          <ImageBackground source={jointly} style={[styles.draw,{height: height * 0.50,}]} resizeMode={"contain"} />
          <TouchableOpacity style={styles.loginBtn} onPress={() => navigation.navigate("Me", { screen: "Profile" })}>
            <Text style={styles.loginText}>Sign In</Text>
          </TouchableOpacity>
        </View>   
      </SafeAreaView>
    );
  }
}

const publicFeed = async () => {
  //console.log("publicFeed");
  const res = await fetch(SERVER + "/api/feed/public");
  return res.data;
};

function UserFeed({ token, navigation,route }) {
  const avatarImage = {
    uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612",
  };

  const setOrigin = allPosts((state) => state.setOrigin);
  const loggedUsername = loginService((state) => state.username)
  const { isLoading, data, hasNextPage, fetchNextPage, handleLikePost, handleUnlikePost, refetch } = useHomeFeedPosts({token, limit: 5});
  const [refreshing, setRefreshing] = useState(false);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    } 
  };

  if (isLoading) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }
  
  let feed = data.pages.map(page => page.posts).flat();

  function moreDetails(item) {
    setOrigin("Home");
    navigation.push("SinglePost", {post_id: item.post_id});
  }

  //console.log("post service",feedItem)


  const Item = ({ item, source }) => {

    const {height, width} = useWindowDimensions();
    //console.log(item.author[0])

    function checkUsername() {
      if (item.author[0]) {
        return item.author[0].username;
      } else if (item.author.username) {
        return item.author.username;
      } else if (post.author && post.author[0]) {
        return post.author[0].username;
      } else {
        return "no username";
      }
    }

    function viewProfile() {
      setOrigin("Home");

      if (loggedUsername != item.author[0].username) {
        item.author[0].username;
        item.username = item.author[0].username;
        navigation.push("ProfileViewer", item);
      } else {
        navigation.navigate("Me", { screen: "Profile" }, item);
      }
    }

    const date = new Date(item.timestamp)
    const timeago = moment(date).fromNow();
    
    function rating(x) {
      if(x != null){

     
      if (x < 10) {
        return Number.parseFloat(x).toFixed(1);
      } else {
        return Number.parseFloat(x);
      }

      }else {
        return "0.0"
      }

    }

    function excerpt() {
      const text = String(item.form.fields.general_notes)
      return text.length > 98 ? text.slice(0, 98)+'...' : text
    }

    let imgHeight = width < BREAKPOINT ? MINIMUM_ITEM_HEIGHT : height / 3
    let imgWidth = width > BREAKPOINT ? MAX_WIDTH : width
    

    let didLike = false;
    if (item["react_user_status"] && item["react_user_status"]["react_like"]) {
        didLike = item.react_user_status.react_like
    }

    let heartCount = 0;
    if (item["reactions_count"] && item["reactions_count"].length > 0 && item["reactions_count"][0]["heart"]) {
        heartCount = item["reactions_count"][0]["heart"];
    }

    let commentCount = 0;

    if (item["comment_count"]) {
      commentCount = item["comment_count"];
    }

    function viewComments(postId) {
        navigation.push("PostComments", {post_id: postId})
    }


    return (
      <View style={styles.itemContainer} id={item.post_id}>
        <View style={styles.profileContainer}>
            <View style={styles.textContainer}>
              <Text style={[styles.profileTitle, { fontSize: 22 }]}>{item.form.fields.name}</Text>
              <Text style={{ fontSize: 15, fontWeight: "500", color: theme.TEXT_COLOR, fontFamily:"Ubuntu-Regular", }}>by {item.form.fields.brand}</Text>
            </View>
          <View style={styles.overallContainer}>
            <Text style={styles.overallName}>Overall</Text>
            <Text style={styles.overallNumber}>{rating(item.form.rating.overall)}</Text>
          </View>
        </View>
        {item.store_name && (
          <View style={[styles.horizonltalAlign, { paddingVertical: 5 }]}>
            <Entypo name="location-pin" size={24} color={theme.SECTION_TITLE} />
            <Text style={styles.store}>{item.store_name}</Text>
          </View>
        )}
    
        <PhotoGallery  
          showPagination={item.media.length <= 1 ? false : true}
          data={item.media}
          offsetTop={imgHeight}
          imgWidth={imgWidth}
          imgHeight={imgHeight}     
        />
       

        <View style={styles.authorContainer}>
          <TouchableOpacity onPress={() => viewProfile()}>
            <Text style={styles.authorName}>{checkUsername()}</Text>
          </TouchableOpacity>
          <View style={styles.badgesContainer}>
            <View style={[styles.badge, styles.badgeFocused]}>
              <Text style={[styles.badgeText, { fontSize: 10 }]}><MaterialCommunityIcons name="bullseye-arrow" size={22} color="#fff" /></Text>
              <Text style={styles.badgeText}>{rating(item.form.rating.focused)}</Text>
            </View>
            <View style={[styles.badge, styles.badgeRelaxed]}>
              <Text style={[styles.badgeText, { fontSize: 10 }]}><FontAwesome5 name="couch" size={22} color="#f1f1f1" /></Text>
              <Text style={styles.badgeText}>{rating(item.form.rating.relaxed)}</Text>
            </View>
            <View style={[styles.badge, styles.badgeHappy]}>
              <Text style={[styles.badgeText, { fontSize: 10, color: "#000" }]}><Entypo name="emoji-happy" size={22} color="black" /></Text>
              <Text style={[styles.badgeText, { color: "#000" }]}>{rating(item.form.rating.happy)}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.date}>{timeago}</Text>
        <View style={[styles.separator, {marginBottom: 0, marginTop: 5 }]}></View>
        <View style={{width: "100%", justifyContent: 'space-around', flexDirection: 'row', marginLeft: 5 }}>                       
            <View style={{flexDirection: "row", alignItems: "flex-start", justifyContent: "center", alignContent: "center" }}>
                <TouchableOpacity style={{flexDirection: "row", alignItems: "center", justifyContent: "center", padding: 5 }}
                  onPress={async () => didLike ? await handleUnlikePost(item.post_id) : await handleLikePost(item.post_id)}
                >
                {!didLike && 
                  <Ionicons name="ios-heart-outline" size={18} color={theme.TEXT_COLOR} />
                }

                {didLike && 
                  <Ionicons name="ios-heart" size={18} color={theme.TEXT_COLOR} />
                }

                <Text style={{marginLeft: 5, opacity: heartCount > 0 ? 100 : 0, color: theme.TEXT_COLOR}}>{heartCount > 0 ? heartCount : 1}</Text>
                </TouchableOpacity>
            </View>
            <View style={{flexDirection: "row", alignItems: "flex-start", justifyContent: "center", alignContent: "center" }}>
                <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                <TouchableOpacity onPress={async () => viewComments(item.post_id)} style={{padding: 5}} >
                  <FontAwesome5 name="comment" size={18} color={theme.ICON_COLOR} />
                </TouchableOpacity>             

                { commentCount > 0 &&
                    <Text style={{marginLeft: 5, color: theme.TEXT_COLOR}}>{commentCount}</Text>
                } 
                </View>
            </View>

        </View>

        <Text style={styles.description}>{excerpt()}</Text>
        <TouchableOpacity
          style={styles.button}
          onPress={() => moreDetails(item)}
        >
          <Text style={styles.buttonText}>MORE DETAILS</Text>
        </TouchableOpacity>
      </View>
    );
  };

  async function refreshFeed() {
    setRefreshing(true)
    await refetch()
    setRefreshing(false)
  }

  // 	<Pressable style={styles.button} onPress={async () => { await schedulePushNotification(); }}>
  // 	<Text style={styles.buttonText}>Press to schedule a notification"</Text>
  // </Pressable>

  return (
      <FlatList
        style={styles.flatlist}
        numColumns={1}
        initialNumToRender={4}
        data={feed}
        keyExtractor={(item) => item.post_id}
        renderItem={({ item, index }) => <Item item={item} />}
        onEndReached={loadMore}
        onEndReachedThreshold={0.75}
        onRefresh={() => refreshFeed()}
        refreshing={refreshing}

        
      />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BACKGROUND_ONE,
    color: theme.TEXT_COLOR,
    alignSelf:"center",
    justifyContent:"center",
  },
  itemContainer: {
    alignSelf:"center",
    width: "100%",
    minHeight: MINIMUM_ITEM_HEIGHT,
    maxHeight: MINIMUM_ITEM_HEIGHT * 2,
    maxWidth: MAX_WIDTH,
  },
  wrapper: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: theme.LINE_GRAY,
    marginVertical: 10,
    opacity: 0.6
  },
  infoContainer: {
    flex: 1,
    flexDirection: "row",
  },
  listHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logoText: {
    fontSize: 24,
    fontWeight: "500",
    marginLeft: 5,
    height: 50,
    alignSelf: "center",
    color: theme.HEADER_TITLE_COLOR,
  },
  text: {
    fontSize: 22,
    color: theme.TEXT_COLOR,
  },
  description: {
    color: theme.TEXT_COLOR,
    width: "100%",
    paddingHorizontal: 5,
    marginBottom: 20,
    fontSize: 16,
    lineHeight: 24,
    fontFamily:"OpenSans-Regular"
  },
  authorContainer: {
    flexDirection: "row",
    color: theme.TEXT_COLOR,
    width: "100%",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    padding: 5, 
  },
  authorName: {
    color: theme.SECTION_TITLE,
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 5,
    fontFamily:"Ubuntu-Bold"
  },
  date: {
    color: theme.TEXT_COLOR,
    fontSize: 14,
    marginLeft:5,
    fontFamily:"OpenSans-Regular"
  },
  badgesContainer: {
    position: "absolute",
    right: 0,
    flexDirection: "row",
    alignSelf: "flex-end",
    padding: 4

  },
  badge: {
    width: 50,
    height: 50,
    borderWidth: 0,
    borderColor: theme.LINE_GRAY,
    borderRadius: 0,
    marginRight: 0,
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  badgeText: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#fff",
    fontFamily:"Ubuntu-Bold"
  },
  badgeFocused: {
    backgroundColor: "#999",
  },
  badgeRelaxed: {
    backgroundColor: theme.SECTION_TITLE,
  },
  badgeHappy: {
    backgroundColor: "#f1f1f1",
  },
  profileContainer: {
    flexDirection: "row",
    width: '100%',
    paddingVertical: 5,
    paddingHorizontal:5,
    borderColor: theme.LINE_GRAY,
    backgroundColor: theme.BACKGROUND_ONE,
    justifyContent: 'space-between'
  },
  avatar: {
    width: 42,
    height: 42,
    borderRadius: 500,
    marginLeft: 10,
    borderWidth: 2,
    borderColor: theme.LINE_GRAY,
  },
  horizonltalAlign: {
    flexDirection: "row",
  },
  store: {
    color: theme.TEXT_COLOR,
    alignSelf: "baseline",
    padding: 0,
    marginRight: 10,
    fontSize: 13,
    fontWeight: "600",
    lineHeight: 24,
    alignSelf: 'center',
    fontFamily:"OpenSans-Regular"
  },
  textContainer: {
    paddingHorizontal: 0,
  },
  profileTitle: {
    fontSize: 18,
    fontFamily:"Ubuntu-Bold",
    marginTop: 10,
    color: theme.TITLE_COLOR,
  },
  profileItem: {
    marginTop: 5,
    marginRight: 25,
  },
  profileNumbers: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.TEXT_COLOR,
  },
  profileText: {
    fontSize: 16,
    color: theme.TEXT_COLOR,
  },
  iconsBottom: {
    backgroundColor: theme.ICON_COLOR,
    width: "100%",
    height: 60,
    paddingVertical: 10,
    borderTopWidth: 1,
    borderTopColor: theme.LINE_GRAY,
  },
  iconButton: {
    flex: 1,
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  iconText: {
    color: theme.TEXT_COLOR,
    fontSize: 12,
  },
  textActive: {
    color: theme.NATURAL_GREEN,
  },
  item: {
    flex:1,
    maxWidth: MAX_WIDTH,
    marginRight: 0,
    marginBottom: 0,
  
  },
  photo: {
    flex:1,
    width: "100%",

  },
  actionsIcons: {
    padding: 10,
  },
  actionsItem: {
    marginRight: 15,
  },
  likesContainer: {
    marginLeft: 10,
  },
  bold: {
    fontWeight: "bold",
    color: theme.TEXT_COLOR,
  },
  button: {
    borderWidth: 1,
    borderColor: theme.LINE_GRAY,
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    paddingVertical: 10,
    backgroundColor: theme.BACKGROUND_ONE,
    marginBottom: 30
  },
  buttonText: {
    color: theme.TEXT_COLOR,
    textTransform: "uppercase",
    fontFamily:"OpenSans-Bold"
  },
  loginBtn: {
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.BUTTON_ACTIVE_COLOR,
    alignSelf: 'center',
    width: "90%",
    marginBottom: 10
  },
  loginText: {
    fontSize: 20,
    color: theme.LOGIN_TEXT_COLOR,
    fontWeight: 'bold',
    fontFamily:"Ubuntu-Bold",
  },
  draw: {
    width: "100%",
    alignSelf: "flex-start",
    marginBottom:20
  },
  overallName: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily:"Ubuntu-Bold",
    color: theme.SECTION_TITLE
  },
  overallNumber: {
    fontSize: 35,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily:"Ubuntu-Bold",
    color: theme.TEXT_COLOR
  },

});
