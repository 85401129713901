import React, { useState, useRef } from "react";
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Dimensions, StatusBar } from "react-native";
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import { Fontisto, Feather, Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import axios from 'axios';
import LoadingView from '../components/Loading';
import { loginService, login } from "../services/UserService";
import { TapGestureHandler } from 'react-native-gesture-handler';
import Header from "../components/Header";
//assets
import colors from "../Colors"
import { SafeAreaView } from "react-native-safe-area-context";
const MAX_WIDTH = 580
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

const theme = colors.defaultTheme()

export default function Login({ navigation }) {

    const [fontsLoaded]= useFonts(fontsList);  

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const doubleTapRef = useRef("");
    const [loading, setLoading] = useState(false);

    const setUser = loginService((state) => state.setUser);

    const sendPostRequest = async () => {

        try {
            setLoading(true)
            const res = await login(username, password);
            const response = res.data
            setLoading("")
            if (response.success) {

                setErrorMessage("")
                setUser({
                    username: response.username,
                    authToken: response.authToken,
                    refreshToken: response.refreshToken,
                    email: response.email,
                    first_name: response.firstName,
                    last_name: response.lastName,
                    bio: response.bio,
                    following: response.following,
                    isLoggedIn: true
                })
            } else {
                setErrorMessage(response.message)
            }

        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
        }

    }

    function fillFields() {
        setUsername('pogo')
        setPassword('pogo')
    }

    if (loading != "") { return (<LoadingView message={loading} />) }

    return (

        <SafeAreaView style={styles.container}>
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
            <Header />
            <KeyboardAwareScrollView
            enableOnAndroid={true}
            enableResetScrollToCoords={false}
            containerContentStyle={{alignSelf: 'center'}}
            bounces={false}
            contentInsetAdjustmentBehavior="always"
            overScrollMode="always"
            showsVerticalScrollIndicator={true}
            style={{flex: 1, marginTop: 30, width: SCREEN_WIDTH, maxWidth: MAX_WIDTH, alignSelf: 'center'}}
            >
                <View style={styles.inputView}>
                    <TextInput
                        style={styles.TextInput}
                        autoFocus={true}
                        placeholder="Username"
                        placeholderTextColor={theme.PLACEHOLDER_COLOR}
                        value={username}
                        autoCapitalize='none'
                        onChangeText={(username) => setUsername(username)}
                    />
                </View>
                <View style={styles.inputView}>
                    <TextInput
                        style={styles.TextInput}
                        placeholder="Password"
                        placeholderTextColor={theme.PLACEHOLDER_COLOR}
                        secureTextEntry={true}
                        value={password}
                        autoCapitalize='none'
                        onChangeText={(password) => setPassword(password)}
                    />
                </View>
                <TouchableOpacity style={styles.loginBtn} onPress={() => sendPostRequest()}>
                    <Text style={styles.loginText}>Login</Text>
                </TouchableOpacity>
                <View style={styles.signupContainer}>
                    <TapGestureHandler ref={doubleTapRef} numberOfTaps={2} onActivated={fillFields}>
                        <Text style={styles.text}>Don't have credentials?</Text>
                    </TapGestureHandler>
                    <TouchableOpacity onPress={() => navigation.push("SignUp")}>
                        <Text style={styles.link}>Sign up</Text>
                    </TouchableOpacity>
                </View>
                {errorMessage != "" &&
                    <View style={styles.separator}>
                        <Text style={styles.alert}>{errorMessage}</Text>
                    </View>
                }
            </KeyboardAwareScrollView>

        </SafeAreaView>

    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        paddingTop: 10,
        paddingHorizontal: 10
    },
    separator: {
        marginVertical: 10
    },
    inputView: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        height: 45,
        marginBottom: 20,
        width: "90%",
        alignSelf: 'center'
    },
    TextInput: {
        height: 50,
        padding: 10,
        fontSize: 18,
        alignSelf: 'center',
        width: "90%",
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular",
    },
    text: {
        fontSize: 18,
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular",
    },
    alert: {
        fontSize: 18,
        color: "#f55",
        paddingVertical: 30
    },
    loginBtn: {
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        alignSelf: 'center',
        width: "90%"
    },
    loginText: {
        fontSize: 20,
        color: theme.LOGIN_TEXT_COLOR,
        fontWeight: 'bold',
        fontFamily:"Ubuntu-Bold",

    },
    title: {
        color: theme.TITLE_COLOR,
        fontSize: 35,
        padding: 50,
        textAlign: 'center'
    },
    userTitle: {
        color: theme.TITLE_COLOR,
        fontSize: 50,
        padding: 10,
        textAlign: 'center',
        textTransform: "capitalize"
    },
    listHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
        top: -150
    },
    logoText: {
        fontSize: 42,
        fontWeight: "800",
        marginLeft: 5,
        height: 50,
        alignSelf: "center",
        textTransform: "uppercase",
        color: theme.HEADER_TITLE_COLOR
    },
    signupContainer: {
        flexDirection: "row",
        padding: 20
    },
    link: {

        color: theme.LOGIN_LINK,
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 10,
        fontFamily:"OpenSans-Bold",

    },
});


