
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Dimensions, Button, TextInput, Pressable, Linking } from 'react-native';
import { Ionicons, Entypo, Fontisto, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import { Camera, CameraType } from 'expo-camera';
import * as ImagePicker from 'expo-image-picker';
import * as MediaLibrary from 'expo-media-library';
import Header from '../components/Header';
import { photoGallery } from "../services/MediaGallery"
import { allPosts } from "../services/PostServices"
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
//colors
import colors from "../Colors"

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const theme = colors.defaultTheme()

export default function AddPost({ navigation, route }) {

    const [type, setType] = useState(CameraType.back)

    const [fontsLoaded]= useFonts(fontsList);  
    
    const [hasCameraPermission, setHasCameraPermission] = useState(false);
    const [hasMediaPermission, setHasMediaPermission] = useState(false);
    const [limitedMediaAccess, setLimitedMediaAccess] = useState(false);
    const [canAskMediaPermission, setCanAskMediaPermission] = useState(false);

    const [startCamera, SetStartCamera] = useState(false)

    // const [permissionResponseOnLoad, requestMediaLibraryPermission] = MediaLibrary.usePermissions();
    const [mediaGallery, setMediaGallery] = useState(null);

    const [selectedMedia, setSelectMedia] = useState([])
    const [nextButton, setNextButton] = useState(false)

    const origin = allPosts((state) => state.origin);

    // const [cameraPermission, requestCameraPermission] =  Camera.useCameraPermissions();

    useEffect(() => {
      if (hasMediaPermission && limitedMediaAccess) {
        //console.log("subscribing");
        let libraryChangeSubscription = MediaLibrary.addListener(
          async (changeEvent) => {
        //console.log("HCNAGED", changeEvent);
            if (changeEvent.hasIncrementalChanges === 0) {
              await mediaLibraryAsync();
              // let permissionResponse = await MediaLibrary.getPermissionsAsync();
              // setHasCameraPermission(permissionResponse.granted);
            }
          }
        );
      } else {
        MediaLibrary.removeAllListeners();
      }
      return () => {
        MediaLibrary.removeAllListeners();
      };
    }, [hasMediaPermission, limitedMediaAccess]);

   useEffect(() => {        

		if(origin == "AddPost"){
			resetGallery()
		}
		
		//console.log(origin)	

	}, [origin]);
    
   useEffect(() => {        

		if(selectedMedia.length > 0){

            setNextButton(true)

        } else {

            setNextButton(false)
        }

	}, [selectedMedia]);


    useEffect( () => {
        async function checkPermission() {
            const permissionResponse = await MediaLibrary.getPermissionsAsync();
            const cameraPermissionResponse = await Camera.getCameraPermissionsAsync();
            setHasCameraPermission(cameraPermissionResponse.granted);
            setHasMediaPermission(permissionResponse.granted);
            //console.log("media permisssion" , permissionResponse);
            //console.log("camera permission", cameraPermissionResponse)
            setCanAskMediaPermission(permissionResponse.canAskAgain);
            if (permissionResponse.granted) {
                setLimitedMediaAccess(permissionResponse.accessPrivileges === 'limited');
            } else {
                setLimitedMediaAccess(false);
            }
        }
        checkPermission();
      return () => {
        MediaLibrary.removeAllListeners();
      };
    }, []);

    // const setPhotos = photoGallery((state) => state.setPhotos);
    // const clearGallery = photoGallery((state) => state.clearGallery);
    // const stockPhotos = photoGallery((state) => state.photos);


    const mediaLibraryAsync = async () => {
        
        let { status } = await MediaLibrary.requestPermissionsAsync()
        //const getPhotos = await MediaLibrary.getAlbumsAsync({ includeSmartAlbums: true })
        //console.log(getPhotos)
        let getAllPhotos = await MediaLibrary.getAssetsAsync({
            first: 20,
            sortBy: ['creationTime'],
            mediaType: ['photo'],
        })

        if (status == "granted") {
            setMediaGallery(getAllPhotos.assets)
        } else {
            console.log("access denied");
        }
    };

    // if (!cameraPermission) {
    //     // Camera permissions are still loading
    //     return <View />;
    // }

    // if (!cameraPermission.granted) {
    //     // Camera permissions are not granted yet
    //     return (
    //         <View style={styles.container}>
    //             <Text style={{ textAlign: 'center' }}>
    //                 We need your permission to show the camera
    //             </Text>
    //             <Button onPress={requestCameraPermission} title="grant permission" />
    //         </View>
    //     )
    // }

    function toggleCameraType() {
        setType((current) => (
            current === CameraType.back ? CameraType.front : CameraType.back
        ))
    }

    function selectItem(item) {
        
        let selMedia = [...selectedMedia]

        for (let i = 0; i < mediaGallery.length; i++) {

            if(mediaGallery[i].id == item.id){

                mediaGallery[i].selected = !mediaGallery[i].selected

                if(mediaGallery[i].selected){
                    selMedia.push(mediaGallery[i])
                } else {
                    selMedia = selMedia.filter((media) => mediaGallery[i].id != media.id )
                }
            }  
        }       

        

        setSelectMedia(selMedia)
        setMediaGallery([...mediaGallery])   

    }
    

    const Item = ({ item, source }) => {

        let position = selectedMedia.indexOf(item)

        return (
            <TouchableOpacity key={item.id} style={styles.item} onPress={() => selectItem(item)} >
                {item.selected &&
                <View style={styles.itemActive}>
                    <Text style={styles.itemActiveText}>{position + 1}</Text>
                </View>
                }
                <ImageBackground source={{ uri: source }} style={styles.photo} resizeMode="cover"/>
            </TouchableOpacity>
        )
    }

    // const footerGallery = () => {

    //     return (

    //     )
    // }

    const selectMoreMedia = async () => {
       let response = await MediaLibrary.presentPermissionsPickerAsync();
    }

    const headerGallery = () => {

        return (
          
            <View style={{height:"45%",flex:1, justifyContent:"center",alignItems:"center", paddingVertical:5}}>
                <Text style={styles.profileTitle}>Select media</Text>
            </View>

        )
    }


    function resetGallery() {

        setMediaGallery(null)
        setSelectMedia([])

    }

    async function openMediaLibrary() {
        if(hasMediaPermission) {
            mediaLibraryAsync();
        } else if (canAskMediaPermission) {
            const permissionResponse = await MediaLibrary.requestPermissionsAsync();
            //console.log('got response', permissionResponse);
            setHasMediaPermission(permissionResponse.granted);
            //console.log("media permisssion" , permissionResponse);
            setCanAskMediaPermission(permissionResponse.canAskAgain);
            if (permissionResponse.granted) {
                setLimitedMediaAccess(permissionResponse.accessPrivileges === 'limited');
            } else {
                setLimitedMediaAccess(false);
            }
        } else {
            Linking.openURL('app-settings:')
        }
    }

    return (
        <SafeAreaView style={{ height: "100%", backgroundColor: theme.BACKGROUND_ONE }}>
            {startCamera &&
                <Camera style={styles.camera} type={type}>
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity
                            style={styles.flipCamera}
                            onPress={toggleCameraType}>
                            <Ionicons name="ios-camera-reverse" size={36} color={theme.CAMERA_ICONS_COLOR} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.takePhoto}
                            onPress={() => SetStartCamera(false)}>
                            <Ionicons name="ios-aperture" size={84} color={theme.CAMERA_ICONS_COLOR} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={styles.closeCamera}
                            onPress={() => SetStartCamera(false)}>
                            <Ionicons name="ios-close" size={36} color={theme.CAMERA_ICONS_COLOR} />
                        </TouchableOpacity>
                    </View>
                </Camera>

            }
            {!mediaGallery &&
            <View style={styles.container}>
         
             
            <ScrollView style={styles.scrollView} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', alignItems: "center" }}>
                {!mediaGallery &&
                    <View>
                        <Text style={styles.profileTitle}>Select media</Text>
                    </View>
                }
                {!mediaGallery &&
                    <View style={styles.mediaContainer}>
                        <Pressable style={[styles.mediaItem,{opacity:0.3}]} onPress={() => SetStartCamera(true)} disabled={true}>
                            <Entypo name="camera" size={48} color={theme.TEXT_COLOR} />
                            <Text style={styles.mediaText}>Camera</Text>
                        </Pressable>
                        <TouchableOpacity style={styles.mediaItem} onPress={openMediaLibrary} >
                            <MaterialIcons name="perm-media" size={48} color={theme.TEXT_COLOR} />
                            <Text style={styles.mediaText}>Media library</Text>
                        </TouchableOpacity>
                        { !hasMediaPermission && (
                           <View><Text>The app required media permission</Text></View>
                        )}
                    </View>
                }

            </ScrollView>
            </View> 
            }
            {mediaGallery &&
                <View style={styles.container}>
                <Header
                    showBackButton={true}
                    showLogo={false}
                    showActionButton={selectedMedia.length > 0  ? true : false}
                    backButtonLabel={"Cancel"}
                    backButtonPressed={resetGallery}
                    actionButtonPressed={() => navigation.push('Add post form', {mediaGallery: selectedMedia})}
                
                />
                <FlatList
                    style={{ height: "100%", backgroundColor: theme.BACKGROUND_ONE }}
                    numColumns={3}
                    data={mediaGallery}
                    keyExtractor={(item, index) => item.id }
                    ListHeaderComponent={headerGallery}
                    renderItem={({ item, index }) =>
                        <Item
                            item={item}
                            source={item.uri}

                        />}
                />
                </View> 
            } 
             { mediaGallery && limitedMediaAccess && (
                <View style={styles.managePermissionContainer}>
                    <Text style={{ width: 30, flexGrow: 1, marginRight: 60, height: 34, color:theme.TEXT_COLOR }}>You gave Joyntly access to some of your photos</Text>
                    <Pressable onPress={selectMoreMedia}><Text style={{ fontSize: 14, fontWeight: 'bold', color: theme.LINK_COLOR }}>Manage</Text>
                    </Pressable>
                </View>
              )}
                       
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    scrollView: {
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        margin: 0,
        width: "100%",
        paddingTop: 10,
        height: "50%"
    },
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        margin: 0,
        width: "100%",
        justifyContent: 'center',
    },
    managePermissionContainer:{
        backgroundColor: theme.BACKGROUND_TWO, 
        flexDirection: 'row',        
        alignItems: 'center', 
        justifyContent: 'space-between', 
        paddingHorizontal: 20, 
        paddingVertical:20
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginVertical: 10,
        color: theme.TEXT_COLOR,
        fontFamily:"Ubuntu-Bold"
    },
    mediaContainer: {
        width: "95%",
        paddingVertical: 15,
        borderBottomWidth: 0,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 0,
        alignItems: 'center',
        borderWidth: 0,
    },
    mediaItem: {
        width: 120,
        height: 120,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        marginHorizontal: "7%"
    },
    mediaText: {
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular"
    },
    camera: {
        height: SCREEN_HEIGHT - 150,
        position: "absolute",
        width: "100%",
        zIndex: 1000

    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: "space-between",
        marginTop: 20

    },
    closeCamera: {

        position: "absolute",
        bottom: 55,
        right: 50


    },
    flipCamera: {

        position: "absolute",
        bottom: 55,
        left: 45


    },
    takePhoto: {

        position: "absolute",
        bottom: 45,
        left: SCREEN_WIDTH / 2.55


    },
    formContainer: {

        width: "95%",
        height: 400,

    },
    textInput: {
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        marginBottom: 10,
        padding: 10,
        fontSize: 18,
        color: theme.TEXT_COLOR
    },
    textArea: {
        borderWidth: 1,
        minHeight: 128,
        borderColor: theme.LINE_GRAY,
        marginBottom: 10,
        padding: 10,
        fontSize: 18,
        color: theme.TEXT_COLOR
    },
    preview: {
        width: SCREEN_WIDTH - 20,
        height: (SCREEN_WIDTH / 200) * 175,
        marginBottom: 20
    },
    button: {
        backgroundColor: theme.BUTTON_COLOR,
        height: 40,
        width: "49%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",

    },
    buttonActive: {

        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "49%",
        borderColor: theme.LINE_GRAY

    },
    buttonActiveDisabled: {
        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "49%",
        opacity: 0.3
    },
    buttonText: {
        color: theme.BUTTON_TEXT_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    buttonTextActive: {
        color: theme.BUTTON_TEXT_ACTIVE_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    item: {
        width: SCREEN_WIDTH / 3,
        height: 120,
        marginRight: 2,
        marginBottom: 2
    },
    itemActive: {
        width: "100%",
        height: 120,
        position:"absolute",
        backgroundColor: theme.PHOTO_SELECTED,
        overflow: "hidden",
        zIndex:10,
        flex:1,
        justifyContent:"center",
        alignItems:"center"
  
    },
    itemActiveText:{

        color:"#fff",
        fontSize:50,
        fontWeight:"600",
        borderWidth:1,
        paddingVertical:20,
        paddingHorizontal:42,
        borderRadius:6,
        borderColor:theme.PHOTO_SELECTED_LINE,        
        zIndex:11

    },
    photo: {
        width: "100%",
        height: 120
    },





});
