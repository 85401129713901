import React from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import Configuration from "../config/Configuration";
import axios from "axios";
const SERVER = Configuration.BASE_SERVER_URL;
import _ from "lodash";
import {
  updateHomeFeedCacheAfterLike,
  updateHomeFeedCacheAfterUnlike,
  updatePostsCacheAfterUnlike,
  updatePostsCacheAfterLike,
} from "./CacheUtils";

const fetchHomeFeed = async ({ token, pageParam, limit }) => {
  let url = `${SERVER}/api/feed/home?page=${pageParam}&limit=${limit}`;
  let headers = { headers: { Authorization: `Bearer ${token}` } };
  let res = await axios.get(url, headers);
  return res.data;
};

const likePostNet = async ({ token, postId }) => {
  try {
    let url = `${SERVER}/api/posts/${postId}/like`;
    const response = await axios.post(
      url,
      { reaction_type: "heart", content_type: "post" },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.reaction;
  } catch (e) {
    console.log(e);
    return e;
  }
};

const unLikePostNet = async ({ token, postId }) => {
  try {
    let url = `${SERVER}/api/posts/${postId}/deslike`;
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const reaction = _.cloneDeep(response.data.reaction);
    reaction.content_id = postId;
    return reaction;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default function useHomeFeedPosts({ token, limit = 10 }) {
  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    ["homefeed"],
    ({ pageParam = 1 }) => fetchHomeFeed({ token, pageParam, limit }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.posts.length == limit) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  const queryClient = useQueryClient();

  const likePost = useMutation(
    async (postId) => {
      return await likePostNet({ token, postId });
    },
    {
      onSuccess: (reaction) => {
        updateHomeFeedCacheAfterLike({ queryClient, reaction });
        updatePostsCacheAfterLike({ queryClient, reaction });
      },
    }
  );

  const unlikePost = useMutation(
    async (postId) => {
      return await unLikePostNet({ token, postId });
    },
    {
      onSuccess: (reaction) => {
        updatePostsCacheAfterUnlike({ queryClient, reaction });
        updateHomeFeedCacheAfterUnlike({ queryClient, reaction });
      },
    }
  );
  const handleLikePost = async (postId) => {
    await likePost.mutate(postId);
  };

  const handleUnlikePost = async (postId) => {
    await unlikePost.mutate(postId);
  };

  return {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    handleLikePost,
    handleUnlikePost,
    refetch,
  };
}
