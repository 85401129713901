import React, { useState } from "react";
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Dimensions, SafeAreaView, StatusBar,Pressable } from "react-native";
import { Fontisto, Feather, Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import axios from 'axios';
import Header from "../components/Header";
import LoadingView from '../components/Loading';
import { loginService, signUp } from "../services/UserService";
import Checkbox from 'expo-checkbox';
//assets
import colors from "../Colors"
const MAX_WIDTH = 580
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');

const theme = colors.defaultTheme()

export default function SignUp({ navigation }) {

    const [fontsLoaded]= useFonts(fontsList);  

    const [username, setUsername] = useState(""); 
    const [agree, setAgree] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [bio, setBio] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const setUser = loginService((state) => state.setUser);

    const sendPostRequest = async () => {

        try {
            setLoading(true)
            const res = await signUp(username, password, email, firstName, lastName, bio);
            const response = res.data

            console.log(response)

            setLoading("")

            if (response.success) {

                setErrorMessage("")

                setUser({
                    username: response.username,
                    authToken: response.authToken,
                    refreshToken:response.refreshToken,
                    email:response.email,
                    first_name: response.firstName,
                    last_name: response.lastName,
                    bio: response.bio,
                    following:[],
                    isLoggedIn: true
                })

            } else {

                setErrorMessage(response.message)

            }



        } catch (error) {

            console.log(error)

            setErrorMessage(error.message)
            setLoading(false)
        }

    }

    if (loading != "") { return (<LoadingView message={loading} />) }

    return (
        <SafeAreaView style={styles.container} >
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
            <Header
                navigation={navigation}
                showLogo={true}
                showBackButton={true}
                backButtonLabel={"Cancel"}
             

            />  
            <KeyboardAwareScrollView
            enableOnAndroid={true}
            enableResetScrollToCoords={false}
            bounces={false}
            contentContainerStyle={{alignItems:"center", justifyContent: "center"}}
            contentInsetAdjustmentBehavior="always"
            overScrollMode="always"
            showsVerticalScrollIndicator={true}
            style={{width:SCREEN_WIDTH, maxWidth:MAX_WIDTH,marginTop:30, backgroundColor: theme.BACKGROUND_ONE
            }}
            >

            {errorMessage != "" &&
            <View style={styles.separator}>
                <Text style={styles.alert}>{errorMessage}</Text>
            </View>
            }
      
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Username"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    autoCapitalize='none'
                    onChangeText={(username) => setUsername(username)}
                    defaultValue={username}
                    autoFocus={true}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Email"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    autoCapitalize='none'
                    onChangeText={(email) => setEmail(email)}
                    defaultValue={email}
                />
            </View>
            <View style={styles.inputView}>
                <TextInput
                    style={styles.TextInput}
                    placeholder="* Password"
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    secureTextEntry={true}
                    autoCapitalize='none'
                    onChangeText={(password) => setPassword(password)}
                    defaultValue={password}
                />
            </View>
            <View style={styles.hAlign}>
                <View style={styles.inputViewDouble}>
                    <TextInput
                        style={styles.TextInput}
                        placeholder="First name"
                        placeholderTextColor={theme.PLACEHOLDER_COLOR}
                        onChangeText={(firstName) => setFirstName(firstName)}
                        defaultValue={firstName}
                    />
                </View>

                <View style={styles.inputViewDouble}>
                    <TextInput
                        style={styles.TextInput}
                        placeholder="Last name"
                        placeholderTextColor={theme.PLACEHOLDER_COLOR}
                        onChangeText={(lastName) => setLastName(lastName)}
                        defaultValue={lastName}
                    />
                </View>
            </View>
            <View style={styles.inputViewArea}>
                <TextInput
                    style={styles.textArea}
                    placeholder="Your weed story"
                    multiline
                    numberOfLines={6}
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    onChangeText={(bio) => setBio(bio)}
                    defaultValue={bio}
                />
            </View>
            <View>
                <Text style={[styles.text,{fontSize:14}]} >fields with * are mandatory</Text>
            </View>
            <View style={styles.horizontalAlign}>
                <Checkbox
                    style={styles.checkbox}
                    value={agree}
                    onValueChange={setAgree}
                    color={theme.NATURAL_GREEN}
                />
                <Text style={styles.terms}>I agree with the </Text>
                <Pressable onPress={() => navigation.push("TermsPage")}>
                    <Text style={styles.linkText}>terms of service</Text>
                </Pressable>
            </View>
            <TouchableOpacity style={[styles.loginBtn,{opacity: agree ? 1.0 : 0.3 }]} onPress={() => sendPostRequest()} disabled={!agree}>
                <Text style={styles.loginText}>Sign up</Text>
            </TouchableOpacity>



            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        alignItems: 'center',
        width: "100%",
        backgroundColor: theme.BACKGROUND_ONE,
    },
    separator: {

        height:50,
        marginBottom:10

    },
    inputView: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 20,
        width: "90%",
        alignSelf: 'center',
        padding:10
    },
    inputViewArea: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        height: 100,
        width: "90%",
        alignSelf: 'center',
        padding:10
        
    },
    inputViewDouble: {
        backgroundColor: theme.BACKGROUND_TWO,
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 20,
        width: "49%",
        padding:10
    },
    TextInput: {
        fontSize: 18,
        alignSelf: 'center',
        width: "100%",
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular",

    },
    textArea: {
        width: "100%",
        alignSelf: "center",
        borderWidth: 0,
        minHeight: 100,
        fontSize: 18,
        color: theme.TEXT_COLOR,
        textAlignVertical: 'top'
    },
    text: {
        fontSize: 18,
        color: theme.TEXT_COLOR,
        width: SCREEN_WIDTH * 0.90,
        paddingVertical:10,
        textAlign:"center",
        fontFamily:"OpenSans-Regular",
 
    },
    terms: {
        fontSize: 14,
        color: theme.TEXT_COLOR, 
        fontFamily:"OpenSans-Regular",
    },
    alert: {
        fontSize: 18,
        color: "#f55",
        paddingVertical: 30,
        fontFamily:"Ubuntu-Regular",
    },
    loginBtn: {
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        alignSelf: 'center',
        width: "90%",
        marginTop:20
    },
    loginText: {
        fontSize: 20,
        color: theme.LOGIN_TEXT_COLOR,
        fontWeight: 'bold',
        fontFamily:"Ubuntu-Bold",
        

    },
    title: {
        color: theme.TITLE_COLOR,
        fontSize: 35,
        padding: 50,
        textAlign: 'center'
    },
    userTitle: {
        color: theme.TITLE_COLOR,
        fontSize: 50,
        padding: 10,
        textAlign: 'center',
        textTransform: "capitalize"
    },
    listHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 50
    },
    logoText: {
        fontSize: 42,
        fontWeight: "500",
        marginLeft: 5,
        height: 50,
        alignSelf: "center",
        color: theme.HEADER_TITLE_COLOR
    },
    signupContainer: {
        flexDirection: "row",
        padding: 20
    },
    link: {

        color: theme.BUTTON_ACTIVE_COLOR,
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 10

    },
    hAlign: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "90%"
    },
    horizontalAlign: {
        flexDirection: "row",
        justifyContent:"flex-start",
        marginTop:20

    },
    linkText: {

        color: theme.LOGIN_LINK,
        fontSize: 14,
        fontWeight: "bold",
        fontFamily:"OpenSans-Bold",


    },
    checkbox:{
        marginRight:10
    }

});


