import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Configuration from "../config/Configuration";
import axios from "axios";
const SERVER = Configuration.BASE_SERVER_URL;

const fetchComments = async ({ post_id }, authToken = null) => {
  if (!post_id) {
    return null;
  }
  let headers = {};

  if (authToken) {
    headers = { headers: { Authorization: `Bearer ${authToken}` } };
  }

  let url = `${SERVER}/api/posts/${post_id}/comments`;
 
  try {
    let res = await axios.get(url, headers);

    return res.data.comments;
  } catch (e) {
    console.log(e);
  }
};

const createComment = async ({ post_id, comment }, authToken = null) => {
  if (!post_id) {
    return null;
  }
  let headers = {};

  if (authToken) {
    headers = { headers: { Authorization: `Bearer ${authToken}` } };
  }
  
  let data = { comment_data: comment }

  let url = `${SERVER}/api/posts/${post_id}/comments/new`;
 
  try {
    let res = await axios.post(url, data, headers);
    return res.data.comment;
  } catch (e) {
    console.log("==>", e);
  }
};

const deleteComment = async ({ comment_id }, authToken = null) => {
  if (!comment_id) {
    return null;
  }
  let headers = {};

  if (authToken) {
    headers = { headers: { Authorization: `Bearer ${authToken}` } };
  }
  
  let url = `${SERVER}/api/posts/comments/${comment_id}`;
 
  try {
    let res = await axios.delete(url, headers);
    return res.data.comment;
  } catch (e) {
    console.log("==>", e);
  }
}

export default function usePostComments(post_id, authToken = null) {

  const { data, isLoading, refetch } = useQuery(["comments", post_id], () =>
    fetchComments({ post_id }, authToken)
  );
 
  return {
    data,
    fetchComments,
    createComment,
    deleteComment,
    refetch
  };
}
