
import React, { useEffect, useState } from "react";
import { loginService } from "../services/UserService";
import { StyleSheet, StatusBar, Text, TextInput, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Dimensions, Platform, useWindowDimensions, KeyboardAvoidingView } from 'react-native';
import usePostComments from "../hooks/usePostComments";
import colors from "../Colors";
import Header from "../components/Header";
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import { KeyboardAwareFlatList, KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import moment from 'moment';
import { updateHomeFeedCacheAfterComment, updatePostsCacheAfterComment } from "../hooks/CacheUtils";
import { useQueryClient } from "react-query";
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

const theme = colors.defaultTheme();
const { height: SCREEN_HEIGHT, width: SCREEN_WIDTH } = Dimensions.get('window');

export default function ViewComments({ navigation, route }) {

  const avatarImage = { uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612" };


  const [fontsLoaded]= useFonts(fontsList);  
  const authToken = loginService((state) => state.authToken);
  const loggedUserName = loginService((state) => state.username)
  const [refreshing, setRefreshing] = useState(false);
  const [comment, setComment] = useState("");
  const [confirmCommentId, setConfirmCommentId] = useState(null);
  const { isLoading, data, refetch, fetchComments, createComment, deleteComment } = usePostComments(route.params.post_id, authToken)
  const queryClient = useQueryClient();
  let comments = (data && data.length > 0) ? data : []

  function refreshComments() {
    setRefreshing(true)
    refetch()
    setRefreshing(false)
  }

  function onChangeComment(text) {
    setComment(text)
  }

  function onDeleteCommentConfirm(comment_id) {
    setConfirmCommentId(comment_id)
  }
  async function onDeleteComment(comment_id) {
    await deleteComment({ comment_id: comment_id }, authToken)
    updateHomeFeedCacheAfterComment({ queryClient, post_id: route.params.post_id, operation: "delete" });
    updatePostsCacheAfterComment({ queryClient, post_id: route.params.post_id, operation: "delete" });
    await refreshComments()
  }

  async function sendComment() {
    if (comment.trim() != "") {
      await createComment({ post_id: route.params.post_id, comment: comment }, authToken)
      updateHomeFeedCacheAfterComment({ queryClient, post_id: route.params.post_id, operation: "add" });
      updatePostsCacheAfterComment({ queryClient, post_id: route.params.post_id, operation: "add" });
      setComment("")
      refreshComments()
    }
  }

  function Item(props) {

    async function onDeleteComment(commentId) {
      props.onDeleteCommentConfirm(commentId)
    }
  
    const timeago = moment(new Date(props.item.created_at)).fromNow();

    function dryTimeAgo(text){

      let dryText = text.replace("an hour ago","1H").replace("hours ago","H").replace("a day","1D").replace("days","D").replace("minutes ago","M").replace(/\s/g, "")
  
      if(text != "Invalid date"){
        return dryText
      }else {
        return ""
      }


      
    }
  
    return (
      <View style={styles.itemContainer}>
        <View style={{width:"10%"}}>
          <View>
            <Image source={avatarImage} style={styles.avatar} />
          </View>
        </View>
        <View style={{width:"90%"}}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Text style={{ fontFamily: "Ubuntu-Bold", fontSize: 14, color: theme.TEXT_COLOR }} >{props.item.user_name} </Text>
            <Text style={{ fontSize: 12, lineHeight: 18, color: theme.PLACEHOLDER_COLOR, fontFamily: "OpenSans-Regular" }} >{dryTimeAgo(timeago)}</Text>
          </View>
          <Text style={{
            marginTop: 10,
            fontFamily: (props.item.deleted_at) ? "OpenSans-Italic" : "OpenSans-Regular",
            color: (props.item.deleted_at) ? theme.PLACEHOLDER_COLOR : theme.TEXT_COLOR,
            fontSize: (props.item.deleted_at) ? 14 : 16
          }}>
            {props.item.text}
          </Text>

          {props.user_name == props.item.user_name && !(props.item.deleted_at) &&
            <TouchableOpacity style={{ position: "absolute", top: 5, right: 5, alignSelf: "flex-end" }} onPress={() => onDeleteComment(props.item.comment_id)}>
              <Ionicons name="ios-trash" size={18} color={theme.PLACEHOLDER_COLOR} />
            </TouchableOpacity>
          }
        </View>
      </View>
  
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
      <Header
        navigation={navigation}
        showLogo={false}
        showBackButton={true}
      />
      <KeyboardAvoidingView behavior={Platform.select({ android: "height", ios: "padding" })}
      keyboardVerticalOffset={Platform.select({
        android: () => 200
       })}

      style={{ flex: 1 }}>
        {comments && comments.length == 0 &&
          <Text style={{color:theme.TEXT_COLOR, alignSelf: 'center'}}>No comments yet</Text>
        }
        <FlatList
            style={styles.list}
            numColumns={1}
            initialNumToRender={4}
            data={comments}
            keyExtractor={(item) => "comment" + item.comment_id}
            renderItem={({ item, index }) => <Item
              item={item}
              user_name={loggedUserName}
              onDeleteCommentConfirm={onDeleteCommentConfirm}
            />}
            onRefresh={() => refreshComments()}
            refreshing={refreshing}
            contentContainerStyle={{ flexGrow: 1 }}
          />

          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              onChangeText={onChangeComment}
              placeholder="Add your comment here"
              value={comment}
            />
            <TouchableOpacity style={styles.button} onPress={() => sendComment()}>
              <Ionicons name="ios-send-outline" size={22} color={theme.TEXT_COLOR}  />
            </TouchableOpacity>
          </View>
      </KeyboardAvoidingView>


      {confirmCommentId &&
        <View style={{ alignSelf: 'center', position: "absolute", backgroundColor: theme.BACKGROUND_ONE, borderWidth: 1, borderRadius: 10, borderColor: theme.LINE_GRAY, padding: 50, zIndex: 999 }}>
          <Text style={{ color: theme.TEXT_COLOR }}>Are you sure you want to delete?</Text>
          <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
            <TouchableOpacity onPress={() => setConfirmCommentId(null)} style={[styles.smallButton]}>
              <Text style={{ color: theme.TEXT_COLOR }}>No</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={async () => {
              onDeleteComment(confirmCommentId)
              setConfirmCommentId(null)
            }} style={styles.smallButton}>
              <Text style={{ color: theme.TEXT_COLOR }}>Yes</Text>
            </TouchableOpacity>

          </View>
        </View>
      }

    </SafeAreaView>)
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.BACKGROUND_ONE,
    color: theme.TEXT_COLOR,
    alignSelf: "center",
    justifyContent: "center",
  },
  avatar: {
      width: SCREEN_WIDTH > 500 ? 48 : 26,
      height: SCREEN_WIDTH > 500 ? 48 : 26,
      borderRadius: 500,
      borderWidth: 2,
    borderColor: theme.LINE_GRAY
  },
  inputContainer: {
    flexDirection: 'row',
    padding: 5,
    height: 60,
    width: '100%',
    bottom: 0,
    backgroundColor: theme.BACKGROUND_ONE,
    maxWidth:780,
    alignSelf:"center",
    marginBottom: Platform.OS == "web" ? 50 : Platform.OS == "ios" ? 0 : 0
  },
  itemContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 50,
    marginVertical: 3,
    padding: 10,
    borderRadius:5,
    backgroundColor: theme.BACKGROUND_TWO,
    maxWidth: 780, 
    width:"95%",
    alignSelf: 'center'
  },
  list: {
    flex: 1,
    height: SCREEN_HEIGHT * 0.8,
    width: '100%'
  },
  input: {
    width: '90%',
    height: 40,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderColor: theme.LINE_GRAY,
    borderWidth: 1,
    borderRadius: 5,
    fontSize: 16,
    color: theme.TEXT_COLOR,
    backgroundColor: theme.BACKGROUND_ONE,
    fontFamily:"OpenSans-Regular"
  },
  button: {
    height: 40,
    width: "10%",
    top:10,
    left:5, 
    alignItems: "center",
  },
  buttonText: {
    fontSize: 20,
    color: theme.LOGIN_TEXT_COLOR,
    fontWeight: 'normal',
    fontFamily: "Ubuntu",

  }
})