import React, { useState } from "react";
import {
    View,
    Text,
    Image,
    ImageBackground,
    StyleSheet,
    TextInput,
    FlatList,
    Pressable,
    Dimensions,
    TouchableOpacity
} from "react-native";
import moment from 'moment';
import colors from "../Colors"
const theme = colors.defaultTheme()
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
import useAllPosts from "../hooks/useAllPosts";
import { loginService } from "../services/UserService";
import LoadingView from '../components/Loading';


export default function SearchProductTab({navigation}) {

    const avatarImage = { uri: "https://images.unsplash.com/photo-1616690002554-b53821496f45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80" };
    const date = new Date(1663259698002)
    const timeago = moment(date).fromNow();
    const authToken = loginService((state) => state.authToken);
    const { isLoading, data, hasNextPage, fetchNextPage, refetch } = useAllPosts({limit: 20, token: authToken });
    const [refreshing, setRefreshing] = useState(false);

    let posts = [];
    if (!isLoading) {
        posts = data.pages.map(page => page.posts).flat();
        // posts.sort((a, b) => {
        //     return b.timestamp - a.timestamp;
        // });
    }

    if (isLoading) {
        return (
        <LoadingView></LoadingView>
        )
    }

    const loadMore = () => {
        if (hasNextPage) {
          fetchNextPage();
        } 
      };

    async function refreshFeed() {
        setRefreshing(true)
        await refetch()
        setRefreshing(false)
    }      

    function singleView(item) {
		navigation.push('SinglePost', item)
	}
    

    const Item = ({ item, source }) => {
        return (
            <TouchableOpacity style={styles.item} onPress={() => singleView(item)}>
                <ImageBackground source={{ uri: source }} style={styles.photo} resizeMode="cover" />
            </TouchableOpacity>
        )
    }

    return (
            <FlatList
                columnWrapperStyle={{maxWidth: 780, alignSelf: 'center', width: '100%'}}
                style={{ height: SCREEN_HEIGHT, width:"100%", backgroundColor: theme.BACKGROUND_TWO }}
                numColumns={3}
                horizontal={false}
                data={posts}
                initialNumToRender={10}
                renderItem={({ item, index }) =>
                    <Item
                        item={item}
                        source={item.media && item.media.length > 0 ? item.media[0].location : "http://"}
                    />}
                onEndReached={loadMore}
                onEndReachedThreshold={0.75}
                onRefresh={() => refreshFeed()}
                refreshing={refreshing}
            />
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 780,
    },
    avatar: {
        width: 100,
        height: 85,
        borderRadius: 0,
        borderWidth: 2,
        borderColor: theme.LINE_GRAY
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    textContainer: {
        paddingHorizontal: 0,
        width: 200
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        color: theme.TITLE_COLOR
    },
    brandTitle: {
        fontSize: 12,
        fontWeight: "600",
        color: theme.TITLE_COLOR
    },
    followersTitle: {
        fontSize: 12,
        fontWeight: "bold",
        color: theme.TITLE_COLOR
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    button: {
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        width: "90%",
        alignSelf: "center",
        alignItems: "center",
        paddingVertical: 10,
        backgroundColor: theme.EDIT_PROFILE_BUTTON,
    },
    buttonText: {
        color: theme.TEXT_COLOR,
        textTransform: "uppercase"
    },
    fullName: {
        color: theme.TEXT_COLOR,
        paddingLeft: 0,
        paddingVertical: 10,
        paddingBottom: 5,
        fontWeight: "300",
        fontSize: 14
    },
    date: {
        color: theme.TEXT_COLOR,
        paddingLeft: 0,
        paddingTop: 3,
        paddingBottom: 5,
        fontWeight: "300",
        fontSize: 12
    },
    item: {
        width: "33%",
        height: 120,
        marginRight: 2,
        marginBottom: 2
    },
    photo: {
        width: "100%",
        height: 120
    },
});

