import React from "react";
import { useQuery } from "react-query";
import Configuration from "../config/Configuration";
import axios from "axios";
const SERVER = Configuration.BASE_SERVER_URL;

const fetchProfile = async ({ username }) => {
  let url = `${SERVER}/api/profile/${username}/info`;
  try {
    let res = await axios.get(url);
    return res.data.info;
  } catch (e) {
    console.log(e);
  }
};

export default ({ username }) => {
  return useQuery(["profiles", username], () => fetchProfile({ username }));
};
