import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Configuration from "../config/Configuration";
import axios from "axios";
const SERVER = Configuration.BASE_SERVER_URL;
import _ from "lodash";
import {
  updateHomeFeedCacheAfterLike,
  updateHomeFeedCacheAfterUnlike,
  updatePostsCacheAfterLike,
  updatePostsCacheAfterUnlike,
} from "./CacheUtils";

const fetchPost = async ({ post_id }, authToken = null) => {
  if (!post_id) {
    return null;
  }
  let headers = {};

  if (authToken) {
    headers = { headers: { Authorization: `Bearer ${authToken}` } };
  }

  let url = `${SERVER}/api/posts/view/${post_id}`;

  try {
    let res = await axios.get(url, headers);
    return res.data.post;
  } catch (e) {
    console.log(e);
  }
};

export default function usePost({ post_id }, authToken = null) {
  const { data, isLoading } = useQuery(["posts", post_id], () =>
    fetchPost({ post_id }, authToken)
  );

  const queryClient = useQueryClient();

  const likePost = useMutation(
    async (postId) => {
      try {
        let url = `${SERVER}/api/posts/${postId}/like`;
        const response = await axios.post(
          url,
          { reaction_type: "heart", content_type: "post" },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const data = await response.data;
        return data.reaction;
      } catch (e) {
        console.log(e);
        return e;
      }
    },

    {
      onError: (e) => {
        console.log("ERROR liking", e);
      },
      onSuccess: (reaction) => {
        // Update the query cache with the new post data
        updatePostsCacheAfterLike({ queryClient, reaction });
        updateHomeFeedCacheAfterLike({ queryClient, reaction });
      },
    }
  );

  const unlikePost = useMutation(
    async (postId) => {
      try {
        let url = `${SERVER}/api/posts/${postId}/deslike`;
        const response = await axios.post(
          url,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const data = response.data;
        data.reaction.content_id = postId;
        return data.reaction;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    {
      onSuccess: (reaction) => {
        // Update the query cache with the new post data
        updatePostsCacheAfterUnlike({ queryClient, reaction });
        updateHomeFeedCacheAfterUnlike({ queryClient, reaction });
      },
    }
  );

  const handleLikePost = async (postId) => {
    await likePost.mutate(postId);
  };

  const handleUnlikePost = async (postId) => {
    await unlikePost.mutate(postId);
  };

  return {
    data,
    isLoading,
    handleLikePost,
    handleUnlikePost,
  };
}
