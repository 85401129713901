import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, useWindowDimensions } from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
//colors
import colors from '../Colors';
const theme = colors.defaultTheme();

export default function Header(props) {   

    const [fontsLoaded]= useFonts(fontsList);  
    
    const showLogo = props.showLogo == false ? false : true 
    const actionButtonPressed = props.actionButtonPressed
    const backButtonPressed = props.backButtonPressed
	const showBackButton = props.showBackButton ? props.showBackButton : false
	const showActionButton = props.showActionButton ? props.showActionButton : false
    const backButtonLabel = props.backButtonLabel ? props.backButtonLabel : "Back"
    const actionButtonLabel = props.actionButtonLabel ? props.actionButtonLabel : "Next"
    
    const {height, width} = useWindowDimensions();
    //console.log(showLogo)   

    const backButtonAction = () => {

        if(props.navigation){

            const {goBack} = props.navigation 

            goBack()

        } 
    }


    return (
        <SafeAreaView style={[styles.container,{paddingVertical: width > 500 ? 10 : 0}]}>
            <TouchableOpacity style={styles.button} onPress={() => backButtonPressed ? backButtonPressed() : backButtonAction() } disabled={showBackButton == false ? true : false}> 
                {showBackButton == true && 
                <View style={{flexDirection:"row"}}>    
                    <Ionicons name="ios-arrow-back" size={20} color={theme.HEADER_BUTTON_LABEL_COLOR} /> 
                    <View><Text style={[styles.buttonLabel,{textAlign:"left",}]}>{backButtonLabel}</Text></View>
                </View>
                }             
            </TouchableOpacity>
            
            <View style={styles.logo}>
            {showLogo == true &&
                <View style={styles.logo}>
                <MaterialIcons name="icecream" size={26} color="purple" />
                <View>
                    <Text style={styles.logoText}><Text style={{ color: theme.NATURAL_GREEN }}>Joy</Text>ntly</Text>
                </View>
                </View>
            }
            </View>
           
            <TouchableOpacity style={styles.button} onPress={()=> actionButtonPressed()} disabled={showActionButton == false ? true : false}>
                {showActionButton == true &&   
                <View style={{flexDirection:"row",justifyContent:"flex-end"}}>    
                    <View><Text style={[styles.buttonLabel,{textAlign:"right",}]}>{actionButtonLabel}</Text></View>
                    <Ionicons name="ios-arrow-forward" size={20} color={theme.HEADER_BUTTON_LABEL_COLOR} /> 
                </View>
                }
            </TouchableOpacity>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        backgroundColor: theme.HEADER_COLOR,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingVertical:10,
        paddingHorizontal:0,
    },
    logo: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "60%",
        height: 35,
   
    },
    logoText: {
        fontSize: 24,
        fontWeight: "800",
        alignSelf: "center",
        textTransform: "uppercase",
        color: theme.HEADER_TITLE_COLOR
    },
    button:{
        height:30,
        width:"20%",
        padding:5,
        justifyContent:"center",
   
    },
    buttonLabel:{
        width:"100%",
        textAlign:"center",
        justifyContent:"center",
        alignItems:"center",
        fontFamily:"OpenSans-Bold",
        lineHeight:19,
        fontSize:14,
        fontWeight:"800",
        color:theme.HEADER_BUTTON_LABEL_COLOR
    },
});
