import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Dimensions, TextInput, Platform, Pressable, StatusBar } from 'react-native';
import { Ionicons, Fontisto, EvilIcons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import MapView, { Marker, Callout, Overlay } from 'react-native-maps';
import { stores, getAllStores, getStoresByArea, getStoresNearby, getStoresbyFollowing } from "../services/MapService";
import { loginService, LoggedUserInfo, follow, unfollow, isFollowed } from "../services/UserService";
import Header from '../components/Header';
import { allPosts} from "../services/PostServices"
import _ from "lodash"
import StarsRating from "../components/StarsRating"
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
import * as Location from 'expo-location';
//colors
import colors from "../Colors"
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
const theme = colors.defaultTheme()

export default function MapViewer({ navigation, route }) {

    const [fontsLoaded]= useFonts(fontsList);  

    const authToken = loginService((state) => state.authToken);
    const setStore = stores((state) => state.setStore);
    const setCurrentStore = stores((state) => state.setCurrentStore);
    const setStoreList = stores((state) => state.setStoreList);
    const storesFeed = stores((state) => state.stores);
    const currentStore = stores((state) => state.currentStore);
    const storeList = stores((state) => state.storeList);
    const setOrigin = allPosts((state) => state.setOrigin);
    const mapRef = useRef(null)
    const ScrollViewRef = useRef(null);
    const loggedUsername = loginService((state) => state.username)
    const [showRedoButton, setShowRedoButton] = useState(false)
    const [showStoreList, setShowStoreList] = useState(true)
    const [mapHeight, setMapHeight] = useState(SCREEN_HEIGHT)
    const [currentMarker, setCurrentMarker] = useState([{ name: "" }])
    const [filterFavorites, setFilterFavorites] = useState(false)
    const [filterFriends, setFilterFriends] = useState(false)
    const resetCurrentStore = currentStore
    const markerIcon = require("../assets/marker.png")
    const markerSelected = require("../assets/marker-selected.png")
    const [followed, setFollowed] = useState(null)
    const setUserInfo = loginService((state) => state.setUserInfo);

    const [location, setLocation] = useState(null);
    
    useEffect(() => {
      (async () => {
        
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          alert('Permission to access location was denied');
          return;
        }
        let location = await Location.getCurrentPositionAsync({});
        setLocation(location);
      })();
    }, []);


    function followUser(username, authToken) {

        if (followed == true) {
            unfollow(username, authToken)
            setFollowed(false)
        } else {
            follow(username, authToken)
            setFollowed(true)
        }

    }

    useEffect(() => {
        async function getUserInfo() {
            const infoData = await LoggedUserInfo(loggedUsername);     
            setUserInfo(infoData)               
            
        }
        if(loggedUsername){

            getUserInfo()
        }
        
    }, [followed]);

    const getStoresList = async (longitude, latitude, range, authToken) => {
        const response = await getStoresNearby(longitude, latitude, range, authToken);
        return response
    }

    useEffect(() => {
        async function getStores() {
            const response = await getAllStores();
            if (storesFeed.length <= 0) {
                const sl = await getStoresList(response[0].longitude, response[0].latitude, 6, authToken);
                setStore(response)
                setStoreList(sl)
                setCurrentStore(response[0])
                setCurrentMarker(response[0])
            }
        }
        getStores();
        setMapHeight(SCREEN_HEIGHT - (SCREEN_HEIGHT / 2))
        //console.log(currentMarker)

    }, []);

    // useEffect(() => {
    //     console.log("favorites: ", filterFavorites)
    //     console.log("friends: ", filterFriends)
    // }, [filterFavorites, filterFriends]);

    let initialStore = storesFeed[0]
    let jumpToStore = currentStore
    let resetStore = currentStore

    if (storesFeed.length > 0) {
        initialStore = _.cloneDeep(storesFeed[0])
        initialStore["latitudeDelta"] = 0.20
        initialStore["longitudeDelta"] = 0.20
        //console.log(initialStore) 
        if (location) {
            initialStore["latitude"] = location.coords.latitude
            initialStore["longitude"] = location.coords.longitude
            //console.log(location)
        } 
    }

    const fillModalStore = (marker) => {
        async function getFollowed() {
            const flw = await isFollowed(loggedUsername, marker.username);
            setFollowed(flw)
        }
        if (loggedUsername) {
            getFollowed()
        }
        setShowRedoButton(false)
        jumpToStore = _.cloneDeep(marker)
        jumpToStore["latitudeDelta"] = 0.015
        jumpToStore["longitudeDelta"] = 0.015
        mapRef.current.animateToRegion(jumpToStore, 800)
        mapRef.current.fitToSuppliedMarkers(jumpToStore, {
            edgePadding: { top: 0, right: 100, bottom: 600, left: 100 },
            animated: true,
        });
        setCurrentMarker(marker)
        setCurrentStore(marker)
        setShowStoreList(false)
        setMapHeight(SCREEN_HEIGHT - (SCREEN_HEIGHT / 2))
        console.log("marker:",marker.username)
    }

    const searchByArea = async () => {
        setShowRedoButton(false)
        const mapBoundaries = await mapRef.current.getMapBoundaries()
        const storesByArea = await getStoresByArea(mapBoundaries, authToken)
        setStore(storesByArea)
        setStoreList(storesByArea)
        setCurrentStore(storesByArea[0])
        setCurrentMarker(storesByArea[0])
        //console.log(storesByArea)
    }

    const filterBy = async (authToken, filter) => {

        const favoritesEnabled = (filter == "favorites") ? !filterFavorites : false
        const friendsEnabled = (filter == "friends") ? !filterFriends : false
        
        setFilterFavorites(favoritesEnabled)
        setFilterFriends(friendsEnabled)
        const mapBoundaries = await mapRef.current.getMapBoundaries()
        const stores = await getStoresByArea(mapBoundaries, authToken, favoritesEnabled, friendsEnabled)
        //console.log(stores)
        setStoreList(stores)
        setStore(stores)
        scrollMarker(stores[0])

    }

    const VisitedUsers = (props) => {

        const followedUsers = props.store.following_users
        let visitedLabel = ""

        //console.log(props)

        if (followedUsers && followedUsers.length == 1) {
            return (
                <View style={{flexDirection:"row"}}>
                    <Text style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }}>Visited by</Text>
                    <TouchableOpacity style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }} onPress={() => viewProfile(followedUsers[0].username)}>
                        <Text style={{ color: theme.LINK_COLOR, fontWeight: "bold" }}> {followedUsers[0].username}</Text>
                    </TouchableOpacity>       
                </View>
            )
        } else if (followedUsers && followedUsers.length == 2) {
            return (
                <View style={{flexDirection:"row"}}>
                    <Text style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10, fontFamily:"OpenSans-Regular" }}>Visited by</Text>
                    <TouchableOpacity style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }} onPress={() => viewProfile(followedUsers[0].username)}>
                        <Text style={{ color: theme.LINK_COLOR, fontWeight: "bold", fontFamily:"OpenSans-Bold"  }}> {followedUsers[0].username}</Text>
                    </TouchableOpacity>
                    <Text style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }}> and </Text>
                    <TouchableOpacity style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }} onPress={() => viewProfile(followedUsers[1].username)}>
                        <Text style={{ color: theme.LINK_COLOR, fontWeight: "bold", fontFamily:"OpenSans-Bold"  }}> {followedUsers[1].username}</Text>
                    </TouchableOpacity>            
                </View>
            )
        } else {
            return (
                <Text style={{ color: theme.TEXT_COLOR, padding: 0, marginTop: 10 }}></Text>
            )
        }
    }

    const closeSLmodal = (resetCurrentStore) => {
        setShowRedoButton(false)
        resetStore = _.cloneDeep(resetCurrentStore)
        resetStore["latitudeDelta"] = 0.20
        resetStore["longitudeDelta"] = 0.20
        mapRef.current.animateToRegion(resetStore, 800)
        mapRef.current.fitToSuppliedMarkers(resetStore, {
            edgePadding: { top: 0, right: 100, bottom: 600, left: 100 },
            animated: true,
        });
        //console.log(resetStore)
        setCurrentStore(resetCurrentStore)
        setShowStoreList(true)
    }

    const scrollMarker = (store) => {
        if (store) {
            jumpToStore = _.cloneDeep(store)
            jumpToStore["latitudeDelta"] = 0.20
            jumpToStore["longitudeDelta"] = 0.20
            //mapRef.current.animateToRegion(jumpToStore, 800)
            // mapRef.current.fitToSuppliedMarkers(jumpToStore, {
            //     edgePadding: { top: 0, right: 100, bottom: 600, left: 100 },
            //     animated: false,
            // });
            setCurrentMarker(store)
            setShowStoreList(true)
        }
    }

    function viewProfile(username) {
        setOrigin("Map")
        navigation.push('ProfileViewer', {username:username})
    }

    return (

        <SafeAreaView style={{ height: "100%", backgroundColor: theme.BACKGROUND_ONE }}>
        <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />    

            <View style={styles.container}>
                <MapView draggable showsCompass ref={mapRef} style={[styles.map, { height: mapHeight }]} initialRegion={initialStore} compassOffset={{ x: -10, y: 60 }} onPanDrag={() => setShowRedoButton(true)}>
                    {storesFeed.map((marker, index) => (
                        <Marker 
                            key={index} 
                            coordinate={marker} 
                            title={marker.title} 
                            description={marker.description} 
                            onPress={() => fillModalStore(marker)} >
                                <Image source={currentMarker.name == marker.name ? markerSelected : markerIcon} style={{width: 40, height:40}}/>
                        </Marker>
                    ))}
                </MapView>
                <Pressable onPress={() => navigation.navigate({name: "StorePickerModal", params: { result_screen_name: "ProfileViewer"}, merge: true})} style={styles.searchContainer}>
                    <Ionicons name="ios-search" size={24} color={theme.ICON_ACTIVE_COLOR} />
                    <TextInput onPressIn={() => navigation.navigate({name: "StorePickerModal", params: { result_screen_name: "ProfileViewer"}, merge: true})} editable={false} style={styles.textInput} placeholder="Search a place" placeholderTextColor={theme.PLACEHOLDER_COLOR} onChangeText={(newText) => void (0)} />
                </Pressable>
                {showRedoButton &&
                    <TouchableOpacity style={[styles.searchButton,{alignSelf:"center"}]} onPress={() => searchByArea()}>
                       <Text style={styles.text}>Redo search in this area</Text>
                    </TouchableOpacity>
                }
                {currentStore.name &&
                    <View style={styles.modalCS}>
                        <View style={styles.SLSingleContainer}>
                            <View style={styles.SLDetailsContainer}>
                                <Text style={styles.storeListTitle}>{currentStore.name}</Text>
                                <View style={styles.stars}>
                                    <View>
                                        <Text style={styles.modalText}>{currentStore.address_street} - {currentStore.address_city}, {currentStore.address_state}</Text>
                                        <Text style={styles.modalText}>{currentStore.phone}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.buttonContainer}>
                                {authToken != "" &&
                                    <TouchableOpacity style={styles.buttonActive} onPress={() => followUser(currentStore.username, authToken)}>
                                        <Text style={styles.buttonTextActive}>{followed == true ? "Unfollow" : "Follow"}</Text>
                                    </TouchableOpacity>
                                }
                                <TouchableOpacity style={styles.buttonActive} onPress={() => viewProfile(currentStore.username)}>
                                    <Text style={styles.buttonTextActive}>VIEW PROFILE</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <TouchableOpacity style={styles.closeButton} onPress={() => { closeSLmodal(resetCurrentStore) }}>
                            <Ionicons name="ios-close-circle-sharp" size={24} color={theme.ICON_COLOR} />
                        </TouchableOpacity>
                    </View>
                }
                {showStoreList &&
                    <View style={styles.modalSL}>
                        <ScrollView
                            ref={ScrollViewRef}
                            style={{ marginTop: 50, marginHorizontal: 10 }}
                            onScroll={({ nativeEvent }) => {
                                const position = Math.floor(nativeEvent.contentOffset.y / (SCREEN_HEIGHT / 7.5))
                                if (position >= 0) {
                                    scrollMarker(storeList[position])
                                }
                            }}
                            scrollEventThrottle={400}
                        >
                            {storeList.map((store, index) => (
                                <TouchableOpacity style={currentMarker.name == store.name ? styles.SLSingleContainerSelected : styles.SLSingleContainer} key={"Store" + index} onPress={() => fillModalStore(store)}>
                                    <View style={styles.SLDetailsContainer}>
                                        <Text style={styles.storeListTitle}>{store.name}</Text>
                                        <View style={styles.stars}>
                                            <Text style={[styles.modalText, { width: 260 }]}>{store.address_street} - {store.address_city}, {store.address_state}</Text>
                                        </View>

                                        {authToken != "" &&
                                            <View style={styles.stars}>
                                                <VisitedUsers store={store} />
                                            </View>
                                        }
                                    </View>
                                    <View style={styles.storeIcon}>
                                        <MaterialCommunityIcons name="store-marker" size={36} color={currentMarker.name == store.name ? theme.STORE_ICON_ACTIVE_COLOR : theme.STORE_ICON_COLOR} />
                                    </View>
                                </TouchableOpacity>
                            ))}
                        </ScrollView>
                        <TouchableOpacity style={styles.closeButton} onPress={() => {
                            setShowStoreList(false)
                            setCurrentStore({})
                            setMapHeight(SCREEN_HEIGHT)
                        }}>
                            <Ionicons name="ios-close-circle-sharp" size={24} color={theme.ICON_COLOR} />
                        </TouchableOpacity>
                        <View style={styles.arrowRight}>
                            <Fontisto name="map-marker-alt" size={15} color={theme.ICON_COLOR} />
                        </View>
                        {authToken != "" &&
                            <View style={styles.filtersContainer}>
                                <Text style={styles.filtersTitle}>Filter by</Text>
                                <TouchableOpacity style={[styles.filterButton, { borderColor: filterFavorites == true ? theme.TEXT_COLOR : theme.LINE_GRAY }]} onPress={() => filterBy(authToken, "favorites")}>
                                    <Text style={[styles.FBText, { color: filterFavorites == true ? theme.TEXT_COLOR : theme.LINE_GRAY }]}>Favorites</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={[styles.filterButton, { borderColor: filterFriends == true ? theme.TEXT_COLOR : theme.LINE_GRAY }]} onPress={() => filterBy(authToken, "friends")}>
                                    <Text style={[styles.FBText, { color: filterFriends == true ? theme.TEXT_COLOR : theme.LINE_GRAY }]}>Friends</Text>
                                </TouchableOpacity>
                            </View>
                        }
                    </View>
                }
            </View>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        width:"100%"
   
    },
    text: {
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular" 
    },
    description: {
        color: theme.TEXT_COLOR,
        alignSelf: "flex-start",
        paddingLeft: 10,
        paddingBottom: 10,
        lineHeight: 24
    },
    profileContainer: {
        flexDirection: "row",
        width: "90%",
        paddingTop: 20,

    },
    bioContainer: {
        flex: 1,
        width: "90%",
        paddingVertical: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 20
    },
    avatar: {
        width: 64,
        height: 64,
        borderRadius: 0,
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    textContainer: {
        paddingHorizontal: 15,
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 10,
        color: theme.TITLE_COLOR
    },
    profileItem: {
        marginTop: 5,
        marginRight: 25
    },
    profileNumbers: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    item: {
        width: "33%",
        height: 120,
        marginRight: 2,
        marginBottom: 2
    },
    photo: {
        width: "100%",
        height: 120
    },
    button: {
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        width: "90%",
        alignSelf: "center",
        alignItems: "center",
        paddingVertical: 10,
        backgroundColor: theme.EDIT_PROFILE_BUTTON,
    },
    buttonText: {
        color: theme.TEXT_COLOR,
        textTransform: "uppercase"
    },
    fullName: {
        color: theme.TEXT_COLOR,
        paddingLeft: 10,
        paddingTop: 15,
        paddingBottom: 5,
        fontWeight: "bold",
        fontSize: 16
    },
    map: {
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT,
        marginTop: 10
    },
    modalCS: {
        position: "absolute",
        paddingHorizontal: 10,
        bottom: 0,
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT / 3.2,
        backgroundColor: theme.CURRENT_STORE_SELECTED,
    },
    searchContainer: {
        position: "absolute",
        top: 15,
        height: 50,
        backgroundColor: theme.BACKGROUND_ONE,
        width: SCREEN_WIDTH - 10,
        alignSelf:"center",
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
        flexDirection: "row",
        alignItems: "flex-start",
    },
    textInput: {
        height: 28,
        width: "90%",
        paddingLeft: 10,
        color: theme.TEXT_COLOR
    },
    searchButton: {
        position: "absolute",
        top: 80,
        backgroundColor: theme.BACKGROUND_ONE,
        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
        flexDirection: "row",
        alignItems: "center"
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,

        borderRadius: 10,
        justifyContent: "center",
        padding: 10,
        flexDirection: "row",
        alignItems: "flex-start"
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: "bold",
        marginTop: 10,
        color: theme.TITLE_COLOR
    },
    modalText: {
        fontSize: 14,
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular"
    },
    modalSL: {
        position: "absolute",
        paddingHorizontal: 10,
        bottom: 0,
        width: SCREEN_WIDTH,
        height: SCREEN_HEIGHT / 2.8,
        backgroundColor: theme.BACKGROUND_ONE,

    },
    storeListTitle: {
        fontSize: 16,
        fontWeight: "bold",
        marginTop: 0,
        color: theme.TITLE_COLOR,
        marginLeft: 15,
        width: 320,
        fontFamily:"OpenSans-Bold"
    },
    SLSingleContainer: {
        borderTopWidth: 1,
        borderColor: theme.LINE_GRAY,
        paddingVertical: 20,
        height: 130
    },
    SLSingleContainerSelected: {
        flexDirection: "row",
        borderTopWidth: 1,
        borderColor: theme.LINE_GRAY,
        paddingVertical: 20,
        height: 130,
        backgroundColor: theme.CURRENT_STORE_SELECTED
    },
    SLDetailsContainer: {
        alignItems: "flex-start"
    },
    stars: {
        marginLeft: 15,
        marginTop: 5,
        flexDirection: "row",
    },
    filtersContainer: {
        position: "absolute",
        top: 5,
        left: 20,
        height: 35,
        width: SCREEN_WIDTH - 60,
        justifyContent: "flex-start",
        padding: 5,
        flexDirection: "row",
        alignItems: "center"
    },
    filtersTitle: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TITLE_COLOR,
    },
    filterButton: {

        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        paddingHorizontal: 10,
        paddingVertical: 0,
        height: 30,
        marginLeft: 10,
        borderRadius: 10,
        color: theme.TEXT_COLOR,
        justifyContent: "center",
        alignItems: "center",
    },
    FBText: {
        fontSize: 14,
        color: theme.LINE_GRAY,
        fontWeight: "bold",
        fontFamily:"OpenSans-Bold"

    },
    arrowRight: {
        position: "absolute",
        top: 90,
        left: 3,
        backgroundColor: theme.BACKGROUND_ONE,
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "flex-start",
        opacity: 0.5
    },
    storeIcon: {
        position: "absolute",
        bottom: 20,
        right: 20,
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    buttonContainer: {
        marginTop: 20,
        marginLeft: 15,
        width: SCREEN_WIDTH,

    },
    buttonActive: {

        backgroundColor: theme.SL_BUTTON_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "90%",
        borderColor: theme.LINE_GRAY,
        borderRadius: 10,
        marginTop:10

    },

    buttonTextActive: {
        color: theme.SL_BUTTON_TEXT_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold",
        fontFamily:"OpenSans-Bold"
    },
});
