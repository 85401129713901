import {create} from "zustand";
import axios from "axios";
import Configuration from "../config/Configuration";
const SERVER = Configuration.BASE_SERVER_URL;
// const SERVER = "http://localhost:8080";
import mime from 'mime';

const stores = create((set, get) => ({
    stores: [],
    currentStore: {location:{coordinates:["1","1"]}},
    storeList:[],
    setStore: (storeObj) => {
        set((state) => {
            return {
                stores: storeObj,
            };
        });
    },
    setCurrentStore: (storeObj) => {
        set((state) => {
            return {
                currentStore: storeObj,
            };
        });
    },
    setStoreList: (storeObj) => {
        set((state) => {
            return {
                storeList: storeObj,
            };
        });
    },

}));

const getAllStores = async () => {
    try {
        let stores = await axios.get(SERVER + "/api/stores/all");

        //console.log(stores.data)

        return stores.data.stores

    } catch (e) {
        console.log(e);
    }
}

const getStoresByArea = async (mapBoundaries, authToken, favorites, friends) => {

    const boundaries = {

        ne_latitude: mapBoundaries.northEast.latitude,
        ne_longitude: mapBoundaries.northEast.longitude,
        sw_latitude: mapBoundaries.southWest.latitude,
        sw_longitude: mapBoundaries.southWest.longitude,

    }
    //console.log(boundaries)

    let url = SERVER + `/api/stores/inBoxArea?ne_latitude=${boundaries.ne_latitude}&ne_longitude=${boundaries.ne_longitude}&sw_latitude=${boundaries.sw_latitude}&sw_longitude=${boundaries.sw_longitude}&onlyFollowedStores=${favorites}&onlyFollowedUserStores=${friends}`
    let headers = {}

    if(authToken && authToken.length > 0){

        headers = {headers: {Authorization: `Bearer ${authToken}`}}
    }

    let stores = await axios.get(url,headers)

    //console.log(stores.data)
    return stores.data.stores

}

const getStoresNearby = async (longitude, latitude, range, authToken) => {  

    let url = SERVER + `/api/stores/nearby?longitude=${longitude}&latitude=${latitude}&range=${range}`

    let headers = {}

    if(authToken && authToken.length > 0){

        headers = {headers: {Authorization: `Bearer ${authToken}`}}
    }

    let stores = await axios.get(url,headers)

    //console.log(stores.data.stores)
    return stores.data.stores

}

export { getAllStores, getStoresByArea, getStoresNearby, stores };
