import React from 'react';
import { View, StyleSheet } from 'react-native';
import {Slider} from '@miblanchard/react-native-slider';

//colors
import colors from "../Colors"

const theme = colors.defaultTheme() 

export default function SliderRating({ rating, onStarRatingPress, name, disabled, size }){
    return (
            <View style={styles.container}>
              <Slider
                    maximumValue={10}
                    step={0.1}
                    minimumTrackTintColor={theme.SLIDER_TRACKING_COLOR}
                    thumbTintColor={theme.SLIDER_BULLET}
                    onValueChange={(rat) => onStarRatingPress(rat[0], name)}
                    value={rating}
                />          
            </View>

    )

}

const styles = StyleSheet.create({
    container: {
    },
});