import React, { useState, useEffect, useCallback, useRef } from 'react';
import { SafeAreaView, StyleSheet, Text, View, TouchableOpacity, Pressable, Button, Image, FlatList, ImageBackground, Dimensions, Appearance, Platform } from 'react-native';
import { Fontisto, Feather, Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import { NavigationContainer,DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { CardStyleInterpolators, createStackNavigator, HeaderStyleInterpolators } from '@react-navigation/stack';

//pages
import Profile from "./pages/Profile"
import ProfileViewer from "./pages/ProfileViewer"
import AddPost from "./pages/AddPost"
import MediaForm from "./pages/MediaForm"
import ViewPost from "./pages/ViewPost"
import Login from "./pages/Login"
import SignUp from "./pages/SignUp"
import Settings from "./pages/Settings"
import MapViewer from "./pages/MapViewer"
import Terms from './pages/Terms';
import StorePickerModal from './pages/StoreFinderModal';
import UserFinderModal from './pages/UserFinderModal';
import PostFinderModal from './pages/PostFinderModal';
import FollowersInfo from './pages/FollowersInfo';
import FollowingInfo from './pages/FollowingInfo';
import DiscoverScreen from './pages/Discover';
import HomeScreen from './pages/Home';
import PostActionModal from './components/PostActionModal';
import EditPost from './pages/EditPost';
import ViewComments from './pages/ViewComments'
//services
import { loginService } from "./services/UserService";
//assets
import colors from "./Colors"
const theme = colors.defaultTheme()
const scheme = Appearance.getColorScheme();
// react-query
import { QueryClient, QueryClientProvider } from "react-query";
import PostsFinder from './components/PostsFinder';
const queryClient = new QueryClient({});
import fontsList from "./FontsList";
import { useFonts } from 'expo-font';

function LoginScreen({ navigation }) {

	return (

		<Login navigation={navigation} />
	)
}

function SignUpScreen({ navigation }) {

	return (
		<SignUp navigation={navigation} />
	)
}
function TermsScreen({ navigation }) {

	return (
		<Terms navigation={navigation} />
	)
}

const stackOptions = { headerShown: false, }

const mapPickerOptions = {
	headerTitle: () => <Text></Text>,
	headerShown: true,
	headerBackTitle: "Cancel",
	headerBackTitleVisible: true,
	headerTruncatedBackTitle: "Cancel",
	headerTintColor: theme.HEADER_TITLE_COLOR,
	orientation: 'all',
	headerTitleAlign: "center",
	headerMode: 'screen',
	headerStyle: {
		backgroundColor: theme.HEADER_COLOR,
	},
	headerBackImage: () => (
		<View>
			<View style={{ padding: 8 }} />
		</View>
	)
}

const mapPickerModalOptions = { headerShown: false }

const forFade = ({ current }) => ({
	cardStyle: {
		opacity: current.progress,
	},
});

const tabOptions = {
	headerShown: false,
}
const tabBarOptions = {
	tabBarLabelStyle: {
		fontSize: 14,
		color: theme.TEXT_COLOR
	},
	tabBarItemStyle: {

	},
	tabBarStyle: {
		backgroundColor: theme.NAVTAB_BG,
	},
	tabBarIndicatorStyle: {
		height: 2,
		backgroundColor: theme.NAVTAB_INDICATOR
	},
	tabBarActiveTintColor: theme.NAVTAB_INDICATOR
}

const FollowerInfoTab = createMaterialTopTabNavigator();

function checkLogin(component, stack = HomeStack, component2 = null) {
	const loggedIn = loginService((state) => state.isLoggedIn)
	if (!loggedIn) {
		if (component2) {
			return component2
		}
		return (
		<stack.Navigator >
			<ProfileStack.Screen name="Login" component={LoginScreen} options={() => (tabOptions)} />
			<ProfileStack.Screen name="SignUp" component={SignUpScreen} options={() => (stackOptions)} />
			<ProfileStack.Screen name="TermsPage" component={TermsScreen} options={() => (stackOptions)} />
		</stack.Navigator>
		)
	}
	return component
}

function FollowerInfoTabsComponent({ route }) {
	let intialRouteName = route.params.initial_route ? route.params.initial_route : "FollowersInfo";
	const loggedIn = loginService((state) => state.isLoggedIn)
	return (
		<FollowerInfoTab.Navigator initialRouteName={intialRouteName} screenOptions={tabBarOptions}>
			<FollowerInfoTab.Screen initialParams={{ username: route.params.username, loggedIn: loggedIn }} name="FollowersInfoScreen" options={{ tabBarLabel: 'Followers' }} component={FollowersInfo} />
			<FollowerInfoTab.Screen initialParams={{ username: route.params.username, loggedIn: loggedIn }} name="FollowingInfoScreen" options={{ tabBarLabel: 'Following' }} component={FollowingInfo} />
		</FollowerInfoTab.Navigator>
	);
}

const DiscoverStack = createStackNavigator();

function DiscoverStackScreen() {
	return checkLogin(
		<DiscoverStack.Navigator>
			<DiscoverStack.Screen name="DiscoverHome" component={DiscoverScreen} options={() => (mapPickerModalOptions)} />
			<DiscoverStack.Screen name="UserFinderModal" component={UserFinderModal} options={({ route, navigation }) => ({
				...mapPickerOptions,
				headerStyleInterpolator: HeaderStyleInterpolators.forFade
				, cardStyleInterpolator: forFade
			})}
			/>
			<DiscoverStack.Screen name="PostsFinderModal" component={PostsFinder} options={({ route, navigation }) => ({
				...mapPickerOptions,
				headerStyleInterpolator: HeaderStyleInterpolators.forFade
				, cardStyleInterpolator: forFade
			})}
			/>
			<DiscoverStack.Screen name="PostComments" component={ViewComments} options={() => (stackOptions)} />	
			<DiscoverStack.Screen name="ProfileViewer" component={ProfileViewer} options={() => (stackOptions)} />
			<DiscoverStack.Screen name="SinglePost" component={ViewPost} options={() => (stackOptions)} />
			<DiscoverStack.Screen name="PostAction" component={PostActionModal} options={mapPickerOptions}/>
		</DiscoverStack.Navigator>
	, DiscoverStack)

}

const HomeStack = createNativeStackNavigator();
function HomeStackScreen() {
	return checkLogin(
		<HomeStack.Navigator>	
			<HomeStack.Screen name="Feed" component={HomeScreen} options={() => (stackOptions)} />
			<HomeStack.Screen name="ProfileViewer" component={ProfileViewer} options={() => (stackOptions)} />
			<HomeStack.Screen name="SinglePost" component={ViewPost} options={() => (stackOptions)} />
			<HomeStack.Screen name="Login" component={LoginScreen} options={() => (stackOptions)} />
			<HomeStack.Screen name="SignUp" component={SignUpScreen} options={() => (stackOptions)} />
			<HomeStack.Screen name="FollowersInfo" component={FollowerInfoTabsComponent} options={() => (stackOptions)} />
			<HomeStack.Screen name="PostComments" component={ViewComments} options={() => (stackOptions)} />	
		</HomeStack.Navigator>
		,HomeStack,
		<HomeStack.Navigator>
			<HomeStack.Screen name="Feed" component={HomeScreen} options={() => (stackOptions)} />
			<HomeStack.Screen name="Login" component={LoginScreen} options={() => (stackOptions)} />
			<HomeStack.Screen name="SignUp" component={SignUpScreen} options={() => (stackOptions)} />
		</HomeStack.Navigator>
	);
}



const MapStack = createStackNavigator();

function MapStackScreen() {
	return checkLogin(
		<MapStack.Navigator>
			<MapStack.Screen name="MapViewer" component={MapViewer} options={() => (mapPickerModalOptions)} />
			<MapStack.Screen name="ProfileViewer" component={ProfileViewer} options={() => (stackOptions)} />
			<MapStack.Screen name="StorePickerModal" component={StorePickerModal} options={({ route, navigation }) => ({
				...mapPickerOptions,
				headerStyleInterpolator: HeaderStyleInterpolators.forFade
				, cardStyleInterpolator: forFade
			})}
			/>
		</MapStack.Navigator>
	, MapStack)
		
}

const ProfileStack = createNativeStackNavigator();

function ProfileStackScreen() {

	return checkLogin(
		<ProfileStack.Navigator>
			<ProfileStack.Screen name="Profile" component={Profile} options={() => (stackOptions)} />
			<ProfileStack.Screen name="SinglePost" component={ViewPost} options={() => (stackOptions)} />
			<ProfileStack.Screen name="Settings" component={Settings} options={() => (stackOptions)} />
			<ProfileStack.Screen name="FollowersInfo" component={FollowerInfoTabsComponent} options={() => (mapPickerOptions)} />
			<ProfileStack.Screen name="ProfileViewer" component={ProfileViewer} options={() => (stackOptions)} />
			<ProfileStack.Screen name="PostAction" component={PostActionModal} options={mapPickerOptions}/>
			<ProfileStack.Screen name="EditPost" component={EditPost} options={() => (stackOptions)} />
			<ProfileStack.Screen name="PostComments" component={ViewComments} options={() => (stackOptions)} />	
			<ProfileStack.Group screenOptions={{ presentation: 'modal' }} >
				<ProfileStack.Screen name="StorePickerModal" component={StorePickerModal} options={({ route, navigation }) => ({
					...mapPickerOptions,
					headerStyleInterpolator: HeaderStyleInterpolators.forFade
					, cardStyleInterpolator: forFade
				})} />
			</ProfileStack.Group>
		</ProfileStack.Navigator>
	, ProfileStack)
}

const AddPostStack = createNativeStackNavigator();

function AddPostStackScreen() {
return checkLogin(
		<AddPostStack.Navigator>
			<AddPostStack.Screen name="Select media" component={AddPost} options={() => (stackOptions)} />
			<AddPostStack.Screen name="Profile" component={Profile} options={() => (stackOptions)} />
			<AddPostStack.Screen name="Add post form" component={MediaForm} options={() => (stackOptions)} />
			<AddPostStack.Screen name="ViewPost" component={ViewPost} options={() => (stackOptions)} />
			<AddPostStack.Group screenOptions={{ presentation: 'modal' }} >
				<AddPostStack.Screen name="StorePickerModal" component={StorePickerModal} options={({ route, navigation }) => ({
					...mapPickerOptions,
					headerStyleInterpolator: HeaderStyleInterpolators.forFade
					, cardStyleInterpolator: forFade
				})} />
			</AddPostStack.Group>
		</AddPostStack.Navigator>
	, AddPostStack)
		
}

const Tab = createBottomTabNavigator();

const config = {
	screens: {
	  Home: {
		screens: {
			Feed: '/home',
			SinglePost: '/home/post/:post_id',
			Settings: '/home/settings',
			ProfileViewer: '/home/profile/:username'
		}
	  },
	  Add: {
		screens: {
			"Select media": '/add_content',
		}
	  },
	  Me:{
		screens: {
			Profile: '/user/myprofile',
			SinglePost: '/user/post/:post_id',
			Settings: '/user/settings',
			Login: '/user/login',
			SignUp: '/user/signup',
			TermsPage: '/user/terms_of_service',
			ProfileViewer: '/user/profile/:username'
		}
	  },
	  Maps: 'maps',
	  Discover: {
		screens: {
			DiscoverHome: '/discover',
			SinglePost: '/discover/post/:post_id',
			ProfileViewer: '/discover/profile/:username'
		}
	  },
	},
  };

const linking = {
	prefixes: ['http://localhost:19006',],
	config,
  };

export default function App() {
	const [fontsLoaded]= useFonts(fontsList);  

	const onLayoutRootView = useCallback(async () => {
		if (fontsLoaded) {
		//   await SplashScreen.hideAsync();
		}
	  }, [fontsLoaded]);
	
	  if (!fontsLoaded) {
		return (
			<View style={{backgroundColor: theme.BACKGROUND_ONE, flex: 1}}/>
		)
	  }

	return (
		<QueryClientProvider client={queryClient}>
			<NavigationContainer linking={linking} theme={ scheme === 'dark' ? DarkTheme : DefaultTheme }>
				<Tab.Navigator sceneContainerStyle={{ overflow: "hidden" }} screenOptions={({ route }) => ({
					tabBarIcon: ({ focused, color, size }) => {
						let iconName;
						size = 28

						if (route.name === 'Home') {
							iconName = focused ? 'ios-home' : 'ios-home-outline';
						} else if (route.name === 'Discover') {
							iconName = focused ? 'ios-search' : 'ios-search-outline';
						} else if (route.name === 'Add') {
							iconName = focused ? 'ios-add-circle-sharp' : 'ios-add-circle-outline';
						} else if (route.name === 'Me') {
							iconName = focused ? 'ios-person-circle' : 'ios-person-circle-outline';
						} else if (route.name === 'Maps') {
							iconName = focused ? 'ios-map' : 'ios-map-outline';
						}

						return <Ionicons name={iconName} size={size} color={color} />;
					},
					tabBarHideOnKeyboard: true,
					tabBarActiveTintColor: theme.ICON_ACTIVE_COLOR,
					tabBarInactiveTintColor: theme.ICON_COLOR,
					tabBarShowLabel: true,
					tabBarLabelStyle: {
						paddingBottom: 0,
						fontSize: 12,

					
					},
					tabBarStyle: {
						backgroundColor: theme.TAB_BG,
						borderTopWidth: 0,
						borderTopColor: theme.LINE_GRAY,
						paddingVertical: 1,
						position: Platform.OS == "web" ? "fixed" : "relative"					
					}
				})}>
					<Tab.Screen name="Home" component={HomeStackScreen} options={() => (tabOptions)} />
					<Tab.Screen name="Discover" component={DiscoverStackScreen} options={() => (tabOptions)} />
					{ Platform.OS != 'web' && (
					<>
						<Tab.Screen name="Add" component={AddPostStackScreen} options={() => (tabOptions)} />
						<Tab.Screen name="Maps" component={MapStackScreen} options={() => (tabOptions)} />
					</>
					)}
					<Tab.Screen name="Me" component={ProfileStackScreen} options={() => (tabOptions)} />
				</Tab.Navigator>
			</NavigationContainer>
		</QueryClientProvider>

	);
}

