import {create} from "zustand";
import axios from "axios";
import Configuration from "../config/Configuration";
const SERVER = Configuration.BASE_SERVER_URL;
import mime from 'mime';

const allPosts = create((set, get) => ({
  posts: [],
  origin: "",
  setPost: (obj) => {
    
    set((state) => {
      let fillFeed = [...state.posts, obj];

      fillFeed.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });

      return {
        posts: fillFeed,
      };
    });
  },

  setOrigin: (item) => {
    set((state) => {
      return {
        origin: item,
      };
    });
  },

  clearFeed: () => {
    console.log("POSTS CLEARED");

    set((state) => ({
      posts: [],
    }));
  },
}));

const getAllPosts = async () => {
  try {
    let posts = await axios.get(SERVER + "/api/profile/allposts");

    let filterPosts = posts.data.posts    

    filterPosts.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });

    //console.log(filterPosts);

    return filterPosts
    
  } catch (e) {
    console.log(e);
  }
};

const getMyPosts = async (username) => {
  try {
    let posts = await axios.get(SERVER + "/api/profile/" + username + "/posts");
    //console.log("THE USERS Posts", posts.data.posts.length);

    let filterPosts = posts.data.posts
    

    filterPosts.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });

    return filterPosts
    
  } catch (e) {
    console.log(e);
  }
};

const getUserFeed = async (token) => {
  try {
    let posts = await axios.get(SERVER + "/api/home/feed", {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    console.log("THE USERS Feed has Posts", posts.data.posts.length);
    let filterPosts = posts.data.posts
    
    filterPosts.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });

    return filterPosts
    
  } catch (e) {
    console.log(e);
  }
};

const createMedia = async (localUri, filename, authToken ) => {
  let formData = new FormData();
  // Assume "photo" is the name of the form field the server expects
  formData.append("photo", {
    uri: localUri,
    name: filename,
    type: mime.getType(filename),
  });

  //console.log(formData);

  try {
    let newMedia = await axios.post(SERVER + "/api/media/upload", formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + authToken,
      },
    });
    return newMedia;
  } catch (e) {
    return null;
  }
};

const createPost = async (post, authToken) => {
  //console.log("CREATING POST", post);
  try {
    let newPostResponse = await axios.post(
      SERVER + "/api/posts/new",
      JSON.stringify(post),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      }
    );
    if (newPostResponse && newPostResponse.data && newPostResponse.data.post) {
      return newPostResponse.data.post;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}


const deletePost = async (postId, authToken ) => {

    const post = await axios.delete(SERVER + "/api/posts/"+postId, {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    //console.log(post.data)

};

const editPost = async (post,authToken ) => {

  const res = await axios.put(SERVER + "/api/posts/"+ post.post_id, post ,{
    headers: {
      Authorization: "Bearer " + authToken,
    },
  });
  
  return res.data.post

};



export { allPosts, getMyPosts, createMedia, createPost, getAllPosts, getUserFeed, deletePost, editPost };
