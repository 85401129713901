import React, { useState } from "react";
import {
    View,
    Text,
    Image,
    ImageBackground,
    StyleSheet,
    TextInput,
    FlatList,
    Pressable,
    Dimensions
} from "react-native";
import { MaterialIcons } from '@expo/vector-icons';
import { useQuery } from "react-query";
import { Ionicons } from "@expo/vector-icons";
import useDebounce from "../hooks/useDebounce";
import colors from "../Colors"
import { TouchableOpacity } from "react-native-gesture-handler";
const theme = colors.defaultTheme()
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');


export default function SearchUserTab({navigation}) {

    const avatarImage = { uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612" };

    function goToProfile(user) {  
          navigation.push("ProfileViewer", {username:user});
      }

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => goToProfile("pogo")} style={styles.itemContainer}>
                <View>
                    <Image source={avatarImage} style={styles.avatar} />
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.profileTitle}>pogo <MaterialIcons name="verified" size={18} color="rgb(29, 155, 240)" /></Text>
                    <Text style={styles.fullName}>Pogo Sanchez</Text>
                    <Text style={styles.followersTitle}>Followers <Text style={styles.fullName}>200</Text></Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => goToProfile("greenairro3")} style={styles.itemContainer}>
                <View>
                    <Image source={avatarImage} style={styles.avatar} />
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.profileTitle}>greenairro3</Text>
                    <Text style={styles.fullName}>Green Airro Three</Text>
                    <Text style={styles.followersTitle}>Followers <Text style={styles.fullName}>80</Text></Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => goToProfile("greenairro4")} style={styles.itemContainer}>
                <View>
                    <Image source={avatarImage} style={styles.avatar} />
                </View>
                <View style={styles.textContainer}>
                    <Text style={styles.profileTitle}>greenairro4</Text>
                    <Text style={styles.fullName}>Green Airro Four</Text>
                    <Text style={styles.followersTitle}>Followers <Text style={styles.fullName}>40</Text></Text>
                </View>
            </TouchableOpacity>
        </View>


    )
}

const styles = StyleSheet.create({
    container: {
        flex: 3,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    text: {
        color: theme.TEXT_COLOR,
    },
    description: {
        color: theme.TEXT_COLOR,
        alignSelf: "flex-start",
        paddingLeft: 10,
        paddingBottom: 10,
        lineHeight: 24

    },
    itemContainer: {
        flexDirection: "row",
        width: SCREEN_WIDTH,
        paddingVertical: 20,
        paddingHorizontal: 10,
        borderBottomWidth: 1,
        borderBottomColor: theme.LINE_GRAY,
        maxWidth: 780

    },
    bioContainer: {
        flex: 1,
        width: "90%",
        paddingVertical: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 20
    },
    avatar: {
        width: 48,
        height: 48,
        borderRadius: 500,
        borderWidth: 2,
        borderColor: theme.LINE_GRAY
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    textContainer: {
        paddingHorizontal: 15,
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        color: theme.TITLE_COLOR
    },
    followersTitle: {
        fontSize: 12,
        fontWeight: "bold",
        color: theme.TITLE_COLOR
    },
    profileItem: {
        marginTop: 5,
        marginRight: 25
    },
    profileNumbers: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    item: {
        width: "33%",
        height: 120,
        marginRight: 2,
        marginBottom: 2
    },
    photo: {
        width: "100%",
        height: 120
    },
    button: {
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        width: "90%",
        alignSelf: "center",
        alignItems: "center",
        paddingVertical: 10,
        backgroundColor: theme.EDIT_PROFILE_BUTTON,


    },
    buttonText: {
        color: theme.TEXT_COLOR,
        textTransform: "uppercase"
    },
    fullName: {
        color: theme.TEXT_COLOR,
        paddingLeft: 0,
        paddingTop: 3,
        paddingBottom: 5,
        fontWeight: "300",
        fontSize: 16
    },

});

