import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Pressable, StatusBar, useWindowDimensions, Platform } from 'react-native';
import { Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import Header from '../components/Header';
import { loginService, LoggedUserInfo } from "../services/UserService";
import { getMyPosts, allPosts } from "../services/PostServices";
import useUserPosts from '../hooks/useUserPosts';
import LoadingView from '../components/Loading';
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

//colors
import colors from "../Colors"
import axios from 'axios';

//photos for the feed
import photos from "../mock/Photos"
const theme = colors.defaultTheme()

export default function Profile({ navigation, route }) {

    const [fontsLoaded]= useFonts(fontsList);  
    // const [posts, setPosts] = useState([])
    const username = loginService((state) => state.username);
    const setOrigin = allPosts((state) => state.setOrigin);
    const setUserInfo = loginService((state) => state.setUserInfo);
    const loggedUserInfo = loginService((state) => state.loggedUserInfo);
    const { isLoading, data, hasNextPage, fetchNextPage, refetch } = useUserPosts({limit: 16, username: username });
    const [refreshing, setRefreshing] = useState(false);
    const {height, width} = useWindowDimensions();

    let forceRefreshFeed = route.params ? route.params.refreshFeed : false   

    const [checkCounts, setCheckCounts ] = useState(forceRefreshFeed)

    if(forceRefreshFeed){
      refreshFeed()
      forceRefreshFeed = false
      route.params.refreshFeed = false
    }

    const avatarImage = { uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612" };

    let posts = [];

    async function getUserInfo() {      

        const infoData = await LoggedUserInfo(username);     
        setUserInfo(infoData)         
        console.log(infoData)
    
    }

    const loadMore = () => {
        if (hasNextPage) {
          fetchNextPage();
        } 
      };

    async function refreshFeed() {
        setRefreshing(true)
        getUserInfo()  
        await refetch()
        setRefreshing(false)
        
    }      
    useEffect( () => {

        async function getUserInfo() {    

            const infoData = await LoggedUserInfo(username);     
            //setLoggedUserData(infoData)
            setUserInfo(infoData)           
        
        }

        getUserInfo()      
       
    }, []);
    

    function singleView(item) {

		setOrigin("Profile")
		navigation.push('SinglePost', item)

	}
    

    if (isLoading) {
        return (
        <LoadingView></LoadingView>
        )
    }

    if (!isLoading) {
        posts = data.pages.map(page => page.posts).flat();
    }


    const Item = ({ item, source }) => {
        // const {height, width} = useWindowDimensions();
        return (
            <TouchableOpacity style={styles.item} onPress={() => singleView(item)}>
             <ImageBackground source={{ uri: source }} style={styles.photo} resizeMode="cover" />
            </TouchableOpacity>
        )
    }

    const ProfileHeader = () => {
        // const {height, width} = useWindowDimensions();
        return (

                <View style={styles.headerContainer}>
                    <View style={styles.profileContainer}>       
                    <TouchableOpacity style={styles.gear} onPress={() => navigation.push('Settings', loggedUserInfo)}>
                        <FontAwesome name="gear" size={24} color={theme.TEXT_COLOR} />
                    </TouchableOpacity>  
                    <View>
                        <Image source={loggedUserInfo.avatar_url ? loggedUserInfo.avatar_url : avatarImage} style={styles.avatar} />
                    </View>      
                    <View style={styles.textContainer}>
                        <View>
                            <Text style={styles.profileTitle}>{loggedUserInfo.username}</Text>
                        </View>
                        <View style={styles.horizonltalAlign}>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileNumbers}>{loggedUserInfo.posts_count}</Text>
                                <Text style={styles.profileText}>Posts</Text>
                            </View>
                            <Pressable style={styles.profileItem} onPress={() => navigation.push("FollowersInfo", {username: loggedUserInfo.username,  initial_route: "FollowersInfoScreen"})}>
                                <Text style={styles.profileNumbers}>{loggedUserInfo.followers_count}</Text>
                                <Text style={styles.profileText}>Followers</Text>
                            </Pressable>
                            <Pressable style={styles.profileItem} onPress={() => navigation.push("FollowersInfo", {username: loggedUserInfo.username,  initial_route: "FollowingInfoScreen"})}>
                                <Text style={styles.profileNumbers}>{loggedUserInfo.following_count}</Text>
                                <Text style={styles.profileText}>Following</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
                <View style={styles.bioContainer}>
                    <Text style={styles.fullName}>{loggedUserInfo.first_name} {loggedUserInfo.last_name}</Text>
                    <Text style={styles.description}>{loggedUserInfo.bio}</Text>
                </View>
                <TouchableOpacity style={styles.button} onPress={() => navigation.push('Settings', loggedUserInfo)}>
                    <Text style={styles.buttonText} >Edit profile</Text>
                </TouchableOpacity>
                </View>
  

        )
    }

    return (

        <SafeAreaView style={styles.container}>
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />    
            <FlatList
                columnWrapperStyle={{maxWidth: 780, alignSelf: 'center', width: '100%'}}
                style={{width:"100%", height: "100%", backgroundColor: theme.BACKGROUND_ONE }}
                numColumns={3}
                data={(posts) ? posts : photos}
                ListHeaderComponent={ProfileHeader}
                keyExtractor={(item) => item.post_id}
                renderItem={({ item, index }) =>
                    <Item
                        item={item}
                        source={item.media[0].location}
                    />}
                onEndReached={loadMore}
                onEndReachedThreshold={0.5}
                onRefresh={() => refreshFeed()}
                refreshing={refreshing}
            />
              
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        width: '100%',
       
    },
    headerContainer: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        justifyContent: 'flex-start',
        width:"100%",
        maxWidth: 780,
        alignSelf: "center",
        paddingHorizontal:5
    },
    text: {
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular"
    },
    description: {
        color: theme.TEXT_COLOR,
        alignSelf: "flex-start",
        paddingLeft: 10,
        paddingBottom:10,
        lineHeight: 24,
        fontFamily:"OpenSans-Regular"

    },
    profileContainer: {
        flexDirection: "row",
        width: "100%",
        paddingTop: 20,

    },
    bioContainer: {
        flex: 1,
        width: "100%",
        paddingVertical: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 20
    },
    avatar: {
        width: 96,
        height: 96,
        borderRadius: 500,
        borderWidth: 2,
		borderColor: theme.LINE_GRAY
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    textContainer: {
        paddingHorizontal: 15,
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 10,
        color: theme.TITLE_COLOR,
        fontFamily:"Ubuntu-Bold"
    },
    profileItem: {
        marginTop: 5,
        marginRight: 25
    },
    profileNumbers: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Bold"
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular"
    },
    item: {
        width: "33%",
        marginRight: 2,
        marginBottom: 2,
        height: 120
    },
    photo: {
        width: "100%",
        height: 120
    },
    button: {
		borderWidth: 1,
		borderColor: theme.LINE_GRAY,
		width: "100%",
		alignSelf: "center",
		alignItems: "center",
		padding: 10,
		backgroundColor: theme.EDIT_PROFILE_BUTTON,
        marginBottom: 20,
	},
	buttonText:{
		color:theme.TEXT_COLOR,
        textTransform:"uppercase",
        fontFamily:"OpenSans-Bold"
	},
    fullName: {
        color: theme.TEXT_COLOR,
        paddingLeft:10,
        paddingTop:15,
        paddingBottom:5,
        fontWeight:"bold",
        fontSize:16,
        fontFamily:"OpenSans-Bold"
    },
    gear:{
        position:"absolute",
        right:0,
        top:20,
        width:24,
        height:24,
        zIndex:100
    },

});
