import React from "react";
import { View, Text, Image, StyleSheet, Dimensions, Pressable} from "react-native";
import moment from "moment";
import colors from "../../Colors";
const theme = colors.defaultTheme();

export default function ProductSearchResult({
  title = "",
  brand = "",
  thc = "",
  score = "",
  date = "",
  thumbnailUrl = "",
  username = "greenairro",
  onProductPress,
  showRemovePress = false,
  onRemoveProductPress
}) {
  const avatarImage = {
    uri: thumbnailUrl
      ? thumbnailUrl
      : "https://images.unsplash.com/photo-1616690002554-b53821496f45?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80",
  };
  // const date = new Date(1663259698002);
  const timeago = moment(date).fromNow();
  return (
    <View style={{flex: 1, flexDirection: 'row', backgroundColor: theme.BACKGROUND_ONE}}>
        <Pressable style={styles.itemContainer} onPress={onProductPress}>
          <View style={styles.imageContainer}>
            <Image source={avatarImage} style={styles.avatar} />
          </View>
          <View style={styles.textContainer}>
            <View
              style={{
                flex: 1,
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Text style={styles.profileTitle}>{title}</Text>
              <Text> </Text>
              <Text style={styles.thc}>THC: {thc}%</Text>
            </View>
            <Text style={styles.brandTitle}>by {brand}</Text>
            <Text style={styles.username}>@{username}</Text>
            <Text style={styles.date}>{timeago}</Text>
          </View>
          <View style={styles.overallContainer}>
            <Text style={styles.overallName}>Overall</Text>
            <Text style={styles.overallNumber}>{Math.round(score * 10) / 10}</Text>
          </View>
      </Pressable>
      { showRemovePress && 
        <Pressable onPress={onRemoveProductPress} style={{alignSelf: 'center', padding: 8 }}>
                <Text style={{fontSize: 24 }}>x</Text>
        </Pressable>
        }
    </View>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    padding: 8,
    borderBottomWidth: 1,
    justifyContent: "space-around",
    alignItems: "stretch",
    borderBottomColor: theme.LINE_GRAY,
    backgroundColor: theme.BACKGROUND_ONE,
  },
  imageContainer: {
    flex: 0,
    flexGrow: 0,
    flexBasis: 60,
    width: 60,
    flexDirection: "row",
  },
  avatar: {
    width: 50,
    height: 43,
    borderRadius: 0,
    borderWidth: 2,
    borderColor: theme.LINE_GRAY,
    margin: 4,
  },
  textContainer: {
    paddingHorizontal: 0,
    flex: 1,
  },
  profileTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.TITLE_COLOR,
  },
  brandTitle: {
    fontSize: 12,
    fontWeight: "600",
    color: theme.TITLE_COLOR,
  },
  followersTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.TITLE_COLOR,
  },
  profileText: {
    fontSize: 16,
    color: theme.TEXT_COLOR,
  },
  thc: {
    color: theme.TEXT_COLOR,
    paddingLeft: 0,
    paddingVertical: 0,
    paddingBottom: 0,
    fontSize: 14,
  },
  username: {
    color: theme.TEXT_COLOR,
    paddingLeft: 0,
    fontWeight: "bold",
    fontSize: 12,
  },
  date: {
    color: theme.TEXT_COLOR,
    paddingLeft: 0,
    paddingTop: 3,
    paddingBottom: 5,
    fontWeight: "300",
    fontSize: 12,
  },
  overallContainer: {
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    width: 60,
    flexBasis: 60,
    flex: 0,
    flexGrow: 0,
  },
  removeContainer: {
    width: 20,
    flexBasis: 20,
    marginTop: -4,
    flex: 0,
    flexGrow: 0,
  },
  overallName: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    color: theme.SECTION_TITLE,
  },
  overallNumber: {
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
    color: theme.TEXT_COLOR,
  },
});
