export default [{ 
    fields:{
        "name":"",
        "brand":"",
        "price":0,
        "thc":0,
        "weight":0,
        "looks_notes":"",
        "smell_notes":"",
        "moisture_notes":"",
        "flavor_notes":"",
        "your_experience":"",
        "hit_notes":"",
        "general_notes":"",
    },
    checks: { 
        "smalls": false,
        "shake": false,
        "joint": false,
        "bowl": false,
        "bong": false,
        "vape": false,
        "muscle_relax": false,
        "pain_relief": false,
        "nausea_relief": false,
        "creative": false,
        "contemplative": false,
        "focused": false,
        "hungry": false,
        "sleepy": false,
        "anxious": false,
        "hazy": false,
        "morning": false,
        "afternoon": false,
        "evening": false,
        "night": false,
    }, 
    rating: { 
        "focused": 5, 
        "happy": 5, 
        "relaxed": 5,
        "moisture":5,
        "looks": 5, 
        "smell": 5, 
        "taste": 5, 
        "strength": 5,
        "overall":5
    } 
}]