import {create} from "zustand";

const photoGallery = create((set, get) => ({
  photos: [],
  setPhotos: (obj) => {
    //console.log("album updated", obj.filename)

    set((state) => {
      let fillAlbum = [...state.photos, obj];

      return {
        photos: fillAlbum,
      };
    });
  },
  clearGallery: () => {
    //console.log("Gallery cleared")

    set((state) => ({
      photos: [],
    }));
  },
}));

export { photoGallery };
