import React from 'react';
import { useInfiniteQuery } from "react-query";
import Configuration from "../config/Configuration";
import axios from 'axios';
const SERVER = Configuration.BASE_SERVER_URL;

const fetchAllPosts = async ({ token, pageParam, limit }) => {
//   let posts = await axios.get(SERVER + "/api/profile/" + username + "/posts");

  let url = `${SERVER}/api/profile/allposts?page=${pageParam}&limit=${limit}`;
  let headers = { headers: { Authorization: `Bearer ${token}` } };
  let res = await axios.get(url, headers);
  return res.data;
};

export default ({token, limit = 12}) => {
  return useInfiniteQuery(
    ["allposts", token],
    ({ pageParam = 1 }) => fetchAllPosts({token, pageParam, limit}),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.posts.length == limit) {
          return allPages.length +  1;
        } else {
          return undefined;
        }
      },
    }
  );
};
