import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  FlatList,
  Pressable,
  Dimensions
} from "react-native";
import axios from 'axios';
import { useQuery } from "react-query";
import { Ionicons } from "@expo/vector-icons";
import useDebounce from "../hooks/useDebounce";
import colors from "../Colors"
import { loginService } from "../services/UserService";
import ProductSearchResult from "../components/lists/ProductSearchResult";
import useRecentSearches from "../services/useRecentSearches";

const theme = colors.defaultTheme()
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
import Configuration from "../config/Configuration";
const SERVER = Configuration.BASE_SERVER_URL;


export default function PostsFinder(props) {

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(undefined);
  const debouncedFilter = useDebounce(filter, 500);
  const authToken = loginService((state) => state.authToken);
  const addRecentSearch = useRecentSearches((state) => state.addRecentSearch);
  const lastSearches = useRecentSearches((state) => state.lastSearches);
  const clearRecentSearches = useRecentSearches((state) => state.clearRecentSearches);
  const removeRecentSearch = useRecentSearches((state) => state.removeRecentSearch);

  const { data, status, error } = useQuery(
    ["posts", debouncedFilter, authToken],
    () => fetchPosts(debouncedFilter, authToken),
    { enabled: Boolean(debouncedFilter) }
  );

  function selectedItem({item, props}) {
    addRecentSearch({searchType: "posts", item});
    props.navigation.push('SinglePost', item)
  }

  function renderItem({ item }) {
    let rating = (item.form && item.form.rating && item.form.rating.overall) ? item.form.rating.overall : 0
    let thumbnailUrl = (item.media && item.media.length > 0) ? item.media[0].location : ""
    let username = (item.author && item.author.length > 0 && item.author[0]) ? item.author[0].username : "";
    return (
      <View id={item.product_id}>  
        {item.form && item.form.fields && 
          <ProductSearchResult showRemovePress={false} onProductPress={() => selectedItem({item, props})} title={item.form.fields.name} brand={item.form.fields.brand} image={item.media[0]} date={new Date(item.created_at)} score={rating} thc="23" thumbnailUrl={thumbnailUrl} username={username}/>
        }
      </View>
    );
  }

  function selectedRecentItem({item, props}) {
    addRecentSearch({searchType: "posts", item});
    props.navigation.push('SinglePost', item)
  }

  function renderRecentItem({ item, index }) {
    let rating = (item.form && item.form.rating && item.form.rating.overall) ? item.form.rating.overall : 0
    let thumbnailUrl = (item.media && item.media.length > 0) ? item.media[0].location : ""
    let username = (item.author && item.author.length > 0 && item.author[0]) ? item.author[0].username : "";
    return (
      <Pressable id={item.product_id} onPress={() => selectedItem({item, props})}>  
        {item.form && item.form.fields && 
          <ProductSearchResult showRemovePress={true} onRemoveProductPress={() => removeRecentSearch({searchType: "posts", index})} onProductPress={() => selectedItem({item, props})} title={item.form.fields.name} brand={item.form.fields.brand} image={item.media[0]} date={new Date(item.created_at)} score={rating} thc="23" thumbnailUrl={thumbnailUrl} username={username}/>
        }
      </Pressable>
    );
  }

  const RecentsHeader = () => {
    return (
      <Pressable onPress={()=> clearRecentSearches({searchType: "posts"})}><Text style={{color: theme.BUTTON_TEXT_COLOR }}>clear</Text></Pressable>
    )
  }

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Ionicons name="ios-search" size={24} color={theme.ICON_ACTIVE_COLOR} />
        <TextInput autoFocus={true} onChangeText={(text) => setFilter(text)} style={styles.textInput} placeholder="Find a product" placeholderTextColor={theme.PLACEHOLDER_COLOR} />
      </View>

      {(!debouncedFilter || debouncedFilter.length == 0)  &&
        <FlatList
        style={styles.list}
        data={lastSearches["posts"]}
        contentContainerStyle={{ borderRadius: 8, overflow: 'hidden' }}
        renderItem={renderRecentItem}
        keyExtractor={(item) => item.post_id}
        ListHeaderComponent={() => {
          return <RecentsHeader/>
        }}
        />      
      }

      {status === "error" && <Text>{error.message}</Text>}

      {status === "loading" && <Text>Loading...</Text>}

      {status === "success" && (
        <FlatList
          style={styles.list}
          data={data}
          contentContainerStyle={{ borderRadius: 8, overflow: 'hidden' }}
          renderItem={renderItem}
          keyExtractor={(item) => item.post_id}
        />
      )}
    </View>
  );
}

const fetchPosts = async (query, authToken) => {
  let headers = {}
  if(authToken && authToken.length > 0){
      headers = {headers: {Authorization: `Bearer ${authToken}`}}
  }

  let url = SERVER + "/api/search/posts?search=" + query.toLowerCase();
  try {

  let posts = await axios.get(url,headers)
  return posts.data.posts;
  }catch(e) {
    console.log("error when getting posts ", e);
    return[]
  }
};

const styles = StyleSheet.create({
  searchInputSection: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.BACKGROUND_TWO,
    padding: 10,
  },
  searchIcon: {
    position: 'absolute',
    zIndex: 100,
    left: 20,
  },
  input: {
    backgroundColor: theme.BACKGROUND_TWO,
    color: "#424242",
    flex: 1,
    height: 28,
    width: "90%",
    paddingLeft: 10,
    color: theme.TEXT_COLOR
  },
  list: {
    padding: 10,
    flex: 1,
    width: '100%',
    borderRadius: 12,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.BACKGROUND_SEARCH_MODAL
  },
  searchContainer: {
    height: 50,
    backgroundColor: theme.BACKGROUND_ONE,
    width: SCREEN_WIDTH - 10,
    borderRadius: 10,
    justifyContent: "center",
    marginTop: 6,
    padding: 10,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  textInput: {
    height: 28,
    width: "90%",
    paddingLeft: 10,
    color: theme.TEXT_COLOR
  },
});
