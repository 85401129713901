import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Pressable,StatusBar } from 'react-native';
import { Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import { loginService, LoggedUserInfo, follow, unfollow, isFollowed } from "../services/UserService";
import { getMyPosts, allPosts } from "../services/PostServices";
import useUserPosts from '../hooks/useUserPosts';
import Header from '../components/Header';
import useProfile from '../hooks/useProfile';
import LoadingView from '../components/Loading';
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

//colors
import colors from "../Colors"
import axios from 'axios';

//photos for the feedr
import photos from "../mock/Photos"

const theme = colors.defaultTheme()
const MAX_WIDTH = 780

export default function ProfileViewer({ navigation, route }) {

    const [fontsLoaded]= useFonts(fontsList);  
    const username = route.params.username;
    // const [posts, setPosts] = useState([])
    const [loggedUserData, setLoggedUserData] = useState({})
    const origin = allPosts((state) => state.origin);
    const loggedUsername = loginService((state) => state.username)
    const setOrigin = allPosts((state) => state.setOrigin);
    const authToken = loginService((state) => state.authToken);
    const [followed, setFollowed] = useState(null)
    const setUserInfo = loginService((state) => state.setUserInfo);
    const { isLoading, data, hasNextPage, fetchNextPage, refetch } = useUserPosts({limit: 8, username });
    const [refreshing, setRefreshing] = useState(false);
    const {status, data: profileData} = useProfile({username: username});

    const avatarImage = { uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612" }
   

    useEffect(() => {
        const {screen_origin} = route.params;
        setOrigin(screen_origin);

        async function getUserInfo() {

            const infoData = await LoggedUserInfo(username);

            if (loggedUserData) {
                setLoggedUserData(infoData)
            }

        }

        getUserInfo()

        async function getFollowed() {

            const flw = await isFollowed(loggedUsername, username);

            setFollowed(flw)

        }

        if (loggedUsername) {

            getFollowed()

        }

       

    }, []);

    useEffect(() => {

        async function getUserInfo() {

            const infoData = await LoggedUserInfo(username);

            if (loggedUserData) {
                setLoggedUserData(infoData)
                if (loggedUsername) {
                    const loggedInfoData = await LoggedUserInfo(loggedUsername);
                    setUserInfo(loggedInfoData)
                }
            }

        }

        getUserInfo()

    }, [followed]);



    if (isLoading || status == "loading") {
        return (
        <LoadingView></LoadingView>
        )
    }

    let posts = [];
    if (!isLoading) {
        posts = data.pages.map(page => page.posts).flat();
        // posts.sort((a, b) => {
        //     return b.timestamp - a.timestamp;
        // });
    }
    let userProfile = loggedUserData;
    if (status === 'success') {
        userProfile = profileData;
    }


    function loadMore() {
      if (hasNextPage) {
        fetchNextPage();
      }
    }

    async function refreshFeed() {
      setRefreshing(true);
      await refetch();
      setRefreshing(false);
    }  

    function singleView(item) {

        setOrigin("Profile")
        navigation.push('SinglePost', item)

    }

    function checkFollow(loggedUsername) {

        if (loggedUsername) {
            return true
        } else {
            return false
        }

    }

    function followUser(username, authToken) {

        if (followed == true) {
            unfollow(username, authToken)
            setFollowed(false)
        } else {
            follow(username, authToken)
            setFollowed(true)
        }

    }

    const Item = ({ item, source }) => {
        return (
            <TouchableOpacity style={styles.item} onPress={() => singleView(item)}>
                <ImageBackground source={{ uri: source }} style={styles.photo} resizeMode="cover" />
            </TouchableOpacity>
        )
    }

    const ProfileHeader = () => {
        return (
      
            <View style={styles.headerContainer}>
                <View style={styles.profileContainer}>
                    <View>
                        <Image source={avatarImage} style={styles.avatar} />
                    </View>
                    <View style={styles.textContainer}>
                        <View>
                            <Text style={styles.profileTitle}>{userProfile.username} </Text>
                        </View>
                        <View style={styles.horizonltalAlign}>
                            <View style={styles.profileItem}>
                                <Text style={styles.profileNumbers}>{userProfile.posts_count}</Text>
                                <Text style={styles.profileText}>Posts</Text>
                            </View>
                            <Pressable style={styles.profileItem} onPress={() => navigation.push("FollowersInfo", {username: loggedUserData.username, initial_route: "FollowersInfoScreen"})}>                                
                                <Text style={styles.profileNumbers}>{userProfile.followers_count}</Text>
                                <Text style={styles.profileText}>Followers</Text>
                            </Pressable>
                            <Pressable style={styles.profileItem} onPress={() => navigation.push("FollowersInfo", {username: loggedUserData.username,  initial_route: "FollowingInfoScreen"})}>
                                <Text style={styles.profileNumbers}>{userProfile.following_count}</Text>
                                <Text style={styles.profileText}>Following</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
                <View style={styles.bioContainer}>
                    <Text style={styles.fullName}>{userProfile.first_name} {userProfile.last_name}</Text>
                    <Text style={styles.description}>{userProfile.bio}</Text>
                </View>
                {checkFollow(loggedUsername) &&
                <TouchableOpacity style={styles.button} onPress={() => followUser(username, authToken)}>
                    <Text style={styles.buttonText} >{followed == true ? "Unfollow" : "Follow"}</Text>
                </TouchableOpacity>
                }
            </View>

        )
    }

    

    return (

        <SafeAreaView style={styles.container}>
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
            <Header
                showLogo={false}
                navigation={navigation}
                showBackButton={true}
            /> 
            <FlatList
                columnWrapperStyle={{maxWidth: 780, alignSelf: 'center', width: '100%'}}
                style={{width:"100%", height: "100%", backgroundColor: theme.BACKGROUND_ONE }}
                numColumns={3}
                data={(posts) ? posts : photos}
                ListHeaderComponent={ProfileHeader}
                renderItem={({ item, index }) =>
                    <Item
                        item={item}
                        source={item.media[0].location}
                    />}
                onEndReached={loadMore}
                onEndReachedThreshold={0.75}
                onRefresh={() => refreshFeed()}
                refreshing={refreshing}
            />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        width:"100%",
    },
    headerContainer: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        justifyContent: 'flex-start',
        width:"100%",
        maxWidth: 780,
        alignSelf: "center"
    },
    text: {
        color: theme.TEXT_COLOR,
    },
    description: {
        color: theme.TEXT_COLOR,
        alignSelf: "flex-start",
        paddingLeft: 10,
        lineHeight: 24,
        paddingBottom: 10,
    },
    profileContainer: {
        flexDirection: "row",
        width: "100%",
        paddingTop: 20,
    },
    bioContainer: {
        flex: 1,
        width: "100%",
        paddingVertical: 0,
        borderBottomWidth: 1,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 20
    },
    avatar: {
        width: 96,
        height: 96,
        borderRadius: 500,
        borderWidth: 2,
        borderColor: theme.LINE_GRAY
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    textContainer: {
        paddingHorizontal: 15,
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginTop: 10,
        color: theme.TITLE_COLOR
    },
    profileItem: {
        marginTop: 5,
        marginRight: 25
    },
    profileNumbers: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    item: {
        width: "33%",
        marginRight: 2,
        marginBottom: 2,
        height: 120
    },
    photo: {
        width: "100%",
        height: 120
    },
    button: {
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        paddingVertical: 10,
        backgroundColor: theme.EDIT_PROFILE_BUTTON,
        marginBottom:20
    },
    buttonText: {
        color: theme.TEXT_COLOR,
        textTransform: "uppercase"
    },
    fullName: {
        color: theme.TEXT_COLOR,
        paddingLeft: 10,
        paddingTop: 15,
        paddingBottom: 5,
        fontWeight: "bold",
        fontSize: 16
    },

});
