import React, { useState, useEffect, useCallback, useRef } from "react";
import { FlatList, View, Text, StyleSheet, Pressable, Button, Image } from "react-native";
//colors
import colors from "../Colors"
const theme = colors.defaultTheme()
const avatarImage = { uri: "https://media.istockphoto.com/vectors/profile-anonymous-face-icon-gray-silhouette-person-male-default-vector-id903053114?s=612x612" }

export default function UserList({ users, rowPressed, loggedIn = false }) {
  const renderIem = ({ item }) => {
    const { first_name, last_name, username } = item;

    const full_name = first_name + " " + last_name;
    return (
      <Pressable style={styles.row} onPress={() => rowPressed(username)}>
        <View>
          <Image source={avatarImage} style={styles.avatar} />
        </View>
        <View style={styles.userinfo}>
          <Text style={styles.username}>{username}</Text>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <Text style={styles.first_last}>{full_name}</Text>
          </View>
        </View>
      </Pressable>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        style={styles.list}
        data={users}
        contentContainerStyle={{ borderRadius: 8, overflow: "hidden" }}
        renderItem={renderIem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
    alignItems: "flex-start",
  },
  follow_button: {
    width: 200,
    backgroundColor: '#993321',
    borderRadius: 8
  },
  row: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    padding: 4,
  },
  userinfo: {
    flex: 1,
    flexDirection: "column",
  },
  list: {
    padding: 10,
    background: "#999993",
    flex: 1,
    width: "100%",
    height: "100%",
    borderRadius: 12,
  },
  username: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.TEXT_COLOR
  },
  first_last: {
    fontSize: 12,
    color: theme.TEXT_COLOR
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 500,
    borderWidth: 2,
    borderColor: theme.LINE_GRAY,
    marginRight:10
}
});
