import { ActivityIndicator, View, StyleSheet } from 'react-native';
import colors from "../Colors"

const theme = colors.defaultTheme()

export default function LoadingView(props) {

    return (

        <View style={[styles.container, styles.horizontal]}>
            <ActivityIndicator size="large" color={theme.ICON_ACTIVE_COLOR} />
        </View>

    )

}

const styles = StyleSheet.create({
    container: {
        height:"110%",
        width:"100%",
        backgroundColor: theme.BACKGROUND_ONE,
        alignItems: 'center',
        justifyContent: 'center'
    },
    horizontal: {
        flexDirection: "row",
    }
});



