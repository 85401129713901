export default [{ 
    fields:{
        "name":"Berniehana Butter",
        "brand":"Cookies",
        "price":20,
        "thc":20,
        "weight":0.5,
        "looks_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "smell_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "moisture_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "flavor_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "your_experience":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "hit_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
        "general_notes":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam facilisis nulla et volutpat tempus.",
    },
    checks: { 
        "smalls": true,
        "shake": false,
        "joint": false,
        "bowl": false,
        "bong": true,
        "vape": false,
        "muscle_relax": false,
        "pain_relief": true,
        "nausea_relief": false,
        "creative": false,
        "contemplative": true,
        "focused": false,
        "hungry": false,
        "sleepy": true,
        "anxious": false,
        "hazy": false,
        "morning": false,
        "afternoon": false,
        "evening": false,
        "night": true,
    }, 
    rating: { 
        "focused": 5, 
        "happy": 8.5, 
        "relaxed": 10,
        "moisture":6,
        "looks": 7, 
        "smell": 8, 
        "taste": 9, 
        "strength": 9,
        "overall":8.5
    } 
}]