
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Dimensions, Button, TextInput, StatusBar } from 'react-native';
import { Ionicons, Entypo, Fontisto, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import { allPosts } from "../services/PostServices"
import Header from '../components/Header';
import { loginService, updateProfile, LoggedUserInfo } from "../services/UserService";
import axios from 'axios';
import LoadingView from '../components/Loading';
import useRecentSearches from '../services/useRecentSearches';
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
//colors
import colors from "../Colors"

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')

const theme = colors.defaultTheme()

export default function Settings({ navigation, route }) {    

    const [fontsLoaded]= useFonts(fontsList);  

    const user = route.params
    const setOrigin = allPosts((state) => state.setOrigin);
    const logout = loginService((state) => state.resetUser);
    const username = loginService((state) => state.username);
    const authToken = loginService((state) => state.authToken);
    const [firstName, setFirstName] = useState(user.first_name)
    const [lastName, setLastName] = useState(user.last_name)
    const [bio, setBio] = useState(user.bio)
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const setUserInfo = loginService((state) => state.setUserInfo);
    const clearAllSearches = useRecentSearches((state) => state.clearAllSearches);

    const sendPostRequest = async () => {

        try {
            setLoading(true)
            const res = await updateProfile(authToken, firstName, lastName, bio);
            const response = res.data

            if (response.status == "success") {  
              
                async function getUserInfo() {    

                    const infoData = await LoggedUserInfo(username);     
                    //setLoggedUserData(infoData)
                    setUserInfo(infoData)  

                    console.log(infoData)
                    
                    navigation.navigate("Profile")

                    setLoading("")
                
                }
        
                getUserInfo()
            
                setErrorMessage("")

            } else {

                setErrorMessage(response.message)

            }



        } catch (error) {

            console.log(error)

            setErrorMessage(error.message)
            setLoading(false)
        }

    } 

    useEffect(() => {

        console.log(user)

    }, [])

    if (loading != "") { return (<LoadingView message={loading} />) }
  
    return (
        <SafeAreaView style={styles.container}>
            <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
            <Header
                navigation={navigation}
                showLogo={false}
                showBackButton={true}
                showActionButton={true}
                actionButtonLabel={"Save"}
                backButtonLabel={"Cancel"}
                actionButtonPressed={sendPostRequest}
            />  
            <KeyboardAwareScrollView style={styles.scrollView}>
                <View style={{ alignItems: "center", paddingVertical: 10 }}>
                    <Text style={styles.profileTitle}>Edit profile</Text>
                </View>             
                <Text style={styles.label}>First Name</Text>
                <TextInput style={styles.textInput} onChangeText={(firstName) => setFirstName(firstName)} defaultValue={user.first_name} />
                <Text style={styles.label}>Last Name</Text>
                <TextInput style={styles.textInput} onChangeText={(lastName) => setLastName(lastName)} defaultValue={user.last_name}/>
                <Text style={styles.label}>Bio</Text>
                <TextInput
                    style={styles.textAreaNote}
                    multiline
                    numberOfLines={5}
                    placeholderTextColor={theme.PLACEHOLDER_COLOR}
                    onChangeText={(bio) => setBio(bio)}
                    defaultValue={user.bio}
                />
                <View style={{ alignItems: "center", paddingVertical: 10 }}>
                    <Text style={styles.profileTitle}> </Text>
                </View>   
                <TouchableOpacity style={[styles.button,styles.alert]} onPress={() => {
                    clearAllSearches();
                    logout();
                }}>
                    <View><Text style={styles.alertText}>Logout</Text></View>
                </TouchableOpacity>

            </KeyboardAwareScrollView>
        </SafeAreaView>



    )

}

const styles = StyleSheet.create({
    scrollView: {
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        margin: 0,
        width: "100%",
        maxWidth:780,
        paddingTop: 10,
        paddingHorizontal:10

    },
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        color: theme.TEXT_COLOR,
        alignItems: 'center',
        margin: 0,
        width: "100%",
        justifyContent: 'center',
        paddingTop: 10,
     
    },
    horizonltalAlign: {
        flexDirection: "row"
    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginVertical: 10,
        color: theme.TEXT_COLOR,
        fontFamily:"Ubuntu-Bold"
    }, 
    formContainer: {

        width: "100%",
        height: 400,

    },
    label: {
        width: "100%",
        alignSelf: "center",
        padding: 5,
        fontSize: 16,
        fontWeight: "800",
        color: theme.SECTION_TITLE,
        marginVertical: 5,
        fontFamily:"OpenSans-Bold"

    },
    sectionTitle: {
        width: "100%",
        textAlign: "center",
        padding: 20,
        fontSize: 18,
        fontWeight: "700",
        color: theme.SECTION_TITLE,
        textTransform: "uppercase"
    },
    textInput: {
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        width: "100%",
        alignSelf: "center",
        padding: 10,
        fontSize: 16,
        color: theme.TEXT_COLOR,
        fontFamily:"OpenSans-Regular"
    },
    textArea: {
        width: "100%",
        alignSelf: "center",
        justifyContent:"flex-start",
        borderWidth: 0,
        maxHeight: 150,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 20,
        fontSize: 16,
        color: theme.PLACEHOLDER_COLOR,
        marginTop: 10
    },
    textAreaNote: {
        width: "100%",
        borderWidth: 1,
        maxHeight: 150,
        borderColor: theme.INPUT_BORDER_COLOR,
        marginBottom: 10,
        paddingHorizontal: 10,
        paddingVertical: 10,
        textAlignVertical: 'top',
        fontSize: 16,
        color: theme.TEXT_COLOR,
        marginTop: 0,
        backgroundColor: theme.BACKGROUND_ONE,
        textAlignVertical: 'top'
    },
    preview: {
        width: SCREEN_WIDTH - 20,
        height: (SCREEN_WIDTH / 200) * 175,
        marginBottom: 20
    },
    button: {
        backgroundColor: theme.BACKGROUND_ONE,
        borderWidth:1,
        borderColor:theme.LINE_GRAY,
        height: 40,
        width: "100%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",

    },
    alert: {
        backgroundColor: "#f55",
        borderWidth:1,
        borderColor:theme.BACKGROUND_ONE,
        height: 40,
        width: "100%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",

    },
    buttonText: {
        color: theme.BUTTON_TEXT_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold",
        textAlign:"center"
    },
    alertText: {
        color: "#fff",
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold",
        textAlign:"center",
        fontFamily:"OpenSans-Bold"
    },


});
