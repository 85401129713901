import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Button,
} from "react-native";
import StoreFinder from "../components/StoreFinder";
import colors from "../Colors";
import { CardStyleInterpolators } from '@react-navigation/stack';

const theme = colors.defaultTheme();

export default function StorePickerModal({ navigation, route }) {
  const { result_screen_name } = route.params;
  console.log("the result screen name is", result_screen_name);
  
  function modalGotResult({ store_id, name, username }) {
    if (result_screen_name && result_screen_name === 'ProfileViewer') {
      navigation.navigate({
        name: result_screen_name,
        params: { username },
        merge: true,
      });
    } else {
      navigation.navigate({
        name: result_screen_name,
        params: { store: { store_id, name } },
        merge: true,
      });
    }
    
  }

  return (
    <View style={{ flex: 1,backgroundColor:theme.BACKGROUND_SEARCH_MODAL, height: "100%", width: "100%", alignItems: 'center'}}>
      <StoreFinder gotResult={modalGotResult}></StoreFinder>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    color: theme.TEXT_COLOR,
    fontSize: 14
  },
});
