export default [
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F5e7be6c1a30d848a%2Foriginal%2FBernie-Hana-Butter&w=1024&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F4e7efe8d5af03bb1%2Foriginal%2F2022-03-04_cookies_CerealALaMode_indoor_dryflower-3&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2Fd2846add7aae941%2Foriginal%2F2022-03-30_cookies_AdiosMF_indoor_dryflower-3&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F5ce1ab4af6ee155c%2Foriginal%2F220309_Cookies_AdiosMF_indoor_LateStage-1&w=1024&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F22c7bd3c53bd1ca1%2Foriginal%2F220102_Madcow_Cookies_BabyPowder_Indoor_DryBud-12-Edit&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F31315968d74e3bc2%2Foriginal%2F210924_Cookies_BabyPowder_Indoor_Latestage_1&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F209ba6cb01d6272e%2Foriginal%2F220216_Cookies_Bernscotti_indoor_Latestage-1&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F75c91f9c1fb5dc6c%2Foriginal%2F2022-03-15_cookies_BernScotti_indoor_dryflower-3&w=1536&q=75"},
    {uri: "https://cookies.co/_next/image?url=https%3A%2F%2Fa.c6cdn.net%2Fcdn-cgi%2Fimage%2Ff%3Dauto%252Cq%3D85%2Fo%2Fw_1000%252Cdpr_auto%2Fasset%2F38fb7bd1f784a4e5%2Foriginal%2F220331_Cookies_Madcow_BlueAgave_Indoor_Latestage_1&w=1536&q=75"},
   ]
