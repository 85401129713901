import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, View, Image, StyleSheet, Text, SectionList, Pressable, TouchableOpacity, TextInput, Dimensions, useWindowDimensions, ScrollView } from 'react-native';
import { FontAwesome5, FontAwesome, Feather, Ionicons, MaterialIcons, AntDesign, SimpleLineIcons, MaterialCommunityIcons } from '@expo/vector-icons'
import { deletePost } from '../services/PostServices';
import { loginService } from "../services/UserService";
import LoadingView from "../components/Loading";

//colors
import colors from "../Colors"
const theme = colors.defaultTheme()

export default function PostActionModal({ navigation, route }) {

    const post = route.params    
    const authToken = loginService((state) => state.authToken);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);

    if (loading) {
        return (
        <LoadingView></LoadingView>
        )
    }

    return (
        <SafeAreaView style={styles.container}>
            {confirm &&
                <View style={{ position: "absolute", backgroundColor: theme.BACKGROUND_ONE, borderWidth: 1, borderRadius: 10, borderColor: theme.LINE_GRAY, padding: 50, zIndex: 999 }}>
                    <Text style={{color:theme.TEXT_COLOR}}>Are you sure you want to delete?</Text>
                    <View style={{flexDirection:"row", justifyContent:"space-around"}}>
                        <TouchableOpacity onPress={() => setConfirm(false)} style={[styles.smallButton]}>
                            <Text style={{color:theme.TEXT_COLOR}}>No</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={async () => {
                            setLoading(true)
                            await deletePost(post.post_id, authToken)
                            navigation.navigate("Me", { screen: "Profile", params: { refreshFeed: true } })
                            setLoading("")

                        }} style={styles.smallButton}>
                            <Text style={{color:theme.TEXT_COLOR}}>Yes</Text>
                        </TouchableOpacity>

                    </View>
                </View>
            }
            <View style={styles.sectionList} >
                <View style={{ minHeight: 40, backgroundColor:theme.BACKGROUND_ONE }}>
                <TouchableOpacity onPress={() => {navigation.navigate("Me", { screen: "EditPost", params:post })} } style={styles.button}>
                    <Text style={styles.buttonText}><FontAwesome name="edit" size={18} color={theme.TEXT_COLOR} /> Edit Post</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setConfirm(true) } style={styles.button}>
                    <Text style={styles.buttonText}><Ionicons name="ios-trash-outline" size={18} color={theme.TEXT_COLOR} /> Delete Post</Text>
                </TouchableOpacity>
                </View>
            </View>           
        </SafeAreaView>

    )

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor:theme.BACKGROUND_TWO,
        justifyContent:"center",
        alignItems:"center",
        width: "100%",
    },
    sectionList: {
        width: "90%",
        alignSelf: "center",
        justifyContent: "center",
        maxWidth:780,

    },
    title: {
        fontSize: 26,
        fontWeight: "bold",
        textAlign: "center"
    },
    text: {
        fontSize: 22,
        lineHeight:30,
        marginTop: 20,
        marginBottom: 50
    },
    buttonContainer: {
        width: "100%",
        paddingTop: 25,
        paddingBottom: 25,
        backgroundColor: "#f1f1f1",
        borderTopWidth: 1,
        borderTopColor: "#ccc",
        marginTop: 0,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,

    },
    button: {
        height: 50,
        width: "100%",
        alignSelf: "center",
        borderBottomWidth:1,
        borderColor:theme.LINE_GRAY
    },
    buttonText: {
        fontSize: 18,
        paddingHorizontal: 15,
        paddingVertical: 10,
        lineHeight: 25,
        color: theme.TEXT_COLOR,
        alignSelf: "center",
        fontWeight: "bold",
        textTransform: "uppercase"

    },

    smallButton: {
        paddingHorizontal:15,
        paddingVertical:5,
        borderRadius:5,
        borderWidth:1,
        borderColor:theme.LINE_GRAY,
        marginTop:20
    },
  



});



