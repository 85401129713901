

import { View, Platform, StyleSheet } from "react-native";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { Pagination } from "react-native-swiper-flatlist";
import { AntDesign } from '@expo/vector-icons'
import { useState, useRef, useEffect } from "react";
import * as ReactDOM from 'react-dom';

import colors from "../Colors";
import { TouchableOpacity } from "react-native-gesture-handler";
import { nativeID } from "deprecated-react-native-prop-types/DeprecatedTextPropTypes";

const theme = colors.defaultTheme();

//custom component to show pagination arrows for web

const style = StyleSheet.create({
  paginationContainer: {
     width: '100%',
     height: '100%',
     top: 0,
  },
  pagination: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#FFFFFF',
    width: 8,
    height: 8,
    marginRight: 2,
    marginLeft: 2,
  },
  arrow: {
    color: "#FFFFFF",
    textShadowColor: '#000000',
    textShadowRadius: 10,
  }
})

let uniqueCounter = 0; //add extra number to guarantee unique values

export default function SwiperFlatListExt(props) {

  const [index, setIndex] = useState(0);
  const swiperRef = useRef({});
  let localNativeID = (props.nativeID) ? props.nativeID : "SwiperFlatListExtID" + (uniqueCounter++)
  let topOffset = (props.offsetTop) ? props.offsetTop : 0
  
   
  const goPrev = () => {
    if (index > 0) {
      let idx = index - 1
      setIndex(idx)
      swiperRef.current.scrollToIndex({ index: idx })
    }
  }

  const goNext = () => {
    if (index < props.data.length) {
      let idx = index + 1
      setIndex(idx)
      swiperRef.current.scrollToIndex({ index: idx })

    }
  }

  const CustomPagination = (props2) => {

    let offsetTop  = props.offsetTop ?  props.offsetTop : 0
    let imgWidth = (props.imgWidth) ? props.imgWidth : '100%'
    let imgHeight = (props.imgHeight) ? props.imgHeight * 0.9 : '90%'
   
  
    // MOBILE
    if (Platform.OS != "web") {
      return (<Pagination
        {...props2}
        index={index}
        paginationStyle={[style.paginationContainer, { top: offsetTop}]}
        paginationStyleItem={style.pagination}
        paginationDefaultColor={theme.PAGINATION_INACTIVE_COLOR}
        paginationActiveColor={theme.PAGINATION_ACTIVE_COLOR}

      />)
    }

    
    // WEB
    let el = document.getElementById(localNativeID)
    if (el) {
      topOffset = el.offsetTop
    }
   
    return (
      <View style={{ width: imgWidth, height: imgHeight, top: topOffset, position: 'absolute' }} >

        <Pagination
          {...props2}
          index={index}
          paginationStyle={[style.paginationContainer, {top: '90%'}]}
          paginationStyleItem={style.pagination}
          paginationDefaultColor={theme.PAGINATION_INACTIVE_COLOR}
          paginationActiveColor={theme.PAGINATION_ACTIVE_COLOR}
          disabled
        />
        <View style={{
          flexDirection: 'row',
          flex: 1,
          padding: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1,
        }
        } >
          {index > 0 &&
            <TouchableOpacity onPress={goPrev}>
              <AntDesign name="leftcircle" size={32} style={style.arrow} />
            </TouchableOpacity>
          }
          {((index == 0) || (index + 1 == props.data.length)) &&
            <View></View>
          }
          {index + 1 < props.data.length &&
            <TouchableOpacity onPress={goNext}>
              <AntDesign name="rightcircle" size={32} style={style.arrow}   />
            </TouchableOpacity>
          }
        </View>
      </View>

    );
  };

  const extProps = {...props, nativeID: localNativeID}
 
  return (
    <SwiperFlatList
      {...extProps}

      getItemLayout = {//FIX ISSUES WHERE SCREEN LARGER THAN 780
        (__data, ItemIndex: number) => {
          return {
            length: props.data.length,
            offset: props.imgWidth * ItemIndex,
            index: ItemIndex,
          };
        }
      }
      ref={(component) => swiperRef.current = component}
      PaginationComponent={CustomPagination} 
    />
  )

}