import {create} from "zustand";
import axios from "axios";
import { persist, createJSONStorage } from "zustand/middleware";
import _ from 'lodash';
import DefaultStorage from './DataStorage';

const useRecentSearches = create(
  persist(
    (set, get) => ({
      lastSearches: {},
      addRecentSearch: ({ searchType, item }) => {
        if (searchType === 'posts') {
          item.searchKey = item.post_id;
        }
        set((state) => {
            let recentSearches = [];
            if (state.lastSearches[searchType]) {
              recentSearches = [...(state.lastSearches[searchType])];
            }
            let existingItem = null;
            for (var i = recentSearches.length - 1; i >= 0; i--) {
              if (recentSearches[i].searchKey === item.searchKey) {
                let existingItems = recentSearches.splice(i, 1);
                if (existingItems && existingItems.length > 0) {
                  existingItem = existingItems[0];
                }
              }
            }            
            recentSearches.unshift(item);
            let newRecentSearches = _.cloneDeep(state.lastSearches);
            newRecentSearches[searchType] = recentSearches;
          return {
            lastSearches: newRecentSearches
          };
        });
      },
      removeRecentSearch: ({ searchType, index }) => {
        set((state) => {
          let recentSearches = [];
          if (state.lastSearches[searchType]) {
            recentSearches = [...(state.lastSearches[searchType])];
          }
          let existingItems = recentSearches.splice(index, 1);
          let newRecentSearches = _.cloneDeep(state.lastSearches);
          newRecentSearches[searchType] = recentSearches;
          return {
            lastSearches: newRecentSearches,
          };
        });
      },
      clearRecentSearches: ({ searchType }) => {
        set((state) => {
          let newLastSearch = _.cloneDeep(state.lastSearches);
          newLastSearch[searchType] = [];
          return {
            lastSearches: newLastSearch,
          };
        });
      },
      clearAllSearches: () => {
        set((state) => {         
          return {
            lastSearches: [],
          };
        });
      },
    }),
    {
      name: "recentSearches",
      storage: createJSONStorage(() => (DefaultStorage))
    }
  )
);

export default useRecentSearches;
