import React, { useState,useRef } from "react";
import { StyleSheet, Text, View, TextInput, TouchableOpacity, Dimensions,ScrollView, SafeAreaView } from "react-native";
import { Fontisto, Feather, Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons'
import Header from "../components/Header";
//assets
import colors from "../Colors"

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
const MAX_WIDTH = 580
const theme = colors.defaultTheme()

export default function Terms({ navigation }) {       

    return (


        <SafeAreaView style={styles.container}>
            <Header
                navigation={navigation}
                showLogo={false}
                showBackButton={true}

            /> 
            <ScrollView style={styles.scrollview}>
                <Text style={styles.title}>Terms of service</Text>
                <Text style={styles.subTitle}>EFFECTIVE DATE: February 22, 2023</Text>
                <Text style={styles.text}>These Joyntly Terms of Service (the "Terms") are a legal contract between you and Joyntly LLC ("Joyntly". "we" or "us"). These Terms explain how you are permitted to use the Joyntly mobile application (the "App") and services, as well as the Joyntly website located at https://joyntly.app (the "Site") and any content therein (collectively, the "Services"). By using the Services, you are agreeing to all of the Terms.</Text>
                <Text style={styles.subTitle}>General Use</Text>
                <Text style={styles.text}>By using, you represent, acknowledge and agree that you are at least 18 years of age. If you are not at least 18 years old, you may not use the Services at any time or in any manner or submit any information to the App, the Site or any part of the Services.</Text>
                <Text style={styles.subTitle}>Unauthorized Activities</Text>
                <Text style={styles.text}>When using the Services, you agree not to Impersonate another person in a manner that is intended to or does mislead, or deceive others. Post or share anything that contains pornography, explicit nudity, or extreme violence. Post or share anything that exploits children or minors or that depicts cruelty to animals. Post or share anything that pushes hard drug, pill, or gun sales.</Text>
                <Text style={styles.subTitle}>Contact Us</Text>
                <Text style={styles.text}>If you have any questions about these Terms or otherwise need to contact  Joyntly for any reason, you can reach us at support@joyntly.app.</Text>
            </ScrollView>            
        </SafeAreaView>


    )
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.BACKGROUND_ONE,
    },
    scrollview:{
        padding:20,
        marginBottom:20,
        maxWidth:MAX_WIDTH
    },
    text: {
        fontSize: 14,
        lineHeight:24,
        color: theme.TEXT_COLOR,
        paddingBottom:20
    },
    title: {
        color: theme.TITLE_COLOR,
        fontSize: 22,
        fontWeight:"bold",
        textAlign:"center",
        marginBottom:20
    },
    subTitle: {
        color: theme.TITLE_COLOR,
        fontSize: 16,
        fontWeight:"bold",
        marginBottom:10
    },  
    link: {

        color: theme.LOGIN_LINK,
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 10

    },
});


