import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Button,
} from "react-native";
import PostsFinder from "../components/PostsFinder";

import colors from "../Colors";
import { CardStyleInterpolators } from '@react-navigation/stack';

const theme = colors.defaultTheme();

export default function PostFinderModal({ navigation, route }) {
  const { result_screen_name } = route.params;

  return (
    <View style={{ flex: 1, backgroundColor: 'red', height: "100%", width: "100%", alignItems: 'center'}}>
      <PostsFinder />
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    color: "#FFFFFF",
    fontSize: 14
  },
});
