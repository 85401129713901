import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  StyleSheet,
  View,
  Button,
} from "react-native";
import UserFinder from "../components/UserFinder";
import colors from "../Colors";
import { CardStyleInterpolators } from '@react-navigation/stack';

const theme = colors.defaultTheme();

export default function UserFinderModal({ navigation, route }) {
  
  function modalGotResult({ store_id, name, username }) {
      navigation.navigate({
        name: 'ProfileViewer',
        params: { username },
        merge: true,
      });
    }

  return (
    <View style={{ flex: 1, backgroundColor:theme.BACKGROUND_SEARCH_MODAL, height: "100%", width: "100%", alignItems: 'center'}}>
      <UserFinder gotResult={modalGotResult}></UserFinder>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    color: "#FFFFFF",
    fontSize: 14
  },
});
