import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  SafeAreaView,
  Dimensions,
  useWindowDimensions,
  StyleSheet,
  StatusBar,
  Pressable
} from "react-native";
import Header from "../components/Header";
import SearchUserTab from "./SearchUserTab";
import SearchProductTab from "./SearchProductTab";
import { Ionicons } from "@expo/vector-icons";
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import colors from "../Colors";
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get("window");
const theme = colors.defaultTheme();
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

export default function DiscoverScreen({ navigation, route }) {
  
  const [fontsLoaded]= useFonts(fontsList);  

  const {height, width} = useWindowDimensions();

  const UsersTab = () => (
    <View style={{ flex: 1, backgroundColor: theme.BACKGROUND_ONE }} >
      <SearchUserTab navigation={navigation}/>
    </View>
  );

  const ProductsTabs = () => (
    <View style={{ flex: 1, backgroundColor: theme.BACKGROUND_ONE }} >
      <SearchProductTab navigation={navigation} />
    </View>
  );

  const renderScene = SceneMap({
    products: ProductsTabs,
    users: UsersTab,
  });

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'products', title: 'Products' },
    { key: 'users', title: 'Users' },
  ]);

  const renderTabBar = props => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.NAVTAB_INDICATOR }}
      activeColor={theme.TEXT_COLOR}
      inactiveColor={theme.NAVTAB_INACTIVE}
      style={{ backgroundColor: theme.NAVTAB_BG, width: "100%", maxWidth: 780, alignSelf: "center" }}
    />
  );


  let placeholder = "Find a product";
  let screenName = "PostsFinderModal";
  if (index == 1) {
    placeholder = "Find a user";
    screenName = "UserFinderModal";
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar barStyle={theme.mode == "light" ? "dark-content" : "light-content"} backgroundColor={theme.STATUS_BAR} />
      <View style={{ flex: 1, width: "100%", height: "100%", alignSelf:"center" }}>
        <Pressable style={[styles.searchContainer,{width:width, maxWidth:780, alignSelf:"center" }]} onPress={() => navigation.navigate({ name: screenName, })}>
          <Ionicons name="ios-search" size={18} color={theme.ICON_ACTIVE_COLOR} style={styles.icon}/>
          <TextInput
          onPressIn={() => navigation.navigate({ name: screenName, })}
            editable={false}
            style={[styles.textInput,{width:width, maxWidth:780, alignSelf:"center" }]}
            placeholder={placeholder}
            placeholderTextColor={theme.PLACEHOLDER_COLOR}
            onChangeText={(newText) => void 0}
          />
        </Pressable>
          <TabView
            navigationState={{ index, routes }}
            contentContainerStyle={{width: "100%", backgroundColor: "blue"}}
            renderScene={renderScene}
            renderTabBar={renderTabBar}
            onIndexChange={(index) => {
              setIndex(index);
            }}
            initialLayout={styles.tabView}
            animationEnabled={false}
            style={{height: "100%", width: "100%", flex: 1}}
          />
      </View>

    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.BACKGROUND_ONE,
    color: theme.TEXT_COLOR,
  },
  tabView: {
    width: SCREEN_WIDTH,
  },
  searchContainer: {
    height: 50,
    backgroundColor: theme.BACKGROUND_ONE,
    width: SCREEN_WIDTH - 10,
    borderRadius: 10,
    justifyContent: "center",
    alignSelf: "center",
    marginTop: 6,
    padding: 10,
    flexDirection: "row",
    alignItems: "flex-start",
    borderWidth: 1,
    borderColor: theme.LINE_GRAY
  },
  textInput: {
    height: 28,
    width: "90%",
    paddingLeft: 30,
    color: theme.TEXT_COLOR,
    fontFamily:"OpenSans-Regular"
  },
  icon: {
    position: "absolute",
    left: 5,
    top: 14
  }
});
