import {create} from "zustand";
import axios from 'axios';
import { persist, createJSONStorage } from 'zustand/middleware';
import Configuration from "../config/Configuration";
import DefaultStorage from './DataStorage';

const SERVER = Configuration.BASE_SERVER_URL;
// const SERVER = 'http://localhost:8080';

export const loginService = create(
  persist((set, get) => ({
    username: "",
    authToken: "",
    refreshToken: {},
    email: "",
    first_name: "",
    last_name: "",
    bio: "",
    following: [],
    isLoggedIn: false,
    loggedUserInfo: [],
    setUser: ({
      authToken,
      username,
      refreshToken,
      email,
      firstName,
      lastName,
      bio,
      following,
    }) => {
      console.log("followingis", following);
      set((state) => ({
        authToken: authToken,
        username: username,
        refreshToken: refreshToken,
        email: email,
        first_name: firstName,
        last_name: lastName,
        bio: bio,
        following: following,
        isLoggedIn: true,
      }));
    },
    setUserInfo: (item) => {
      set((state) => {
        return {
          loggedUserInfo: item,
        };
      });
    },
    resetUser: () => {
      set((state) => ({
        username: "",
        authToken: "",
        isLoggedIn: false,
        loggedUserInfo: [],
      }));
    },
  }), {
    name: 'users',
    storage: createJSONStorage(() => (DefaultStorage))
  })
);

export const login = async (username, password) => {
  const dataLogin = { username: username, password: password }

  return await axios.post(SERVER + '/api/auth/login', dataLogin);
}

export const signUp = async (username, password, email, firstName, lastName, bio) => {
  const dataSignUp = {

    username: username,
    password: password,
    email: email,
    first_name: firstName,
    last_name: lastName,
    bio: bio,

  }

  return await axios.post(SERVER + '/api/auth/signup', dataSignUp);
}

export const updateProfile = async (authToken, firstName, lastName, bio) => {
  const dataToUpdate = {

    first_name: firstName,
    last_name: lastName,
    bio: bio,

  }

  return await axios.post(SERVER + '/api/profile/update', dataToUpdate,
    {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })

}

export const LoggedUserInfo = async (username) => {
  try {
    const url = SERVER + "/api/profile/" + username + "/info";
    let userinfo = await axios.get(url);
    //console.log(userinfo.data.info);
    return userinfo.data.info

  } catch (e) {
    console.log(e);
  }
}

export const isFollowing = (username) => {

}

export const follow = (username, authToken) => {
  console.log("follow user:", username)
  try {
    const url = SERVER + "/api/feed/follow/" + username;
    return axios.post(url, {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
  } catch (e) {
    console.log(e);
  }
}

export const unfollow = (username, authToken) => {
  console.log("unfollow user:", username)
  try {
    const url = SERVER + "/api/feed/unfollow/" + username;
    return axios.post(url, {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
  } catch (e) {
    console.log(e);
  }
}

export const isFollowed = async (loggedUsername, username) => {

  try {

    let followExists = false;

    const url = SERVER + "/api/feed/following/" + loggedUsername;
    let followingList = await axios.get(url);
    const list = followingList.data.followers

    for (let i = 0; i < list.length; i++) {

      if (list[i].username === username) {
        followExists = true;
      }
    }

    return followExists

  } catch (e) {
    console.log(e);
  }

}