import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, ImageBackground, ScrollView, FlatList, Dimensions, Platform, useWindowDimensions } from 'react-native';
import { Ionicons, Entypo, FontAwesome, MaterialIcons, AntDesign, MaterialCommunityIcons, FontAwesome5, Feather } from '@expo/vector-icons'
import { allPosts, createMedia, createPost } from "../services/PostServices";
import LoadingView from "../components/Loading";
import Header from '../components/Header';
import mime from 'mime';
// import * as FileSystem from 'expo-file-system'
import { getAssetInfoAsync } from 'expo-media-library';
import { loginService } from "../services/UserService";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import moment from 'moment';
import SwiperFlatListExt from "../components/SwiperFlatListExt";
import usePost from "../hooks/usePost";
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';
import PhotoGallery from "../components/PhotoGallery";
import { GestureHandlerRootView } from 'react-native-gesture-handler';


//colors
import colors from "../Colors"

const theme = colors.defaultTheme()

const MAX_WIDTH = 780
const BREAKPOINT = 500
const MINIMUM_ITEM_HEIGHT = 300
const device = Platform.OS

export default function ViewPost({ navigation, route }) {

    const [fontsLoaded] = useFonts(fontsList);

    let { post, post_id, postUpdate, origin } = route.params
    const [loading, setLoading] = useState(false);
    const setPost = allPosts((state) => state.setPost);
    const setOrigin = allPosts((state) => state.setOrigin);
    const authToken = loginService((state) => state.authToken);
    const loggedUsername = loginService((state) => state.username)
    let {height, width} = useWindowDimensions();
    const {isLoading, data, handleLikePost, handleUnlikePost} = usePost({post_id: post_id}, authToken);
    const [isUpdated , setIsUpdated] = useState(false)

    //console.log(origin)

    useEffect(()=>{

        setIsUpdated(postUpdate)

    },[route.params])

    async function uploadMedia(mediaAsset) {

        const { localUri, filename } = await getAssetInfoAsync(mediaAsset);

        const manipResult = await manipulateAsync(
            localUri,
            [
                {
                    resize: { width: 800 },
                },
            ],
            { compress: .5, format: SaveFormat.JPEG }
        );
        let manipFilename = manipResult.uri.substring(manipResult.uri.lastIndexOf("/") + 1, manipResult.uri.length);

        try {
            const newMedia = await createMedia(
                manipResult.uri,
                manipFilename,
                authToken
            );
            //console.log("New Media is", newMedia);
            return newMedia;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    if (loading) {
        return (
        <LoadingView></LoadingView>
        )
    }

    async function savePost(post) {   
        
        setLoading(true)

        const media_ids = [];
        for (let i = 0; i < post.media.length; i++) {
            let newMedia = await uploadMedia(post.media[i]);
            //console.log("Got new Media", newMedia);
            media_ids.push(newMedia.data.media.media_id);
        }

        post['media_ids'] = media_ids;
        post['media'] = [];

        //console.log(JSON.stringify(post));

        try {            
            let newPostResponse = await createPost(post, authToken);
            //console.log(newPostResponse);
            setOrigin("AddPost")
            setPost(newPostResponse)
            navigation.navigate('Select media', { resetFlow: true })
            navigation.navigate("Me", { screen: "Profile", params:{refreshFeed:true} })
            //setLoading("")
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    }

    //console.log(origin)
    //console.log(post)

    function checkUsername() {
        // console.log(post);
        return (post.author && post.author.length > 0) ? post.author[0].username : "";
    }

    const sessionMethod = () => {
        if (!post.form.checks) {
            return "";
        }

        if (post.form.checks.bong == true) {
            return "BONG"
        } else if (post.form.checks.joint == true) {
            return "JOINT"
        } else if (post.form.checks.bowl == true) {
            return "BOWL"
        } else if (post.form.checks.vape == true) {
            return "VAPE"
        }
    }

    const usageTime = () => {
        if (!post.form.checks) {
            return "";
        }

        if (post.form.checks.night == true) {
            return "Night"
        } else if (post.form.checks.evening == true) {
            return "Evening"
        } else if (post.form.checks.afternoon == true) {
            return "Afternoon"
        } else if (post.form.checks.morning == true) {
            return "Morning"
        }
    }

    const Item = ({ item, source }) => {
        return (
            <View key={item.id} style={[styles.item, { width: width, height: height > 500 ? 400 : height - 600, }]} >
                <Image source={{ uri: source }} style={styles.photo} />
            </View>
        )
    }

    function rating(x) {
        return Number.parseFloat(x).toFixed(1);
    }

    function viewProfile() {
        setOrigin("ViewPost");
        if (loggedUsername != post.author[0].username) {
            post.author[0].username;
            post.username = post.author[0].username;
            navigation.push("ProfileViewer", post);
        } else {
            navigation.navigate("Me", { screen: "Profile" }, post);
        }
    }

    if (!post) {
        post = data;
    }

    const date = post ? new Date(post.timestamp) : null;
    const timeago = date ? moment(date).fromNow() : null;
    //console.log(post.form)
    const paddingHorizontal = styles.itemContainer.paddingHorizontal * 2
    width -= paddingHorizontal// discount padding
    let imgWidth = width > BREAKPOINT ? MAX_WIDTH - paddingHorizontal : width
    let imgHeight = width < BREAKPOINT ? imgWidth : height / 3
   
    let didLike = false;
    if (post && post["react_user_status"] && post["react_user_status"]["react_like"]) {
        didLike = post.react_user_status.react_like
    }
    

    let heartCount = 0;
    let commentsCount = 0;
    
    function PostUpdated() {

        setTimeout(() => {
            setIsUpdated(false)
          }, 3000);

        return (
            <View>
                <Text style={{color:theme.NATURAL_GREEN, textAlign:"center", paddingVertical:5}}>Post updated!</Text>
            </View>
        )
    }

    function viewComments(postId) {
        navigation.push("PostComments", {post_id: postId})
    }
    
    if (post && post["reactions_count"] && post["reactions_count"].length > 0 && post["reactions_count"][0]["heart"]) {
        heartCount = post["reactions_count"][0]["heart"];
    }


    if (post && post["comment_count"]) {
        commentsCount = post["comment_count"];
    }

    return (

        <SafeAreaView style={styles.container}>
            <Header
                navigation={navigation}
                showLogo={false}
                showBackButton={true}
                showActionButton={origin == "form" ? true : false}
                actionButtonLabel={"Add post"}
                actionButtonPressed={() => savePost(post)}
            />
            <GestureHandlerRootView style={{ flex: 1, width: "100%", alignSelf: "center" }}>

            { post && (
            <ScrollView style={{flex:1, width:"100%"}}>
                <View style={[styles.itemContainer,{paddingBottom: width > 500 ? 100 : 30}]}>
                {isUpdated &&
                    <PostUpdated/>                         
                }
                <View style={styles.profileContainer}>
                        <View style={styles.megaTitle}>
                            <View style={styles.textContainer}>
                                <Text style={[styles.profileTitle, { fontSize: 24.5 }]}>{post.form.fields.name}</Text>
                                <Text style={{ fontSize: 14, fontWeight: "500", color: theme.TEXT_COLOR, fontFamily:"Ubuntu-Regular" }}>by {post.form.fields.brand}</Text>
                            </View>
                        </View>
                        <View style={styles.overallContainer}>
                            {( loggedUsername == checkUsername() && origin != "form") &&
                                <TouchableOpacity style={{ flex: 1, top: -10, width: 50, height: 10, justifyContent: "center", alignItems: "center" }} onPress={() => navigation.push("PostAction", post)}>
                                    <View style={{ width: 50, height: 45 }}>
                                        <Text style={{ color: theme.TEXT_COLOR, fontSize: 45, lineHeight: 35, textAlign: "center" }}>...</Text>
                                    </View>
                                </TouchableOpacity>
                            }
                            <Text style={styles.overallName}>Overall</Text>
                            <Text style={styles.overallNumber}>{rating(post.form.rating.overall)}</Text>
                        </View>
                    </View>
                    {post.store_name && (
                        <View style={[styles.horizonltalAlign, { paddingVertical: 10, width: "100%", justifyContent: "flex-start" }]}>
                            <Entypo name="location-pin" size={24} color={theme.SECTION_TITLE} />
                            <Text style={styles.store}>{post.store_name}</Text>
                        </View>
                    )}
                    <View style={{ flex: 1, height:"100%" }}>           
                        <PhotoGallery  
                            showPagination={post.media.length <= 1 ? false : true}
                            data={post.media}
                            offsetTop={imgHeight * 0.9}
                            imgWidth={imgWidth}
                            imgHeight={imgHeight} 
                            origin={origin == "form" ? "form" : null}   
                        />
                    </View>
                    <View style={{width: "100%", justifyContent: 'space-between', flexDirection: 'row'}}>
                        <View style={{flex:1}}>
                            <View style={styles.authorContainer}>
                                <TouchableOpacity onPress={() => viewProfile()}>
                                    <Text style={styles.authorName}>{checkUsername()}</Text>
                                </TouchableOpacity>
                            </View>
                            <Text style={styles.date}>{timeago}</Text>
                        </View>
                        <View style={{flexDirection: "row", alignItems: "flex-start", justifyContent: "center", marginTop: 5, alignContent: "center" }}>
                            <View style={{flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                            <TouchableOpacity 
                                onPress={async () => await viewComments(post_id)} 
                                style={{width:32, height:32,alignItems:"center", justifyContent:"center"}}
                            >
                             <FontAwesome5 name="comment" size={22} color={theme.ICON_COLOR} />
                            </TouchableOpacity>
                            <Text style={{color: theme.TEXT_COLOR, paddingEnd: 5}}>{commentsCount}</Text>
                            {!didLike && 
                            <TouchableOpacity 
                                onPress={async () => await handleLikePost(post_id)} 
                                style={{width:32, height:32,alignItems:"center", justifyContent:"center"}}
                            >
                             <Ionicons name="ios-heart-outline" size={24} color={theme.TEXT_COLOR} />
                            </TouchableOpacity>
                            }

                            {didLike && 
                            <TouchableOpacity 
                                onPress={async () => await handleUnlikePost(post_id)} 
                                style={{width:32, height:32, alignItems:"center", justifyContent:"center"}}
                                >
                                <Ionicons name="ios-heart-sharp" size={24} color={theme.NATURAL_GREEN}  />
                            </TouchableOpacity>
                            }

                            { heartCount > 0 &&
                                <Text style={{ color: theme.TEXT_COLOR}}>{heartCount}</Text>
                            }  
                            { heartCount <= 0 &&
                                <Text style={{opacity: 0}}>1</Text>
                            }         
                            </View>
                        </View>
                    </View>
                    <View style={styles.separator}></View>
                    <Text style={styles.description}>{post.form.fields.general_notes}</Text>
                    <View style={[styles.thcContainter, { backgroundColor: theme.BACKGROUND_SEARCH_MODAL, paddingVertical: 10 }]}>
                        <Text style={styles.thcItem}>THC: {post.form.fields.thc}%</Text>
                        <Text style={styles.thcItem}>WEIGHT: {post.form.fields.weight}g</Text>
                        {post.form.checks && post.form.checks.smalls == true &&
                            <Text style={styles.thcItem}>Smalls</Text>

                        }
                        {post.form.checks && post.form.checks.shake == true &&
                            <Text style={styles.thcItem}>Shake</Text>
                        }
                        <Text style={[styles.names, { fontSize: 26, color: theme.SECTION_TITLE, marginLeft: 10 }]}>${post.form.fields.price}</Text>
                            </View>
                            <View style={styles.separator}></View>
                            <View style={styles.starsContainer}>
                                <View style={styles.starsColumn}>
                                    <Text style={styles.sectionTitle} >Session with {sessionMethod()}</Text>
                                    <View style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                                        <Text style={styles.text}></Text>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Taste: <Text style={styles.rating}>{rating(post.form.rating.taste)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.flavor_notes}</Text>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Focused: <Text style={styles.rating}>{rating(post.form.rating.focused)}</Text></Text>
                                            <Text style={styles.label}>Happy: <Text style={styles.rating}>{rating(post.form.rating.happy)}</Text></Text>
                                            <Text style={styles.label}>Relaxed: <Text style={styles.rating}>{rating(post.form.rating.relaxed)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.your_experience}</Text>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Strenght: <Text style={styles.rating}>{rating(post.form.rating.strength)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.hit_notes}</Text>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Other effects</Text>
                                            <View style={styles.pillsContainer}>
                                                {post.form.checks && post.form.checks.muscle_relax == true &&
                                                    <Text style={styles.pill}>Muscle Relax</Text>
                                                }
                                                {post.form.checks && post.form.checks.pain_relief == true &&
                                                    <Text style={styles.pill}>Pain Relief</Text>
                                                }
                                                {post.form.checks && post.form.checks.nausea_relief == true &&
                                                    <Text style={styles.pill}>Nausea Relief</Text>
                                                }
                                                {post.form.checks && post.form.checks.creative == true &&
                                                    <Text style={styles.pill}>Creative</Text>
                                                }
                                                {post.form.checks && post.form.checks.contemplative == true &&
                                                    <Text style={styles.pill}>Contemplative</Text>
                                                }
                                                {post.form.checks && post.form.checks.focused == true &&
                                                    <Text style={styles.pill}>Focused</Text>
                                                }
                                                {post.form.checks && post.form.checks.hungry == true &&
                                                    <Text style={styles.pill}>Hungry</Text>
                                                }
                                                {post.form.checks && post.form.checks.sleepy == true &&
                                                    <Text style={styles.pill}>Sleepy</Text>
                                                }
                                                {post.form.checks && post.form.checks.anxious == true &&
                                                    <Text style={styles.pill}>Anxious</Text>
                                                }
                                                {post.form.checks && post.form.checks.hazy == true &&
                                                    <Text style={styles.pill}>Hazy</Text>
                                                }
                                            </View>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Usage time</Text>
                                            <Text style={styles.text}>{usageTime()}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={[styles.starsColumn, { borderRightWidth: 0 }]}>
                                    <Text style={styles.sectionTitle}>Bag appeals</Text>
                                    <View style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Looks: <Text style={styles.rating}>{rating(post.form.rating.looks)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.looks_notes}</Text>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Smell: <Text style={styles.rating}>{rating(post.form.rating.smell)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.smell_notes}</Text>
                                        </View>
                                        <View style={styles.section}>
                                            <Text style={styles.label}>Moisture: <Text style={styles.rating}>{rating(post.form.rating.moisture)}</Text></Text>
                                            <Text style={styles.text}>{post.form.fields.moisture_notes}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                )}
            </GestureHandlerRootView>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.BACKGROUND_ONE,
        width: "100%",
        height: "100%",
    },
    itemContainer: {
        flex: 1,
        maxWidth: MAX_WIDTH,
        width: "100%",
        alignSelf: "center",
        paddingHorizontal: 5,


    },
    description: {
        color: theme.TEXT_COLOR,
        width: "100%",
        paddingHorizontal: 5,
        marginBottom: 20,
        fontSize: 16,
        lineHeight: 26,
        fontFamily: "OpenSans-Regular"
    },
    authorContainer: {
        flexDirection: "row",
        color: theme.TEXT_COLOR,
        width: "100%",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        padding: 5,

    },
    authorName: {
        color: theme.SECTION_TITLE,
        fontSize: 18,
        fontWeight: "bold",
        marginRight: 5,
        fontFamily: "Ubuntu-Bold"
    },
    date: {
        color: theme.TEXT_COLOR,
        fontSize: 14,
        marginLeft: 5,
        fontFamily: "OpenSans-Regular"
    },
    profileContainer: {
        flexDirection: "row",
        width: "100%",
        paddingVertical: 10,
        borderColor: theme.LINE_GRAY,
        marginBottom: 0,

    },
    megaTitle: {
        flexDirection: "row",
        width: "83.5%"
    },
    avatar: {
        width: 48,
        height: 48,
        borderRadius: 500,
        borderWidth: 2,
        borderColor: theme.LINE_GRAY
    },
    horizonltalAlign: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    textContainer: {
        paddingHorizontal: 0,
        width: "100%"
    },
    profileTitle: {
        fontSize: 18,
        fontWeight: "bold",
        marginTop: 10,
        color: theme.TITLE_COLOR,
        fontFamily: "Ubuntu-Bold"
    },
    profileItem: {
        marginTop: 5,
        marginRight: 25
    },
    profileNumbers: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR
    },
    profileText: {
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    item: {
        flex: 1,
        maxWidth: MAX_WIDTH,
        marginRight: 0,
        marginBottom: 0,

    },
    photo: {
        width: "100%",
        maxWidth: MAX_WIDTH,
        height: "100%"
    },
    text: {
        fontSize: 16,
        color: theme.TEXT_COLOR,
        lineHeight: 26,
        fontFamily: "OpenSans-Regular"
    },
    label: {
        fontSize: 16,
        color: theme.SECTION_TITLE,
        fontWeight: "bold",
        marginBottom: 10,
        fontFamily: "OpenSans-Bold"
    },
    rating: {
        fontSize: 20,
        color: theme.TEXT_COLOR,
        fontWeight: "bold",
        fontFamily: "OpenSans-Bold"
    },
    sectionTitle: {
        width: "100%",
        textAlign: "center",
        padding: 20,
        fontSize: 18,
        fontWeight: "700",
        color: theme.SECTION_TITLE,
        textTransform: "uppercase",
        fontFamily: "OpenSans-Bold"
    },
    names: {
        color: theme.TEXT_COLOR,
        alignSelf: "center",
        padding: 0,
        marginRight: 10,
        fontSize: 22,
        fontWeight: "600",
        lineHeight: 26,
        fontFamily: "Ubuntu-Bold"

    },
    productName: {
        color: theme.SECTION_TITLE,
        alignSelf: "center",
        padding: 0,
        marginRight: 10,
        fontSize: 28,
        fontWeight: "700",
        lineHeight: 35,
        fontFamily: "Ubuntu-Bold"

    },
    brand: {
        color: theme.TEXT_COLOR,
        alignSelf: "baseline",
        padding: 0,
        marginRight: 10,
        fontSize: 14,
        fontWeight: "600",
        lineHeight: 40,
        fontFamily: "Ubuntu-Bold"
    },
    from_store: {
        color: theme.SECTION_TITLE,
        alignSelf: "baseline",
        padding: 0,
        marginRight: 10,
        fontSize: 12,
        fontWeight: "600",
        lineHeight: 26,
        alignSelf: 'center'
    },
    store: {
        color: theme.TEXT_COLOR,
        alignSelf: "baseline",
        padding: 0,
        marginRight: 10,
        fontSize: 12,
        fontWeight: "600",
        lineHeight: 24,
        fontFamily: "OpenSans-Regular"

    },
    separator: {
        height: 1,
        width: "100%",
        backgroundColor: theme.LINE_GRAY,
        marginVertical: 10,
        opacity: 0.5
    },
    paginationContainer: {
        position: "relative"
    },
    pagination: {
        borderRadius: 10,
        width: 8,
        height: 8,
        marginRight: 2,
        marginLeft: 2
    },
    starsContainer: {
        flexDirection: "column",
        width: "100%"
    },
    dataContainer: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 10
    },
    buttonActive: {
        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "100%",
        marginBottom: 20,
        borderColor: theme.LINE_GRAY

    },
    overallContainer: {
        alignSelf: "flex-end",
        justifyContent: "center",
        alignItems: "flex-end",
        width: "16.5%",
    },
    overallName: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.SECTION_TITLE,
        fontFamily: "Ubuntu-Bold"
    },
    overallNumber: {
        fontSize: 35,
        fontWeight: "bold",
        textAlign: "center",
        color: theme.TEXT_COLOR,
        fontFamily: "Ubuntu-Bold"
    },
    overallNotes: {
        fontSize: 16,
        width: "60%",
        color: theme.TEXT_COLOR,
        textAlign: "center",
        fontFamily: "Ubuntu-Bold"
    },
    thcContainter: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        width: "100%",
        paddingHorizontal: 20,
    },
    thcItem: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR,
        fontFamily: "OpenSans-Bold"
    },
    section: {
        marginVertical: 15,
        width: "100%",
        alignSelf: "center"

    },
    pillsContainer: {
        flexDirection: "row",
        maxWidth: "100%",
        flexWrap: 'wrap'
    },
    pill: {
        position: "relative",
        fontSize: 16,
        color: theme.TEXT_COLOR,
        borderColor: theme.PLACEHOLDER_COLOR,
        paddingHorizontal: 10,
        paddingVertical: 5,
        textAlign: "center",
        borderWidth: 2,
        borderRadius: 10,
        marginRight: 5,
        marginBottom: 5,
        alignSelf: 'baseline',
        fontFamily: "OpenSans-Regular"
    }


});
