import * as React from 'react';
import { Image } from 'react-native';
import SwiperFlatListExt from './SwiperFlatListExt';


export default function ReanimatedGallery(props) {

    return (

        <SwiperFlatListExt
            style={{width: props.imgWidth }}
            showPagination={props.showPagination}
            data={props.data}
            imgWidth={props.imgWidth}
            imgHeight={props.imgHeight}
            offsetTop={68} // just a initial value to not jump from zero, will be replaced by the DOM calc for WEB
            renderItem={({ item, index }) => (
                <Image source={{ uri: item.location }} style={{flex: 1, width: props.imgWidth, height: props.imgHeight }} />
            )}

        />



    )
}

