import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Rating, AirbnbRating } from 'react-native-ratings';

//colors
import colors from "../Colors"

const theme = colors.defaultTheme() 

export default function StarsRating({ rating, onStarRatingPress, name, disabled, size }){
    return (
              <View>
            <Rating
                showRating={true}
                defaultRating={rating}
                ratingTextColor={theme.FULLSTAR_COLOR}
                tintColor={theme.BACKGROUND_ONE}
                ratingBackgroundColor={theme.BACKGROUND_ONE}
                isDisabled={disabled}
                count={5}
                imageSize={40}
                fractions={2.5}
                jumpValue={0.5}
                rating={rating}
                onFinishRating={(rat) => onStarRatingPress(rat, name)}
             
            />
            </View>
       

    )

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: 20,
        paddingLeft: 10,
        paddingRight: 10,
        alignItems: 'stretch',
        justifyContent: 'center',
    },
});