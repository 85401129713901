import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  FlatList,
  Pressable,
  Dimensions
} from "react-native";
import axios from 'axios';
import { useQuery } from "react-query";
import { Ionicons } from "@expo/vector-icons";
import useDebounce from "../hooks/useDebounce";
import colors from "../Colors"
import { loginService } from "../services/UserService";

const theme = colors.defaultTheme()
const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
import Configuration from "../config/Configuration";
const SERVER = Configuration.BASE_SERVER_URL;

export default function UserFinder({ gotResult, close }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(undefined);
  const debouncedFilter = useDebounce(filter, 500);
  const authToken = loginService((state) => state.authToken);


  const { data, status, error } = useQuery(
    ["users", debouncedFilter, authToken],
    () => fetchUsers(debouncedFilter, authToken),
    { enabled: Boolean(debouncedFilter) }
  );

  console.log("info", status, error, data);

  function renderItem({ item }) {
    console.log("item", item);
    return (
      <Pressable
        onPress={() => {
          if (gotResult) {
            gotResult({ first_name: item.first_name, last_name: item.last_name, username: item.username });
          }
          console.log("touched", item.store_id);
        }}
      >
        <View
          style={{
            width: "100%",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
            backgroundColor: theme.BACKGROUND_ONE,
          }}
        >
          <Text style={{ color: theme.TITLE_COLOR, fontWeight: "bold", fontSize: 16 }}>{item.username}</Text>
          <Text style={{ color: theme.TEXT_COLOR, fontSize: 13, marginTop: 2 }}>{item.first_name} {item.last_name}</Text>
        </View>
        <View style={{ backgroundColor: theme.BACKGROUND_TWO, height: 1, width: '100%' }}></View>
      </Pressable>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.searchContainer}>
        <Ionicons name="ios-search" size={24} color={theme.ICON_ACTIVE_COLOR} />
        <TextInput autoFocus={true} onChangeText={(text) => setFilter(text)} style={styles.textInput} placeholder="Find a user" placeholderTextColor={theme.PLACEHOLDER_COLOR} />
      </View>

      {status === "error" && <Text>{error.message}</Text>}

      {status === "loading" && <Text>Loading...</Text>}

      {status === "success" && (
        <FlatList
          style={styles.list}
          data={data.users}
          contentContainerStyle={{ borderRadius: 8, overflow: 'hidden' }}
          renderItem={renderItem}
          keyExtractor={(item) => item.username}
        />
      )}
    </View>
  );
}

const fetchUsers = async (query, authToken) => {
  console.log("called fetch users");
  let headers = {}
  if(authToken && authToken.length > 0){
      headers = {headers: {Authorization: `Bearer ${authToken}`}}
  }

  let url = SERVER + "/api/search/users?q=" + query.toLowerCase();
  let stores = await axios.get(url,headers)
  return stores.data;
};

const styles = StyleSheet.create({
  searchInputSection: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.BACKGROUND_TWO,
    padding: 10,
  },
  searchIcon: {
    position: 'absolute',
    zIndex: 100,
    left: 20,
  },
  input: {
    backgroundColor: theme.BACKGROUND_TWO,
    color: "#424242",
    flex: 1,
    height: 28,
    width: "90%",
    paddingLeft: 10,
    color: theme.TEXT_COLOR
  },
  list: {
    padding: 10,
    background: theme.BACKGROUND_TWO,
    flex: 1,
    width: '100%',
    borderRadius: 12,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    alignItems: 'center'
  },
  searchContainer: {
    height: 50,
    backgroundColor: theme.BACKGROUND_ONE,
    width: SCREEN_WIDTH - 10,
    borderRadius: 10,
    justifyContent: "center",
    marginTop: 6,
    padding: 10,
    flexDirection: "row",
    alignItems: "flex-start"
  },
  textInput: {
    height: 28,
    width: "90%",
    paddingLeft: 10,
    color: theme.TEXT_COLOR
  },
});
