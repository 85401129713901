
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, SafeAreaView, Image, Pressable, ImageBackground, ScrollView, FlatList, Dimensions, Button, TextInput } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Rating, AirbnbRating } from 'react-native-ratings';
import SliderRating from "../components/SliderRating";
import { allPosts } from "../services/PostServices"
import { loginService } from "../services/UserService";
import Header from '../components/Header';
import StoreFinder from '../components/StoreFinder';
import SwitchSelector from "react-native-switch-selector";
import ResetForm from '../mock/ResetForm';
import DebugForm from '../mock/DebugForm';
import Checkbox from 'expo-checkbox';
import { TapGestureHandler } from 'react-native-gesture-handler';
import _ from "lodash"
//colors
import colors from "../Colors"
import fontsList from "../FontsList";
import { useFonts } from 'expo-font';

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window')
const theme = colors.defaultTheme()

export default function MediaForm({ navigation, route }) {

    const [fontsLoaded]= useFonts(fontsList);  
    const [isChecked, setChecked] = useState(false);
    const [form, setForm] = useState(ResetForm[0])
    const [number, setNumber] = useState('50');
    const starSize = 28
    const setOrigin = allPosts((state) => state.setOrigin);
    let { mediaGallery } = route.params
    const mediaCount = mediaGallery.length
    const username = loginService((state) => state.username);
    const [storeId, setStoreId] = useState(null);
    const [storeName, setStoreName] = useState(null);
    const [postType, setPostType] = useState("normal");
    const doubleTapRef= useRef();
    const scroll = useRef()

    var objectId = function () {
        var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

    let post = {
        id: objectId(),
        timestamp: Date.now(),
        media: mediaGallery,
        post_type: postType,
        form: form,
        store_id: storeId,
        store_name: storeName,
        author: [{
            username: username,
        }]
    }

    React.useEffect(() => {
        if (route.params && route.params.store) {
            const { store_id, name } = route.params.store;
            setStoreName(name);
            setStoreId(store_id);
            // Post updated, do something with `route.params.post`
            // For example, send the post to the server
        }
    }, [route.params.store]);

    function nextToReview() {
        setOrigin("Form")
        navigation.push('ViewPost', {post: post, origin:"form"})
    }

    function onFillText(text, name) {

        let fields = form["fields"]
        if (!fields) {
            fields = {}
        }
        fields[name] = text
        form["fields"] = fields
        setForm(_.cloneDeep(form))
    }

    function checkMarks(state, name) {
        form["checks"][name] = state
        setForm(_.cloneDeep(form))

    }

    function onStarRatingPress(rating, name) {       

        form["rating"][name] = rating
        setForm(_.cloneDeep(form))
        //console.log(post.form.rating)
    }

    const onChanged = (text) => {
        let newText = '';
        let numbers = '0123456789';

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
            else {
                alert("please enter numbers only");
            }
        }
        setNumber(newText);
    }

    function ratingNumber(rating) {
        return (Math.round(rating * 100) / 100).toFixed(1)
    }

    const Item = ({ item, source }) => {

        return (
            <View key={item.id} style={mediaCount <= 1 ? styles.singleItem : styles.item} >
                <ImageBackground source={{ uri: source }} style={mediaCount <= 1 ? styles.singlePhoto : styles.photo} resizeMode={mediaCount <= 1 ? "contain" : "cover"} />
            </View>
        )
    }

    const checkBoxColor = theme.SECTION_TITLE

    function fillFields(){

        setForm(DebugForm[0])

    }

    //console.log("store id is", storeId, storeName);

    //console.log(post)

    
    return (
        <SafeAreaView style={styles.container} >
            <Header
                showBackButton={true} 
                showActionButton={form.fields["name"] == "" ? false : true} 
                showLogo={false}
                actionButtonPressed={nextToReview}
                navigation={navigation}
            />
            <KeyboardAwareScrollView
                style={styles.scrollView}
                extraHeight={110}
                scrollEnabled={true}
                ref={scroll}
            >
                <FlatList
                    style={styles.mediaGallery}
                    showsHorizontalScrollIndicator={true}
                    horizontal
                    data={mediaGallery}
                    keyExtractor={(item, index) => "Photo" + index}
                    renderItem={({ item, index }) => (
                        <Item item={item} source={item.uri} />
                    )}
                />
 
                <TapGestureHandler ref={doubleTapRef} numberOfTaps={2}  onActivated={fillFields}>
                    <Text style={styles.label}>Product Name</Text>
                </TapGestureHandler>
                <TextInput
                    style={styles.textInput}
                    onChangeText={(newText) => onFillText(newText, "name")}
                    value={form.fields["name"]}
                />
                <Text style={styles.label}>Brand</Text>
                <TextInput
                    style={styles.textInput}
                    onChangeText={(newText) => onFillText(newText, "brand")}
                    value={form.fields["brand"]}
                />
                <Text style={styles.label}>Store</Text>
                {!storeId && (
                    <Pressable
                        onPress={() =>
                            navigation.navigate("StorePickerModal", {
                                result_screen_name: "Add post form",
                            })
                        }
                    >
                        <View
                            style={{
                                flex: 1,
                                flexDirection: "row",
                                marginBottom: 12,
                                alignItems: "center",
                            }}
                        >
                            <Text style={styles.store_name}>Pick a Store</Text>
                            <Ionicons
                                name="chevron-forward"
                                size={24}
                                color={theme.NATURAL_GREEN}
                            />
                        </View>
                    </Pressable>
                )}
                {storeId && (
                    <View
                        style={{
                            flex: 1,
                            flexDirection: "row",
                            marginBottom: 12,
                            alignItems: "center",
                        }}>
                            <Text style={styles.store_name}>{storeName}</Text>
                            <Pressable onPress={() => setStoreId(null)}>
                                <Ionicons
                                    name="close"
                                    size={24}
                                    color={theme.TEXT_COLOR}
                                />
                            </Pressable>
                    </View>
                )}
                <View style={{ flexDirection: "row", marginBottom: 16 }}>
                    <View style={{ marginRight: 12 }}>
                        <Text style={styles.label}>Price ($)</Text>
                        <TextInput
                            style={styles.priceInput}
                            maxLength={4}
                            onChangeText={(newText) => {
                                onFillText(newText, "price") 
                            }}
                            value={form.fields["price"].toString()}
                            keyboardType="numeric"
                    
                        />
                    </View>
                    <View style={{ marginRight: 12 }}>
                        <Text style={styles.label}>THC %</Text>
                        <TextInput
                            style={styles.priceInput}
                            maxLength={5}
                            onChangeText={(newText) => {
                                onFillText(newText, "thc");
                            }}
                            keyboardType="numeric"
                            value={form.fields["thc"].toString()}
                        />
                    </View>
                    <View style={{ marginRight: 12 }}>
                        <Text style={styles.label}>Weight (g)</Text>
                        <TextInput
                            style={styles.priceInput}
                            maxLength={5}
                            onChangeText={(newText) => {
                                onFillText(newText, "weight");
                            }}
                            value={form.fields["weight"].toString()}
                            keyboardType="numeric"
                        />
                    </View>
                    <View style={{ marginRight: 12 }}>
                        <Text style={styles.label}>Smalls</Text>
                        <Checkbox
                            style={styles.checkbox}
                            value={form.checks["smalls"]}
                            onValueChange={(ev) => checkMarks(ev, "smalls")}
                            color={form.checks["smalls"] == true ? checkBoxColor: undefined}
                        />
                    </View>
                    <View>
                        <Text style={styles.label}>Shake</Text>
                        <Checkbox
                            style={styles.checkbox}
                            value={form.checks["shake"]}
                            onValueChange={(ev) => checkMarks(ev, "shake")}
                            color={form.checks["shake"] == true ? checkBoxColor: undefined}
                        />
                    </View>
                </View>
                <View>
                    <Text style={styles.sectionTitle}>Bag appeal</Text>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Looks</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["looks"])}</Text>                       
                        </View>        
                        <SliderRating
                            rating={form.rating["looks"]}
                            onStarRatingPress={onStarRatingPress}
                            name="looks"
                            disabled={false}
                        />             
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="color, bud structure, trim job"
                            onChangeText={(newText) => onFillText(newText, "looks_notes")}
                            value={form.fields.looks_notes}
                        />
                    </View>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Smell</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["smell"])}</Text>                       
                        </View>        
                        <SliderRating
                            rating={form.rating["smell"]}
                            onStarRatingPress={onStarRatingPress}
                            name="smell"
                            disabled={false}
                        />             
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="gas, orange, lime, candy..."
                            onChangeText={(newText) => onFillText(newText, "smell_notes")}
                            value={form.fields.smell_notes}
                        />
                    </View>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Moisture</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["moisture"])}</Text>                       
                        </View>        
                        <SliderRating
                            rating={form.rating["moisture"]}
                            onStarRatingPress={onStarRatingPress}
                            name="moisture"
                            disabled={false}
                        />             
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="dry, sticky..."
                            onChangeText={(newText) => onFillText(newText, "moisture_notes")}
                            value={form.fields.moisture_notes}
                        />
                    </View>
                    <Text style={styles.sectionTitle}>Session</Text>
                    <View style={{ flexDirection: "row", alignItems: "center", marginVertical:30,  }}>
                        <View
                            style={{
                                padding: 4,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent:"center",
                            }}
                        >
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["joint"]}
                                onValueChange={(ev) => checkMarks(ev, "joint")}
                                color={form.checks["joint"] == true ? checkBoxColor: undefined}
                            />
                            <View><Text style={styles.checkboxLabel}>Joint</Text></View>
                        </View>
                        <View
                            style={{
                                marginRight: 12,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks.bowl}
                                onValueChange={(ev) => checkMarks(ev, "bowl")}
                                color={form.checks["bowl"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Bowl</Text>
                        </View>
                        <View
                            style={{
                                marginRight: 12,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks.bong}
                                onValueChange={(ev) => checkMarks(ev, "bong")}
                                color={form.checks["bong"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Bong</Text>
                        </View>
                        <View
                            style={{
                                marginRight: 12,
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["vape"]}
                                onValueChange={(ev) => checkMarks(ev, "vape")}
                                color={form.checks["vape"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Vape</Text>
                        </View>
                    </View>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Taste</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["taste"])}</Text>                       
                        </View>        
                        <SliderRating
                            rating={form.rating["taste"]}
                            onStarRatingPress={onStarRatingPress}
                            name="taste"
                            disabled={false}
                        /> 
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="flavor notes"
                            onChangeText={(newText) => onFillText(newText, "flavor_notes")}
                            value={form.fields.flavor_notes}
                        />           
                    </View>

                    <View style={styles.starContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Focused</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["focused"])}</Text>                     
                        </View>               
                        <SliderRating
                            rating={form.rating["focused"]}
                            onStarRatingPress={onStarRatingPress}
                            name="focused"
                            disabled={false}
                        />
                    </View>
                    <View style={styles.starContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Happy</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["happy"])}</Text>                    
                        </View>
                        <SliderRating
                            rating={form.rating["happy"]}
                            onStarRatingPress={onStarRatingPress}
                            name="happy"
                            disabled={false}
                        />
                    </View>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Relaxed</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["relaxed"])}</Text>
                        </View>
                        <SliderRating
                            rating={form.rating["relaxed"]}
                            onStarRatingPress={onStarRatingPress}
                            name="relaxed"
                            disabled={false}
                        />
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="Your experience"
                            onChangeText={(newText) => onFillText(newText, "your_experience")}
                            value={form.fields.your_experience}
                        />
                    </View>
                    <View style={styles.sectionContainer}>
                        <View style={styles.horizonltalAlign} >
                            <Text style={styles.label}>Strength</Text>
                            <Text style={styles.score}>{ratingNumber(form.rating["strength"])}</Text>                   
                        </View>  
                        <SliderRating
                            rating={form.rating["strength"]}
                            onStarRatingPress={onStarRatingPress}
                            name="strength"
                            disabled={false}
                        />
                        <TextInput
                            style={styles.textAreaNote}
                            multiline
                            numberOfLines={1}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="How did this hit? Long and Immedidate?"
                            onChangeText={(newText) => onFillText(newText, "hit_notes")}
                            value={form.fields.hit_notes}
                        />
                

                    <Text style={styles.labelSub}>Other Effects</Text>
                    <View style={{ flexDirection: "row", paddingVertical:10, flexWrap:"wrap"}}>
                        <View style={{ flexDirection: "row", marginRight:5 }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["muscle_relax"]}
                                onValueChange={(ev) => checkMarks(ev, "muscle_relax")}
                                color={form.checks["muscle_relax"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Muscle Relax</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["pain_relief"]}
                                onValueChange={(ev) => checkMarks(ev, "pain_relief")}
                                color={form.checks["pain_relief"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Pain Relief</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["nausea_relief"]}
                                onValueChange={(ev) => checkMarks(ev, "nausea_relief")}
                                color={form.checks["nausea_relief"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Nausea Relief</Text>
                        </View>
     
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["creative"]}
                                onValueChange={(ev) => checkMarks(ev, "creative")}
                                color={form.checks["creative"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Creative</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["contemplative"]}
                                onValueChange={(ev) => checkMarks(ev, "contemplative")}
                                color={form.checks["contemplative"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Contemplative</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["focused"]}
                                onValueChange={(ev) => checkMarks(ev, "focused")}
                                color={form.checks["focused"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Focused</Text>
                        </View>

                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["hungry"]}
                                onValueChange={(ev) => checkMarks(ev, "hungry")}
                                color={form.checks["hungry"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Hungry</Text>
                        </View>
                        <View style={{ flexDirection: "row" , marginRight:5 }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["sleepy"]}
                                onValueChange={(ev) => checkMarks(ev, "sleepy")}
                                color={form.checks["sleepy"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Sleepy</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["anxious"]}
                                onValueChange={(ev) => checkMarks(ev, "anxious")}
                                color={form.checks["anxious"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Anxious</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5  }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["hazy"]}
                                onValueChange={(ev) => checkMarks(ev, "hazy")}
                                color={form.checks["hazy"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Hazy</Text>
                        </View>
                    </View>
                    <Text style={styles.labelSub}>Usage Time</Text>
                    <View style={{ flexDirection: "row", paddingVertical:10, flexWrap:"wrap"}}>
                        <View style={{ flexDirection: "row", marginRight:5 }}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["morning"]}
                                onValueChange={(ev) => checkMarks(ev, "morning")}
                                color={form.checks["morning"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Morning</Text>
                        </View>
                        <View style={{ flexDirection: "row" , marginRight:5}}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["afternoon"]}
                                onValueChange={(ev) => checkMarks(ev, "afternoon")}
                                color={form.checks["afternoon"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Afternoon</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5}}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["evening"]}
                                onValueChange={(ev) => checkMarks(ev, "evening")}
                                color={form.checks["evening"] == true ? checkBoxColor: undefined}
                            />
                            <Text style={styles.checkboxLabel}>Evening</Text>
                        </View>
                        <View style={{ flexDirection: "row", marginRight:5}}>
                            <Checkbox
                                style={styles.checkbox}
                                value={form.checks["night"]}
                                onValueChange={(ev) => checkMarks(ev, "night")}
                                color={form.checks["night"] == true ? checkBoxColor : undefined}
                            />
                            <Text style={styles.checkboxLabel}>Night</Text>
                        </View>
                    </View>
                    </View>
                    <View style={{ padding: 0 }}>
                        <Text style={styles.sectionTitle}>OVERALL</Text>
                        <Text style={styles.overall}>{ratingNumber(form.rating["overall"])}</Text>                        

                        <View style={styles.starContainer}>                      
                        <SliderRating
                            rating={form.rating["overall"]}
                            onStarRatingPress={onStarRatingPress}
                            name="overall"
                            disabled={false}
                        />
                        </View>
                    </View>
                    <View style={{ paddingBottom: 30 }}>
                        <TextInput
                            style={[styles.textAreaNote, {minHeight:100}]}
                            multiline
                            numberOfLines={10}
                            placeholderTextColor={theme.PLACEHOLDER_COLOR}
                            placeholder="General Impressions and Experiences"
                            onChangeText={(newText) => onFillText(newText, "general_notes")}
                            value={form.fields.general_notes}
                        />  
                    </View> 
                </View>
            </KeyboardAwareScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex:1,
        backgroundColor: theme.BACKGROUND_ONE,        
    },
    scrollView: {
        backgroundColor: theme.BACKGROUND_ONE,
        marginHorizontal: 15,  
    },
    horizonltalAlign: {
        flexDirection: "row",

    },
    profileTitle: {
        fontSize: 20,
        fontWeight: "bold",
        marginVertical: 10,
        color: theme.TEXT_COLOR
    },
    mediaGallery:{
        marginBottom:20
    },
    mediaContainer: {

        width: "100%",
        paddingVertical: 15,
        borderBottomWidth: 0,
        borderBottomColor: theme.LINE_GRAY,
        marginBottom: 0,
        alignItems: 'center',
        borderWidth: 0,
    },
    mediaItem: {
        width: 120,
        height: 120,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.LINE_GRAY,
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
        marginHorizontal: "7%"
    },
    mediaText: {
        color: theme.TEXT_COLOR
    },
    camera: {
        height: SCREEN_HEIGHT - 150,
        position: "absolute",
        width: "100%",
        zIndex: 1000

    },    
    formContainer: {
        width: "100%",
    },
    sectionContainer:{
        marginBottom:0,
        paddingBottom:10,
        borderBottomWidth:0,
        borderBottomColor:theme.LINE_GRAY
    },
    label: {
        alignSelf: "flex-start",
        fontSize: 14,
        fontWeight: "600",
        color: theme.PLACEHOLDER_COLOR,
        fontFamily:"OpenSans-Bold",
        
    },
    labelSub: {
        alignSelf: "flex-start",
        fontSize: 14,
        fontWeight: "600",
        color: theme.SECTION_TITLE,
        marginTop:20,
        fontFamily:"OpenSans-Bold",
        
    },
    score: {
        fontSize: 16,
        fontWeight: "bold",
        color: theme.TEXT_COLOR,
        marginLeft:5,
        top:-3,
        fontFamily:"OpenSans-Bold",
    },
    overall: {
        fontSize: 55,
        fontWeight:"bold",
        fontWeight: "600",
        textAlign:"center",
        color: theme.TITLE_COLOR,
        padding: 4,
        marginLeft: 0,
    },
    store_name: {
        fontSize: 16,
        flex: 1,
        color: theme.TEXT_COLOR,
        marginLeft: 0,
        fontFamily:"OpenSans-Regular",
    },
    sectionTitle: {
        width: "100%",
        textAlign: "center",
        padding: 10,
        fontSize: 22,
        fontWeight: "bold",
        color: theme.SECTION_TITLE,
        textTransform: "uppercase",
        marginTop:0,
        fontFamily:"Ubuntu-Bold",
    },
    textInput: {
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        width: "100%",
        alignSelf: "center",
        padding: 10,
        fontSize: 16,
        color: theme.TEXT_COLOR,
        marginTop:5,
        marginBottom:20,
        fontFamily:"OpenSans-Regular",
    },
    dateInput: {
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        alignSelf: "flex-start",
        padding: 10,
        fontSize: 16,
        color: theme.TEXT_COLOR
    },
    priceInput: {
        borderWidth: 1,
        borderColor: theme.INPUT_BORDER_COLOR,
        alignSelf: "stretch",
        padding: 10,
        fontSize: 16,
        color: theme.TEXT_COLOR,
        marginTop:5
    },
    textAreaNote: {
        width: "100%",
        alignSelf: "center",
        textAlignVertical: 'top',
        borderWidth: 1,
        maxHeight: 150,
        borderColor: theme.INPUT_BORDER_COLOR,
        fontSize: 16,
        color: theme.PLACEHOLDER_COLOR,
        marginTop: 10,
        marginBottom: 10,
        paddingVertical:8,
        paddingHorizontal:5,
        backgroundColor: theme.BACKGROUND_TWO
    },
    preview: {
        width: SCREEN_WIDTH - 20,
        height: (SCREEN_WIDTH / 200) * 175,
        marginBottom: 20
    },
    button: {
        backgroundColor: theme.BUTTON_COLOR,
        height: 40,
        width: "49%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",

    },
    buttonActive: {

        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "49%",
        borderColor: theme.LINE_GRAY

    },
    buttonActiveDisabled: {
        backgroundColor: theme.BUTTON_ACTIVE_COLOR,
        height: 40,
        paddingHorizontal: 20,
        paddingVertical: 10,
        alignItems: "center",
        width: "49%",
        opacity: 0.3
    },
    buttonText: {
        color: theme.BUTTON_TEXT_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    buttonTextActive: {
        color: theme.BUTTON_TEXT_ACTIVE_COLOR,
        fontSize: 14,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    item: {
        width: 120,
        height: 120,
        marginRight: 2,
        marginBottom: 2
    },
    itemActive: {
        width: "100%",
        height: 120,
        position: "absolute",
        backgroundColor: theme.PHOTO_SELECTED,
        overflow: "hidden",
        zIndex: 10,
        flex: 1,
        justifyContent: "center",
        alignItems: "center"

    },
    itemActiveText: {

        color: theme.BACKGROUND_ONE,
        fontSize: 50,
        fontWeight: "600",
        borderWidth: 2,
        paddingVertical: 20,
        paddingHorizontal: 42,
        borderRadius: 6,
        borderColor: theme.PHOTO_SELECTED_LINE,
        zIndex: 11

    },
    photo: {
        width: "100%",
        height: 120
    },
    singleItem: {
        width: SCREEN_WIDTH,
        height: 300,
        marginRight: 2,
        marginBottom: 2,
        backgroundColor:"#000"

    },
    singlePhoto: {
        width: "100%",
        height: 570,
        marginTop:-150
    },
    starContainer: {
        marginTop: 0
    },
    checkbox:{
        borderColor:theme.INPUT_BORDER_COLOR,
        width:25,
        height:25,
        marginTop:15
    },
    checkboxLabel:{
        padding: 6, 
        color: theme.PLACEHOLDER_COLOR,
        fontSize:18,
        lineHeight:45,
        fontFamily:"OpenSans-Regular",
    }

});
