import React, { useState, useEffect, useCallback, useRef } from "react";
import { View, StyleSheet, Text } from "react-native";
import UserList from "../components/UserList";
import { useQuery } from "react-query";
import Configuration from "../config/Configuration";
//colors
import colors from "../Colors"
const theme = colors.defaultTheme()
const MAX_WIDTH = 780
export default function FollowersInfo({ navigation, route }) {
    let username = route.params.username;
    const loggedIn = route.params.loggedIn;
    console.log(loggedIn);

  const { data, status, error } = useQuery({
    queryKey: ["fetchUserFollowers", username],
    queryFn: () => fetchUserFollowers(username),
  });

  const rowPressed = (username) => {
    navigation.push("ProfileViewer", {username: username});
  }

  return (
    <View style={styles.container}>
      {status === "error" && <Text>{error.message}</Text>}
      {status === "loading" && <Text>Loading...</Text>}
      {status === "success" && (
        <View style={styles.container}>
          <UserList style={styles.container} loggedIn={loggedIn} users={data.followers} rowPressed={rowPressed}/>
        </View>
      )}
    </View>
  );
}

const fetchUserFollowers = async (username) => {
  console.log("called get follower for " + username);
  const res = await fetch(
    Configuration.BASE_SERVER_URL + "/api/feed/followers/" + username
  );
  return res.json();
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
    backgroundColor:theme.BACKGROUND_TWO
  
  },
});
