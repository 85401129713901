import _ from "lodash";

function updateHomeFeedCacheAfterLike({ queryClient, reaction }) {
  queryClient.setQueryData(["homefeed"], (oldData) => {
    let newData = {
      pages: oldData.pages.map((page) => {
        return {
          posts: page.posts.map((post) => {
            if (post.post_id === reaction.content_id) {
              let newPost = _.cloneDeep(post);
              newPost["react_user_status"] = {
                react_like: true,
                react_type: "heart",
              };

              let heartCount = 0;
              if (
                newPost["reactions_count"] &&
                newPost["reactions_count"].count > 0 &&
                newPost["reactions_count"][0]["heart"]
              ) {
                heartCount = newPost["reactions_count"][0]["heart"];
              }
              newPost["reactions_count"] = [
                {
                  heart: heartCount + 1,
                  like: 0,
                },
              ];
              return newPost;
            }
            return post;
          }),
        };
      }),
    };
    return newData;
  });
}

function updateHomeFeedCacheAfterUnlike({ queryClient, reaction }) {
  queryClient.setQueryData(["homefeed"], (oldData) => {
    if (!oldData) return;
    let newData = {
      pages: oldData.pages.map((page) => {
        const updatedPage = {
          page: _.cloneDeep(page),
          posts: page.posts.map((post) => {
            if (post.post_id === reaction.content_id) {
              let newPost = _.cloneDeep(post);
              newPost["react_user_status"] = {
                react_like: false,
                react_type: "heart",
              };

              let heartCount = 0;
              if (
                newPost["reactions_count"] &&
                newPost["reactions_count"].count > 0 &&
                newPost["reactions_count"][0]["heart"]
              ) {
                heartCount = newPost["reactions_count"][0]["heart"];
              }

              newPost["reactions_count"] = [
                {
                  heart: heartCount - 1,
                  like: 0,
                },
              ];
              return newPost;
            }
            return post;
          }),
        };
        return updatedPage;
      }),
    };
    return newData;
  });
}

function updatePostsCacheAfterLike({ queryClient, reaction }) {
  if (queryClient.getQueryData(["posts", reaction.content_id])) {
    queryClient.setQueryData(["posts", reaction.content_id], (oldData) => {
      if (!oldData) return;
      let newPost = _.cloneDeep(oldData);
      newPost["react_user_status"] = {
        react_like: true,
        react_type: "heart",
      };

      let heartCount = 0;
      if (
        newPost["reactions_count"] &&
        newPost["reactions_count"].count > 0 &&
        newPost["reactions_count"][0]["heart"]
      ) {
        heartCount = newPost["reactions_count"][0]["heart"];
      }

      newPost["reactions_count"] = [
        {
          heart: heartCount + 1,
          like: 0,
        },
      ];
      return newPost;
    });
  }
}

function updatePostsCacheAfterUnlike({ queryClient, reaction }) {
  if (queryClient.getQueryData(["posts", reaction.content_id])) {
    queryClient.setQueryData(["posts", reaction.content_id], (oldData) => {
      if (!oldData) return;
      let newPost = _.cloneDeep(oldData);

      newPost["react_user_status"] = {
        react_like: false,
        react_type: "heart",
      };
      let heartCount = 0;
      if (
        newPost["reactions_count"] &&
        newPost["reactions_count"].length > 0 &&
        newPost["reactions_count"][0]["heart"]
      ) {
        heartCount = newPost["reactions_count"][0]["heart"];
      }

      newPost["reactions_count"] = [
        {
          heart: heartCount - 1,
          like: 0,
        },
      ];
      return newPost;
    });
  }
}

function updatePostsCacheAfterComment({ queryClient, post_id, operation = "add" }) {
    if (queryClient.getQueryData(["posts", post_id])) {
      queryClient.setQueryData(["posts", post_id], (oldData) => {
        if (!oldData) return;
        let newPost = _.cloneDeep(oldData);
          let commentCount = newPost["comment_count"]
          ? newPost["comment_count"]
          : 0;
        commentCount =
          operation == "add" ? commentCount + 1 : commentCount - 1;
        newPost["comment_count"] = commentCount;

        return newPost;
      });
    }
  }


function updateHomeFeedCacheAfterComment({
  queryClient,
  post_id,
  operation = "add",
}) {
    queryClient.setQueryData(["homefeed"], (oldData) => {
      let newData = {
      pages: oldData.pages.map((page) => {
        return {
          posts: page.posts.map((post) => {
            if (post.post_id === post_id) {
              let newPost = _.cloneDeep(post);
              let commentCount = newPost["comment_count"]
                ? newPost["comment_count"]
                : 0;
            
              commentCount =
                operation == "add" ? commentCount + 1 : commentCount - 1;
              newPost["comment_count"] = commentCount;
              return newPost;
            }
            return post;
          }),
        };
    })};    
    return newData;
});
}

export {
  updateHomeFeedCacheAfterLike,
  updateHomeFeedCacheAfterUnlike,
  updatePostsCacheAfterLike,
  updatePostsCacheAfterUnlike,
  updateHomeFeedCacheAfterComment,
  updatePostsCacheAfterComment
};
